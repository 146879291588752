import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  ContentChild,
  ContentChildren,
  effect,
  EventEmitter,
  forwardRef,
  input,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  signal,
  Signal,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import * as R from 'ramda';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { NgxOvDataTableTemplateDirective } from './directives/data-table-template.directive';
import { IPager } from './pager/services/pager.service';
import {
  INgxOvDataTableColumn,
  INgxOvDataTableSelectAllEvent,
  INgxOvDataTableSelectEvent,
  INgxOvDataTableSelectionChangeEvent,
  INgxOvDataTableSortChangeEvent,
  isRowSelected,
  mapFilterable,
  mapVisible,
  NgxOvDataTableColumnType,
  NgxOvDataTableSortingOrder,
  PagerPosition
} from './data-table.utils';
import { FilterDataTablePipe } from './pipes/filterDataTable.pipe';
import { PageDataTablePipe } from './pipes/pageDataTable.pipe';
import { NgxOvDataTableSortService } from './services/data-table-sort.service';
import { trackItemByIndex } from '../../../helper';
import { CellValuePipe } from './pipes/getCellValue.pipe';
import { SortDataTablePipe } from './pipes/sortDataTable.pipe';
import { NgxOvBooleanIndicatorComponent } from '../../forms/boolean-indicator/boolean-indicator.component';
import { NgxOvDataTableCellComponent } from './data-table-cell/data-table-cell.component';
import { StopPropagationDirective } from '../../../directives/stop-propagation';
import { NgxOvDataTableColumnComponent } from './data-table-column/data-table-column.component';
import { NgxOvDataTableRowComponent } from './data-table-row/data-table-row.component';
import {
  AsyncPipe,
  NgClass,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet
} from '@angular/common';
import { NgxOvDataTableTableComponent } from './data-table-table/data-table-table.component';
import { FilterVisibleColumnsPipe } from './pipes/filterVisibleColumns.pipe';
import { FilterInVisibleColumnsFilterablePipe } from './pipes/filterInVisibleColumnsFilterable.pipe';
import { NgxOvInputFieldComponent } from '../../forms/input-field/input-field.component';
import { NgxOvDataTableSettingsComponent } from './data-table-settings/data-table-settings.component';
import { NgxOvDataTableSettingsService } from './data-table-settings/data-table-settings.service';
import { NgxOvFlyoutItemComponent } from '../../navigatie/flyout/flyout-item.component';
import { NgxOvPanelComponent } from '../panel/panel.component';
import { NgxOvLinkComponent } from '../../default-layout-blokken/link/link.component';
import { PortalPanelActionsDirective } from '../panel/portals/portal-panel-actions.directive';
import { PortalModule } from '@angular/cdk/portal';
import { NgxOvButtonComponent } from '../../default-layout-blokken/button/button.component';
import { PortalDataTableCustomFilterDirective } from './portals/portal-data-table-custom-filter.directive';
import { PortalDataTablePagerDirective } from './portals/portal-data-table-pager.directive';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxOvFlyoutComponent } from '../../navigatie/flyout/flyout.component';
import { PortalFlyoutTriggerDirective } from '../../navigatie/flyout/flyout-trigger.directive';
import { PortalDataTableCustomFilterAreaDirective } from './portals/portal-data-table-custom-filter-area.directive';

const noop: any = () => {
  // empty method
};

let identifier = 0;

export const NGX_OV_DATA_TABLE_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => NgxOvDataTableComponent),
  multi: true
};
// =============================================================================
// Component
// =============================================================================
@Component({
  providers: [
    NGX_OV_DATA_TABLE_CONTROL_VALUE_ACCESSOR,
    FilterDataTablePipe,
    CellValuePipe
  ],
  selector: 'ngx-ov-data-table',
  templateUrl: './data-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgxOvDataTableTableComponent,
    NgIf,
    NgxOvDataTableRowComponent,
    NgxOvDataTableColumnComponent,
    NgxOvInputFieldComponent,
    NgFor,
    NgClass,
    FormsModule,
    StopPropagationDirective,
    NgxOvDataTableCellComponent,
    NgSwitch,
    NgSwitchCase,
    NgxOvBooleanIndicatorComponent,
    NgSwitchDefault,
    NgTemplateOutlet,
    SortDataTablePipe,
    FilterDataTablePipe,
    PageDataTablePipe,
    CellValuePipe,
    FilterVisibleColumnsPipe,
    FilterInVisibleColumnsFilterablePipe,
    NgxOvDataTableSettingsComponent,
    NgxOvFlyoutItemComponent,
    NgxOvPanelComponent,
    NgxOvLinkComponent,
    PortalPanelActionsDirective,
    PortalModule,
    NgxOvButtonComponent,
    NgxSkeletonLoaderModule,
    AsyncPipe,
    NgxOvFlyoutComponent,
    PortalFlyoutTriggerDirective
  ]
})
export class NgxOvDataTableComponent
  implements OnInit, ControlValueAccessor, AfterContentInit, OnChanges
{
  @ContentChild(PortalPanelActionsDirective, { static: false })
  actionsPortal: PortalPanelActionsDirective;

  @ContentChild(PortalDataTablePagerDirective, { static: false })
  pagerPortal: PortalDataTablePagerDirective;

  @ContentChildren(PortalDataTableCustomFilterDirective)
  customFilterPortal: PortalDataTableCustomFilterDirective[];

  @ContentChild(PortalDataTableCustomFilterAreaDirective, { static: false })
  customFilterAreaPortal: PortalDataTableCustomFilterAreaDirective;

  title = input<string>(null);
  subTitle = input<string>(null);
  isLoading = input<boolean>(false);
  pagerSignal = signal(null);
  pagerPosition = input<PagerPosition>('bottom');

  selectFilter = signal<'all' | 'selected' | 'unselected'>('all');
  @Input() identifier: string = `datatable-${(identifier += 1)}`;

  /**
   * columns?: ITdDataTableColumn[]
   * Sets additional column configuration. [ITdDataTableColumn.name] has to exist in [data] as key.
   * Defaults to [data] keys.
   */
  @Input() columns: INgxOvDataTableColumn[] = [];

  /* Computed signals */

  _columns: Signal<INgxOvDataTableColumn[]> = computed(() => {
    if (this.columns) {
      let columns: INgxOvDataTableColumn[] = [];

      for (const column of this.columns) {
        let mappedColumn = { ...column };
        mappedColumn.visible = mapVisible(
          column,
          this.settingsService.localstorageDataTableColumns()
        );
        mappedColumn.clickable = !('clickable' in column);
        mappedColumn.filterable = mapFilterable(
          column,
          this.settingsService.localstorageDataTableColumns()
        );
        mappedColumn.filterableDisabled = column.filterableDisabled;
        columns.push(mappedColumn);
      }
      return columns;
    }
    if (this.hasData) {
      const visibleColumns = [];
      // if columns is undefined, use key in [data] rows as name and label for column headers.
      const row: any = this._data[0];
      Object.keys(row).forEach((k: string) => {
        if (!this.columns.find((c: any) => c.name === k)) {
          visibleColumns.push({ name: k, label: k });
        }
      });
      return visibleColumns;
    }
    return [];
  });

  additionalFilterColumns: Signal<INgxOvDataTableColumn[]> = computed(() => {
    return [...this._columns()].filter(
      (column) => !column.visible && column.filterable
    );
  });

  datatableColumns: Signal<INgxOvDataTableColumn[]> = computed(() => {
    return [...this._columns()].filter((column) => column.visible);
  });

  public ngxOvDataTableColumnType = NgxOvDataTableColumnType;
  /** Callback registered via registerOnChange (ControlValueAccessor) */
  public _onChangeCallback: (_: any) => void = noop;
  /** internal attributes */
  public _data$: BehaviorSubject<any> = new BehaviorSubject([]);
  public _sortedData$: Observable<any>;
  public _sortBy$: BehaviorSubject<INgxOvDataTableColumn | string> =
    new BehaviorSubject('test');
  public _sortOrder$: BehaviorSubject<NgxOvDataTableSortingOrder> =
    new BehaviorSubject(NgxOvDataTableSortingOrder.Ascending);
  public _sortCaseSensitive = false;
  public _pager$: BehaviorSubject<IPager | null> = new BehaviorSubject(null);
  public _filters: INgxOvDataTableColumn[] = [];
  /** template fetching support */
  public _templateMap: Map<string, TemplateRef<any>> = new Map<
    string,
    TemplateRef<any>
  >();
  public _extraTemplatesMap: Map<string, TemplateRef<any>> = new Map<
    string,
    TemplateRef<any>
  >();
  trackByFn = trackItemByIndex;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('extraTemplates')
  _extraTemplates: QueryList<NgxOvDataTableTemplateDirective>;
  /**
   * uniqueId?: string
   * Allows selection by [uniqueId] property.
   */
  @Input() uniqueId: string;
  @Input() caption: string;
  @Output() dataChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearFilters: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterChange: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Sets the text to display inside table with no rows.
   */
  @Input() emptyTableText = 'No results';
  /**
   * If you need to use serverside filtering but want to keep using the standard filters set this to true.
   * The datable will not filter but only emit the serversideFilteringChange event with the values of the filters.
   */
  @Input() serversideFiltering = false;
  @Input() clearValueOnDataChange = false;
  @Input() isServersideFilteringButtonVisible = false;
  @Output() serversideFilteringChange: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() serversideSortingChange: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() serversideFilteringClick: EventEmitter<any> =
    new EventEmitter<any>();
  /**
   * sortChange?: function
   * Event emitted when the column headers are clicked. [sortable] needs to be enabled.
   * Emits an [ITdDataTableSortChangeEvent] implemented object.
   */
  @Output() sortChange: EventEmitter<INgxOvDataTableSortChangeEvent> =
    new EventEmitter<INgxOvDataTableSortChangeEvent>();
  /**
   * rowSelect?: function
   * Event emitted when a row is selected/deselected. [selectable] needs to be enabled.
   * Emits an [ITdDataTableSelectEvent] implemented object.
   */
  @Output() rowSelect: EventEmitter<INgxOvDataTableSelectEvent> =
    new EventEmitter<INgxOvDataTableSelectEvent>();
  /**
   * rowSelect?: function
   * Event emitted when a row is selected/deselected. [selectable] needs to be enabled.
   * Emits an [ITdDataTableSelectEvent] implemented object.
   */
  @Output() rowClick: EventEmitter<any> = new EventEmitter<any>();
  /**
   * selectAll?: function
   * Event emitted when all rows are selected/deselected by the all checkbox. [selectable] needs to be enabled.
   * Emits an [ITdDataTableSelectAllEvent] implemented object.
   */
  @Output() selectAll: EventEmitter<INgxOvDataTableSelectAllEvent> =
    new EventEmitter<INgxOvDataTableSelectAllEvent>();
  /**
   * selectionChange?: function
   * Event emitted when the selection of rows was changed.
   * Emits an [ITdDataTableSelectAllEvent] implemented object.
   */
  @Output() selectionChange: EventEmitter<INgxOvDataTableSelectionChangeEvent> =
    new EventEmitter<INgxOvDataTableSelectionChangeEvent>();
  @ContentChildren(NgxOvDataTableTemplateDirective)
  private _templates: QueryList<NgxOvDataTableTemplateDirective>;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private filterDataTable: FilterDataTablePipe,
    private ngxOvDataTableSortService: NgxOvDataTableSortService,
    public settingsService: NgxOvDataTableSettingsService
  ) {
    effect(() => {
      this.filterChange.emit(
        this.filterDataTable.transform(
          this._data,
          this._filters,
          this.selectFilter(),
          this._value,
          this.uniqueId
        )
      );
    });
  }

  /**
   * Implemented as part of ControlValueAccessor.
   */
  public _value: any[] = [];

  get value(): any {
    return this._value;
  }

  /**
   * Implemented as part of ControlValueAccessor.
   */
  @Input() set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this._onChangeCallback(v);
    }
  }

  public _data: any[];

  get data(): any {
    return this._data;
  }

  /**
   * data?: {[key: string]: any}[]
   * Sets the data to be rendered as rows.
   */

  @Input() set data(data: any) {
    if (data instanceof Observable || data instanceof BehaviorSubject) {
      data /* .pipe(takeUntil(this.unsubscribe)) */
        // eslint-disable-next-line no-return-assign
        .subscribe((res) => (this._data = res));
    } else {
      this._data = data;
    }
  }

  public _selectable = false;

  /**
   * selectable?: boolean
   * Enables row selection events, hover and selected row states.
   * Defaults to 'false'
   */
  @Input()
  set selectable(_selectable: string | boolean) {
    this._selectable =
      _selectable !== ''
        ? _selectable === 'true' || _selectable === true
        : true;
  }

  public _clickable = false;

  /**
   * clickable?: boolean
   * Enables row click events.
   * Defaults to 'false'
   */
  @Input()
  set clickable(_clickable: string | boolean) {
    this._clickable =
      _clickable !== '' ? _clickable === 'true' || _clickable === true : true;
  }

  public _multiple = true;

  /**
   * multiple?: boolean
   * Enables multiple row selection. [selectable] needs to be enabled.
   * Defaults to 'false'
   */
  @Input()
  set multiple(multiple: string | boolean) {
    this._multiple =
      multiple !== '' ? multiple === 'true' || multiple === true : true;
  }

  public _detail = false;

  /**
   * detail?: boolean
   * Enables detail dropdowns for row.
   * Defaults to 'false'
   */
  @Input()
  set detail(_detail: string | boolean) {
    this._detail =
      _detail !== '' ? _detail === 'true' || _detail === true : true;
  }

  public _isDetailDefaultVisible = false;

  /**
   * isDetailDefaultVisible?: boolean
   * Handels default display of details.
   * Defaults to 'false'
   */
  @Input()
  set isDetailDefaultVisible(_isDetailDefaultVisible: string | boolean) {
    this._isDetailDefaultVisible =
      _isDetailDefaultVisible !== ''
        ? _isDetailDefaultVisible === 'true' || _isDetailDefaultVisible === true
        : true;
  }

  public _isHeaderVisible = true;

  /**
   * isHeaderVisible?: boolean
   * Hides the header if set to false.
   * Defaults to 'true'
   */
  @Input()
  set isHeaderVisible(_isHeaderVisible: string | boolean) {
    this._isHeaderVisible =
      _isHeaderVisible !== ''
        ? _isHeaderVisible === 'true' || _isHeaderVisible === true
        : true;
  }

  /** sorting */
  public _sortable = false;

  /**
   * sortable?: boolean
   * Enables sorting events, sort icons and active column states.
   * Defaults to 'false'
   */
  @Input()
  set sortable(sortable: string | boolean) {
    this._sortable =
      sortable !== '' ? sortable === 'true' || sortable === true : true;
  }

  public _sortBy: INgxOvDataTableColumn;

  /**
   * sortBy?: string
   * Sets the active sort column. [sortable] needs to be enabled.
   */
  @Input()
  set sortBy(columnName: string) {
    if (!columnName) {
      return;
    }
    const column: INgxOvDataTableColumn | undefined = this.columns.find(
      (c: any) => c.name === columnName
    );
    if (!column) {
      throw new Error('[sortBy] must be a valid column name');
    }

    this._sortBy = column;
    this._sortBy$.next(column);
  }

  public _sortOrder: NgxOvDataTableSortingOrder =
    NgxOvDataTableSortingOrder.Ascending;

  /**
   * sortOrder?: ['ASC' | 'DESC'] or TdDataTableSortingOrder
   * Sets the sort order of the [sortBy] column. [sortable] needs to be enabled.
   * Defaults to 'ASC' or TdDataTableSortingOrder.Ascending
   */
  @Input()
  set sortOrder(order: 'ASC' | 'DESC') {
    const sortOrder: string = order ? order.toUpperCase() : 'ASC';
    if (sortOrder !== 'DESC' && sortOrder !== 'ASC') {
      throw new Error('[sortOrder] must be empty, ASC or DESC');
    }

    this._sortOrder =
      sortOrder === 'ASC'
        ? NgxOvDataTableSortingOrder.Ascending
        : NgxOvDataTableSortingOrder.Descending;
    this._sortOrder$.next(this._sortOrder);
  }

  /** paging * */
  public _pager: IPager;

  /**
   * pager?: string
   * Sets the active sort column. [sortable] needs to be enabled.
   */
  @Input()
  set pager(pager: IPager) {
    this._pager = pager;
    this._pager$.next(pager);
    this.pagerSignal.set(pager);
  }

  /** max checked results * */
  public _maxRowsToSelect: number;

  /**
   * maxRowsToSelect?: number
   * Limits the result of maximum selected rows
   */
  @Input()
  set maxRowsToSelect(maxRowsToSelect: number) {
    this._maxRowsToSelect = maxRowsToSelect;
  }

  /** filtering * */
  public _filterable = false;

  /**
   * filterable?: boolean
   * Enables filtering.
   * Defaults to 'false'
   */
  @Input()
  set filterable(filterable: string | boolean) {
    this._filterable =
      filterable !== '' ? filterable === 'true' || filterable === true : true;
  }

  @Input() showSetting = false;
  widthSelectColumn = 20;

  /* private unsubscribe: Subject<void> = new Subject(); */

  get hasData(): boolean {
    return this._data && this._data.length > 0;
  }

  ngOnInit(): void {
    this.settingsService.init(this.identifier);
    this._sortedData$ = combineLatest(
      [this.data, this._sortBy$, this._sortOrder$, this._pager$],
      ([data, sortby, sortOrder, pager]) => {
        let displayData = this.ngxOvDataTableSortService.sortData(
          data,
          sortby,
          sortOrder.toLowerCase()
        );

        if (pager)
          displayData = displayData.slice(pager.startIndex, pager.endIndex + 1);

        return displayData;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.clearValueOnDataChange) {
      this._value = [];
    }
  }

  /**
   * Loads templates and sets them in a map for faster access.
   */
  ngAfterContentInit(): void {
    for (const template of this._templates.toArray()) {
      this._templateMap.set(
        template.ngxOvDataTableTemplate,
        template.templateRef
      );
    }

    if (this._extraTemplates) {
      for (const extraTemplate of this._extraTemplates.toArray()) {
        this._extraTemplatesMap.set(
          extraTemplate.ngxOvDataTableTemplate,
          extraTemplate.templateRef
        );
      }
    }
  }

  /**
   * Getter method for template references
   */
  getTemplateRef(name: string): TemplateRef<any> | undefined {
    let templateRef: TemplateRef<any> | undefined;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,prefer-const
    templateRef = this._templateMap.get(name);
    return this._templateMap.get(name);
  }

  /**
   * Getter method for template references
   */
  getExtraTemplateRef(name: string): TemplateRef<any> | undefined {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let templateRef: TemplateRef<any> | undefined;
    return this._extraTemplatesMap.get(name);
  }

  /**
   * Clears model (ngModel) of component by removing all values in array.
   */
  clearModel(): void {
    this._value.splice(0, this._value.length);
  }

  /**
   * Refreshes data table and rerenders [data] and [columns]
   */
  refresh(): void {
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Workaround for https://github.com/angular/material2/issues/1825
   */
  tooltipRefresh(): void {
    setTimeout(() => {
      this.refresh();
    }, 100);
  }

  /**
   * Checks if all visible rows are selected.
   */
  areAllVisibleSelected(): boolean {
    const pagedData = this.getVisibleRows();

    if (this._maxRowsToSelect) {
      const match: string = pagedData
        .slice(0, this._maxRowsToSelect)
        .find((row: any) => !isRowSelected(this._value, row, this.uniqueId));
      return typeof match === 'undefined';
    } else {
      const match: string = pagedData.find(
        (row: any) => !isRowSelected(this._value, row, this.uniqueId)
      );
      return typeof match === 'undefined';
    }
  }

  /**
   * Selects or clears all rows depending on 'checked' value.
   */
  onSelectAll(checked: boolean): void {
    if (checked) {
      this._data.forEach((row: any) => {
        // skipping already selected rows
        if (!isRowSelected(this._value, row, this.uniqueId)) {
          this._value.push(row);
        }
      });
    } else {
      this.clearModel();
    }
    this.selectAll.emit({ rows: this._value, selected: checked });
    this.selectionChange.emit({ rows: this._value });
  }

  /**
   * Selects or clears all rows depending on 'checked' value.
   */
  selectAllVisible(checked: boolean): void {
    const pagedData = this.getVisibleRows();

    if (checked) {
      if (this._maxRowsToSelect) {
        // eslint-disable-next-line sonarjs/no-identical-functions
        pagedData.slice(0, this._maxRowsToSelect).forEach((row: any) => {
          // skiping already selected rows
          if (!isRowSelected(this._value, row, this.uniqueId)) {
            this._value.push(row);
          }
        });
      } else {
        // eslint-disable-next-line sonarjs/no-identical-functions
        pagedData.forEach((row: any) => {
          // skiping already selected rows
          if (!isRowSelected(this._value, row, this.uniqueId)) {
            this._value.push(row);
          }
        });
      }
    } else {
      if (this._maxRowsToSelect) {
        // eslint-disable-next-line sonarjs/no-identical-functions
        pagedData.slice(0, this._maxRowsToSelect).forEach((row: any) => {
          // skiping already selected rows
          if (isRowSelected(this._value, row, this.uniqueId)) {
            if (this.uniqueId) {
              // eslint-disable-next-line no-param-reassign,prefer-destructuring
              row = this._value.filter((val: any) => {
                return val[this.uniqueId] === row[this.uniqueId];
              })[0];
            }
            const index: number = this._value.indexOf(row);
            if (index > -1) {
              this._value.splice(index, 1);
            }
          }
        });
      } else {
        // eslint-disable-next-line sonarjs/no-identical-functions
        pagedData.forEach((row: any) => {
          // skiping already selected rows
          if (isRowSelected(this._value, row, this.uniqueId)) {
            if (this.uniqueId) {
              // eslint-disable-next-line no-param-reassign,prefer-destructuring
              row = this._value.filter((val: any) => {
                return val[this.uniqueId] === row[this.uniqueId];
              })[0];
            }
            const index: number = this._value.indexOf(row);
            if (index > -1) {
              this._value.splice(index, 1);
            }
          }
        });
      }
    }
    this.selectAll.emit({ rows: this._value, selected: checked });
    this.selectionChange.emit({ rows: this._value });
  }

  /**
   * Selects or clears a row depending on 'checked' value
   */
  // eslint-disable-next-line consistent-return
  select(
    row: any,
    checked: boolean,
    selectable: boolean,
    clickable: boolean,
    detail: boolean,
    eventFromCheckbox: boolean,
    event: any
  ): void {
    if (!selectable || clickable) {
      return null;
    }
    if (detail && !eventFromCheckbox) {
      return null;
    }
    event.stopPropagation();

    // clears all the fields for the dataset
    if (!this._multiple) {
      this.clearModel();
    }
    if (checked) {
      this._value.push(row);
    } else {
      // if selection is done by a [uniqueId] it uses it to compare, else it compares by reference.
      if (this.uniqueId) {
        // eslint-disable-next-line no-param-reassign,prefer-destructuring
        row = this._value.filter((val: any) => {
          return val[this.uniqueId] === row[this.uniqueId];
        })[0];
      }
      const index: number = this._value.indexOf(row);
      if (index > -1) {
        this._value.splice(index, 1);
      }
    }
    this.rowSelect.emit({ row, selected: checked });
    this.selectionChange.emit({ rows: this._value });
    this.onChange(this._value);
  }

  /**
   * Selects or clears a row depending on 'checked' value
   */
  openRow(row: any, tableClickable: boolean, columnClickable: boolean): void {
    if (this._detail) {
      row.isDetailVisible = !row.isDetailVisible;
    } else if (tableClickable && columnClickable) {
      this.rowClick.emit(row);
    }
  }

  /**
   * Method handle for sort click event in column headers.
   */
  handleSort(column: INgxOvDataTableColumn): void {
    let nextSortBy = this._sortBy;
    let nextSortOrder = this._sortOrder;

    if (this._sortable && column.sortable) {
      if (this._sortBy === column) {
        if (this._sortOrder === NgxOvDataTableSortingOrder.Descending) {
          nextSortBy = null;
        }
        nextSortOrder =
          this._sortOrder == NgxOvDataTableSortingOrder.Ascending
            ? NgxOvDataTableSortingOrder.Descending
            : null;
        this._sortOrder$.next(this._sortOrder);
      } else {
        nextSortBy = column;
        this._sortBy$.next(column);
        nextSortOrder =
          this._sortOrder === NgxOvDataTableSortingOrder.Descending
            ? NgxOvDataTableSortingOrder.Ascending
            : NgxOvDataTableSortingOrder.Descending;
      }

      this._sortCaseSensitive = column.sortCaseSensitive
        ? column.sortCaseSensitive
        : false;

      if (this.serversideFiltering) {
        this.serversideSortingChange.emit({
          name: nextSortBy ? nextSortBy.name : '',
          caseSensitive: this._sortCaseSensitive,
          order: nextSortOrder
        });
      } else {
        this._sortBy = nextSortBy;
        this._sortOrder = nextSortOrder;
        this.sortChange.next({
          name: this._sortBy ? this._sortBy.name : '',
          caseSensitive: this._sortCaseSensitive,
          order: this._sortOrder
        });
      }
    }
  }

  handleFilter(e: any, column: INgxOvDataTableColumn): void {
    if (this._filterable) {
      const columnFilter: INgxOvDataTableColumn = column;
      columnFilter.filter = e.target.value;

      const newFilter: INgxOvDataTableColumn[] = R.unionWith(
        R.eqBy(R.prop('name')),
        this._filters,
        [columnFilter]
      ).filter((filter) => !!filter.filter);

      this._filters = newFilter;

      if (this.serversideFiltering) {
        this.serversideFilteringChange.emit(this._filters);
      } else {
        this.filterChange.emit(
          this.filterDataTable.transform(
            this._data,
            this._filters,
            this.selectFilter(),
            this._value,
            this.uniqueId
          )
        );
      }
    }
  }

  onClearFilters(columns: INgxOvDataTableColumn[]): void {
    columns.forEach((column) => {
      column.filter = '';
    });
  }

  /**
   * Implemented as part of ControlValueAccessor.
   */
  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/no-unused-vars,@angular-eslint/no-output-on-prefix
  onChange = (_: any) => noop;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onTouched = () => noop;

  blockEvent(event: any): any {
    event.preventDefault();
    return false;
  }

  isDetailVisible(row: any) {
    if (row.isDetailVisible === undefined) {
      row.isDetailVisible = this._isDetailDefaultVisible;
    }
    return row.isDetailVisible;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackItemByIndex(index, item) {
    return index;
  }

  toggleSettings() {}

  onServersideFilter() {
    this.serversideFilteringClick.emit(this._filters);
  }

  verwijderFilters() {
    this.pagerSignal.set(null);
    this._filters = [];
    for (let col of this._columns()) {
      col.filter = null;
    }
    this.serversideFilteringClick.emit([]);
    this.clearFilters.emit(null);
  }

  isRowSelected(row: any) {
    return isRowSelected(this._value, row, this.uniqueId);
  }

  getVisibleRows() {
    const filteredData = new FilterDataTablePipe().transform(
      this._data,
      this._filters,
      this.selectFilter(),
      this._value,
      this.uniqueId
    );
    const sortedData = new SortDataTablePipe(
      this.ngxOvDataTableSortService
    ).transform(filteredData, this._sortBy, this._sortOrder);
    return new PageDataTablePipe().transform(sortedData, this._pager);
  }
}
