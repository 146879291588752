export interface ICodeOmschrijvingRestDto {
  code: string;
  omschrijving: string;
}

export class CodeOmschrijvingRestDto implements ICodeOmschrijvingRestDto {
  code: string;
  omschrijving: string;
}

export interface IEnumOverzichtResultaatRestDto {
  content?: Array<CodeOmschrijvingRestDto>;
}

export class EnumOverzichtResultaatRestDto
  implements IEnumOverzichtResultaatRestDto
{
  content?: Array<CodeOmschrijvingRestDto>;
}