<div
  class="c-checkbox {{ classes }}"
  [class.c-checkbox--selected]="value"
  [class.is-disabled]="isDisabled"
  [class.is-error]="isError"
  [class.show-block]="showAsBlock"
  (click)="clickCheckbox($event)"
  (keyup.enter)="clickCheckbox($event)"
  (keyup.space)="clickCheckbox($event)"
>
  @if (!isReadonly) {
    <ng-container>
      <div
        class="c-checkbox__container"
        (click)="clickCheckbox($event)"
        tabindex="-1"
      >
        <div class="c-checkbox__toggle">
          <input
            class="c-checkbox__toggle_input"
            type="checkbox"
            id="{{ id }}"
            [name]="label"
            [(ngModel)]="value"
            [disabled]="isDisabled"
            (change)="onChange(value)"
            [attr.data-cy]="code"
            #input
            tabindex="-1"
          />
          <i
            [attr.data-cy]="'checkbox-click'"
            class="c-checkbox__toggle_box"
            tabindex="-1"
            aria-hidden="true"
            #triggerCheckbox
          ></i>
        </div>
        <div class="c-checkbox__label" [class.cursor-pointer]="!isDisabled">
          <label for="{{ id }}" [innerHTML]="label"></label>

          @if (extraInfo) {
            <ngx-ov-tooltip
              class="hidden-mobile"
              (click)="$event.stopPropagation()"
              >{{ extraInfo }}
            </ngx-ov-tooltip>
          }
          &nbsp;
        </div>

        @if (icon) {
          <div class="c-checkbox__icon">
            <i class="{{ icon }}" aria-hidden="true"></i>
          </div>
        }
      </div>

      <!-- Extra Information -->
      @if (extraInfo) {
        <div class="c-checkbox__extra-info hidden-desktop">
          <ngx-ov-extra-info [text]="extraInfo"></ngx-ov-extra-info>
        </div>
      }

      @if (value && contentPortal) {
        <div class="c-checkbox__content" (click)="$event.stopPropagation()">
          <ng-template [cdkPortalOutlet]="contentPortal"></ng-template>
        </div>
      }
    </ng-container>
  } @else {
    <div class="c-checkbox__icon-readonly">
      @if (value) {
        <i
          [attr.data-cy]="'checkbox-icon'"
          data-testid="iconCheck"
          class="fa fa-check c-checkbox__icon-check-readonly"
          title="Checked"
          aria-hidden="true"
        ></i>
        <span class="sr-only">checked</span>
      } @else {
        <i
          [attr.data-cy]="'checkbox-icon'"
          data-testid="iconXmark"
          class="fa fa-xmark c-checkbox__icon-xmark-readonly"
          title="Not Checked"
          aria-hidden="true"
        ></i>
        <span class="sr-only">not checked</span>
      }

      <ngx-ov-input-label
        [text]="label"
        [isReadonly]="true"
      ></ngx-ov-input-label>
    </div>
  }
</div>
