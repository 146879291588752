/**
 * Naric
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 4.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { BenodigdeDocumentenSelectiecriteriaRestDto } from '././benodigdeDocumentenSelectiecriteriaRestDto';
import { BenodigdeDocumentRestDto } from '././benodigdeDocumentRestDto';


import {ValidatorFn, AbstractControlOptions, FormGroup, FormBuilder, FormArray} from '@angular/forms';
import { SwaggerValidators } from '@ov/ngx-ov-utils';

export interface IResultaatInfoRestDto {
	benodigde_documenten?: Array<BenodigdeDocumentRestDto>;
	generieke_waarden?: { [key: string]: string; };
	kost?: number;
	looptijd?: number;
	selectie_criteria?: BenodigdeDocumentenSelectiecriteriaRestDto;
}


export class ResultaatInfoRestDto implements IResultaatInfoRestDto {
	benodigde_documenten?: Array<BenodigdeDocumentRestDto>;
	generieke_waarden?: { [key: string]: string; };
	kost?: number;
	looptijd?: number;
	selectie_criteria?: BenodigdeDocumentenSelectiecriteriaRestDto;
}

export class ResultaatInfoRestDtoFormHelper {

    static generateFormWithSwaggerValidators(formBuilder: FormBuilder, controlsConfig: {[key: string]: any;}, options?: AbstractControlOptions | {[key: string]: any;} | null): FormGroup{
        if(controlsConfig["benodigde_documenten"] != undefined && !(controlsConfig["benodigde_documenten"] instanceof FormGroup) && !(controlsConfig["benodigde_documenten"] instanceof FormArray)){
            controlsConfig["benodigde_documenten"][1] = controlsConfig["benodigde_documenten"][1].concat(ResultaatInfoRestDtoValidator.benodigde_documenten_swagger_validator());
        }
        if(controlsConfig["generieke_waarden"] != undefined && !(controlsConfig["generieke_waarden"] instanceof FormGroup) && !(controlsConfig["generieke_waarden"] instanceof FormArray)){
            controlsConfig["generieke_waarden"][1] = controlsConfig["generieke_waarden"][1].concat(ResultaatInfoRestDtoValidator.generieke_waarden_swagger_validator());
        }
        if(controlsConfig["kost"] != undefined && !(controlsConfig["kost"] instanceof FormGroup) && !(controlsConfig["kost"] instanceof FormArray)){
            controlsConfig["kost"][1] = controlsConfig["kost"][1].concat(ResultaatInfoRestDtoValidator.kost_swagger_validator());
        }
        if(controlsConfig["looptijd"] != undefined && !(controlsConfig["looptijd"] instanceof FormGroup) && !(controlsConfig["looptijd"] instanceof FormArray)){
            controlsConfig["looptijd"][1] = controlsConfig["looptijd"][1].concat(ResultaatInfoRestDtoValidator.looptijd_swagger_validator());
        }
        if(controlsConfig["selectie_criteria"] != undefined && !(controlsConfig["selectie_criteria"] instanceof FormGroup) && !(controlsConfig["selectie_criteria"] instanceof FormArray)){
            controlsConfig["selectie_criteria"][1] = controlsConfig["selectie_criteria"][1].concat(ResultaatInfoRestDtoValidator.selectie_criteria_swagger_validator());
        }
        if(options){
            return formBuilder.group(controlsConfig, options);
        }
        return formBuilder.group(controlsConfig);
    }
}

export class ResultaatInfoRestDtoValidator {

        /**
        * Active Validators for benodigde_documenten:
        */
        static benodigde_documenten_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

        /**
        * Active Validators for generieke_waarden:
        */
        static generieke_waarden_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

        /**
        * Active Validators for kost:
        */
        static kost_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

        /**
        * Active Validators for looptijd:
        */
        static looptijd_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

        /**
        * Active Validators for selectie_criteria:
        */
        static selectie_criteria_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

}



