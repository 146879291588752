import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  Injectable,
  InjectionToken,
  ModuleWithProviders,
  NgModule
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule
} from '@angular/platform-browser';
import { TippyDirective } from '@ngneat/helipopper';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  FlatpickrDefaultsInterface,
  FlatpickrModule
} from 'angularx-flatpickr';

import * as Hammer from 'hammerjs';
import { A11yModule } from '@angular/cdk/a11y';
import { NgxOvProfileComponent } from './components/diverse/profile/profile.component';
import { SafePipe } from './pipes/safe.pipe';
import { NgxOvBadgeComponent } from './components/meldingen/badge/badge.component';
import { NgxOvBooleanIndicatorComponent } from './components/forms/boolean-indicator/boolean-indicator.component';
import { NgxOvButtonComponent } from './components/default-layout-blokken/button/button.component';
import { NgxOvLinkComponent } from './components/default-layout-blokken/link/link.component';
import { NgxOvLoaderComponent } from './components/default-layout-blokken/loader/loader.component';
import { NgxOvPillComponent } from './components/diverse/pill/pill.component';
import { NgxOvActionListItemComponent } from './components/diverse/action-list/action-list-item.component';
import { NgxOvActionListComponent } from './components/diverse/action-list/action-list.component';
import { PortalActionListItemActionsDirective } from './components/diverse/action-list/portal-action-list-item-actions.directive';
import { PortalActionListItemDetailDirective } from './components/diverse/action-list/portal-action-list-item-detail.directive';
import { NgxOvAlertComponent } from './components/meldingen/alert/alert.component';
import { NgxOvArrowNavigationComponent } from './components/navigatie/arrow-navigation/arrow-navigation.component';
import { NgxOvBannerComponent } from './components/default-layout-blokken/banner/banner.component';
import { NgxOvBreadcrumbItemComponent } from './components/navigatie/breadcrumb/breadcrumb-item.component';
import { NgxOvBreadcrumbComponent } from './components/navigatie/breadcrumb/breadcrumb.component';
import { NgxOvContactCardComponent } from './components/default-layout-blokken/contact-card/contact-card.component';
import { NgxOvControlPanelItemComponent } from './components/data-visualisatie/control-panel/control-panel-item/control-panel-item.component';
import { NgxOvControlPanelComponent } from './components/data-visualisatie/control-panel/control-panel.component';
import { NgxOvCookiebarComponent } from './components/default-layout-blokken/cookiebar/cookiebar.component';
import { NgxOvDataTableCellComponent } from './components/data-visualisatie/data-table/data-table-cell/data-table-cell.component';
import { NgxOvDataTableColumnComponent } from './components/data-visualisatie/data-table/data-table-column/data-table-column.component';
import { NgxOvDataTableRowComponent } from './components/data-visualisatie/data-table/data-table-row/data-table-row.component';
import { NgxOvDataTableTableComponent } from './components/data-visualisatie/data-table/data-table-table/data-table-table.component';
import { NgxOvDataTableComponent } from './components/data-visualisatie/data-table/data-table.component';
import { NgxOvDataTableTemplateDirective } from './components/data-visualisatie/data-table/directives/data-table-template.directive';
import { NgxOvPagerNavigationComponent } from './components/data-visualisatie/data-table/pager/components/pager-navigation.component';
import { NgxOvPagerInfoComponent } from './components/data-visualisatie/data-table/pager/components/pager-pageinfo.component';
import { NgxOvPagerPageSizeComponent } from './components/data-visualisatie/data-table/pager/components/pager-pagesize.component';
import { NgxOvUiPagerComponent } from './components/data-visualisatie/data-table/pager/pager.component';
import { PagerService } from './components/data-visualisatie/data-table/pager/services/pager.service';
import { NgxOvDataTableService } from './components/data-visualisatie/data-table/services/data-table.service';
import { NgxOvDoormatACMComponent } from './components/content-blokken/doormat/doormat-acm/doormat-acm.component';
import { NgxOvDoormatComponent } from './components/content-blokken/doormat/doormat.component';
import { NgxOvFlyoutItemComponent } from './components/navigatie/flyout/flyout-item.component';
import { NgxOvFlyoutComponent } from './components/navigatie/flyout/flyout.component';
import { NgxOvFooterComponent } from './components/default-layout-blokken/footer/footer.component';
import { NgxOvInternalFooterComponent } from './components/default-layout-blokken/internal-footer/internal-footer.component';
import { NgxOvGlobalAlertComponent } from './components/meldingen/global-alert/global-alert.component';
import { NgxOvHeaderTitleLinkComponent } from './components/default-layout-blokken/header/header-title-link/header-title-link.component';
import { NgxOvHeaderComponent } from './components/default-layout-blokken/header/header.component';
import { PortalHeaderContentDirective } from './components/default-layout-blokken/header/portals/portal-header-content.directive';
import { NgxOvInfoBlockComponent } from './components/content-blokken/info-block/info-block.component';
import { NgxOvNewsTeaserComponent } from './components/content-blokken/news-teaser/news-teaser.component';
import { NgxOvPanelComponent } from './components/data-visualisatie/panel/panel.component';
import { PortalPanelActionsDirective } from './components/data-visualisatie/panel/portals/portal-panel-actions.directive';
import { NgxOvSpotlightComponent } from './components/content-blokken/spotlight/spotlight.component';
import { NgxOvTabComponent } from './components/navigatie/tabs/tab.component';
import { NgxOvTabsComponent } from './components/navigatie/tabs/tabs.component';
import { StopPropagationDirective } from './directives/stop-propagation';
import { NgxOv3WayToggleComponent } from './components/forms/3-way-toggle/3-way-toggle.component';
import { NgxOvCheckboxGroupComponent } from './components/forms/checkbox/checkbox-group/checkbox-group.component';
import { NgxOvCheckboxNestedComponent } from './components/forms/checkbox/checkbox-item/checkbox-nested.component';
import { NgxOvCheckboxComponent } from './components/forms/checkbox/checkbox-item/checkbox.component';
import { NgxOvDatepickerComponent } from './components/forms/datepicker/datepicker.component';
import { NgxOvFormMessageComponent } from './components/forms/form-message/form-message.component';
import { NgxOvInputFieldComponent } from './components/forms/input-field/input-field.component';
import { NgxOvInputLabelComponent } from './components/forms/input-label/input-label.component';
import { NgxOvInputValidationComponent } from './components/forms/input-validation/input-validation.component';
import {
  PortalRadioContentDirective,
  PortalRadioLabelDirective
} from './components/forms/radio/portals/portal-radio-content.directive';
import { NgxOvRadioGroupComponent } from './components/forms/radio/radio-group/radio-group.component';
import { NgxOvRadioComponent } from './components/forms/radio/radio-item/radio.component';
import { NgxOvRequiredMarkComponent } from './components/forms/required-mark/required-mark.component';
import { NgxOvTextareaComponent } from './components/forms/textarea/textarea.component';
import { NgxOvToggleComponent } from './components/forms/toggle/toggle.component';
import { NgxOv404Component } from './fundamentals/404/404.component';
import { PortalSearchFilterDirective } from './fundamentals/search/portals/portal-search-filter.directive';
import { NgxOvSearchComponent } from './fundamentals/search/search.component';
import { NgxOvTypographyComponent } from './fundamentals/typography/typography.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { PortalFooterDirective } from './layout/main-layout/portals/portal-footer.directive';
import { PortalHeaderDirective } from './layout/main-layout/portals/portal-header.directive';
import { PortalLeftNavigationDirective } from './layout/main-layout/portals/portal-left-navigation.directive';
import { PortalCheckboxContentDirective } from './components/forms/checkbox/portals/portal-checkbox-content.directive';
import { NgxOvModalComponent } from './components/meldingen/modal/modal.component';
import { PortalTitleContentDirective } from './components/data-visualisatie/expansion-panel/expansion-panel-item/portal-title-content.directive';
import { PortalRightContentDirective } from './components/data-visualisatie/expansion-panel/expansion-panel-item/portal-right-content.directive';
import { PortalSearchResultFooterDirective } from './components/data-visualisatie/expansion-panel/expansion-panel-item/portal-footer.directive';
import { NgxOvExpansionPanelItemComponent } from './components/data-visualisatie/expansion-panel/expansion-panel-item/expansion-panel-item.component';
import { NgxOvExpansionPanelListComponent } from './components/data-visualisatie/expansion-panel/expansion-panel-list.component';
import { NgxOvLanguageSwitchComponent } from './components/default-layout-blokken/language-switch/language-switch.component';
import { NgxOvModalService } from './components/meldingen/modal/modal.service';
import { NgxOvBadgeDirective } from './directives/badge';
import { NgxOvAgendaComponent } from './components/content-blokken/agenda/agenda.component';
import { NgxOvAgendaItemComponent } from './components/content-blokken/agenda/agenda-item.component';
import { NgxOvCardComponent } from './components/content-blokken/card/card.component';
import { PortalCardDirective } from './components/content-blokken/card/portals/portal-card.directive';
import { PortalAgendaActionsDirective } from './components/content-blokken/agenda/portals/portal-panel-actions.directive';
import { LinkService } from './services/link.service';
import { PortalFlyoutItemContentDirective } from './components/navigatie/flyout/flyout-item-content.directive';
import { PortalFlyoutBottomNavigationDirective } from './components/navigatie/flyout/flyout-bottom-navigation.directive';
import { NgxOvExtraInfoComponent } from './components/forms/extra-info/extra-info.component';
import { PortalFlyoutTriggerDirective } from './components/navigatie/flyout/flyout-trigger.directive';
import { NgxOvTooltipComponent } from './components/meldingen/tooltip/tooltip.component';
import { NgxOvStatusMessageComponent } from './fundamentals/status-message/status-message.component';
import { NgxOvMainLayoutService } from './layout/main-layout/main.layout.service';
import { PortalExpansionPanelListEmptyStateDirective } from './components/data-visualisatie/expansion-panel/expansion-panel-list-empty-state.directive';
import { PortalModalFooterDirective } from './components/meldingen/modal/modal-footer.directive';
import { GdprService } from './services/gdpr.service';
import { NgxOvLoginPageComponent } from './layout/login-page/login-page.component';
import { NgxOvLoginPageLoginButtonComponent } from './layout/login-page/login-page-login-button/login-page-login-button.component';
import { NgxOvHighlightListComponent } from './components/content-blokken/highlight/login-page-highlight-list/highlight-list.component';
import { NgxOvHighlightItemComponent } from './components/content-blokken/highlight/login-page-highlight-item/highlight-item.component';
import { NgxOvNewsTeaserListComponent } from './components/content-blokken/news-teaser/news-teaser-list.component';
import { NgxOvToggleButtonComponent } from './components/forms/toggle-button/toggle-button.component';
import { NgxOvToggleButtonGroupComponent } from './components/forms/toggle-button/toggle-button-group.component';
import { NgxOvProgressBarComponent } from './components/forms/progress-bar/progress-bar.component';
import { NgxOvWizardNavigationComponent } from './components/navigatie/wizard-navigation/wizard-navigation.component';
import { PortalWizardNavigationLeftContentDirective } from './components/navigatie/wizard-navigation/portals/portal-wizard-navigation-left-content.directive';
import { PortalWizardNavigationRightContentDirective } from './components/navigatie/wizard-navigation/portals/portal-wizard-navigation-right-content.directive';
import { NgxOvCardGroupComponent } from './components/content-blokken/card/card-group.component';
import { HighlightSearchPipe } from './pipes/highlight.pipe';
import { NgxOvSelectComponent } from './components/forms/select/select.component';
import { ExtendedBindValuePipe } from './components/forms/select/pipes/getExtendedBindValue.pipe';
import { NgxOvUploadComponent } from './components/forms/upload/upload.component';
import { NgxOvNavigationComponent } from './components/navigatie/side-navigation/navigation.component';
import { NgxOvNavigationItemComponent } from './components/navigatie/side-navigation/navigation-item.component';
import { NgxOvNavigationSubItemComponent } from './components/navigatie/side-navigation/navigation-sub-item.component';
import { NgxOvNavigationService } from './components/navigatie/side-navigation/navigation.service';
import { NgxOvProgressBlockComponent } from './components/content-blokken/progress-block/progress-block.component';
import { PortalCookiebarDirective } from './layout/main-layout/portals/portal-cookiebar.directive';
import { NgxOvDataTableSortService } from './components/data-visualisatie/data-table/services/data-table-sort.service';
import { SortDataTablePipe } from './components/data-visualisatie/data-table/pipes/sortDataTable.pipe';
import { FilterDataTablePipe } from './components/data-visualisatie/data-table/pipes/filterDataTable.pipe';
import { PageDataTablePipe } from './components/data-visualisatie/data-table/pipes/pageDataTable.pipe';
import { CellValuePipe } from './components/data-visualisatie/data-table/pipes/getCellValue.pipe';
import { ClickEqualsEnterDirective } from './directives/click-equals-enter';
import { NgxOvSnackbarComponent } from './components/meldingen/snackbar/snackbar.component';
import { PortalSnackbarDirective } from './components/meldingen/snackbar/portals/portal-snackbar.directive';
import { NgxOvDatepickerInputsComponent } from './components/forms/datepicker-inputs/datepicker-inputs.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgxOvStepperComponent } from './components/forms/ov-stepper/ov-stepper.component';
import { NgxOvStepComponent } from './components/forms/ov-stepper/ov-step.component';
import {
  OvStepperNextDirective,
  OvStepperPreviousDirective
} from './components/forms/ov-stepper/ov-stepper-navigations';
import { PortalCardTitleDirective } from './components/content-blokken/card/portals/portal-card-title.directive';
import { NgxOvSnackbarService } from './components/meldingen/snackbar/snackbar.service';
import { NgxOvDocumentViewerComponent } from './components/diverse/document-viewer/document-viewer.component';
import { DomSanitizerPipe } from './components/diverse/document-viewer/dom-sanitizer.pipe';
import {
  NgxOvUiConfigServiceOptions,
  NgxOvUiModuleConfig
} from './models/ngx-ov-ui.config';
import { NgxOvDatepickerInputsService } from './components/forms/datepicker-inputs/datepicker-inputs.service';
import { UploadService } from './services/upload.service';
import { PathPipe } from './pipes/path.pipe';
import { PapyrusUploadComponent } from './fundamentals/papyrus-upload/papyrus-upload.component';
import { PortalSearchResultFilterDirective } from './fundamentals/search/portals/portal-search-result-filter.directive';
import { PortalSearchButtonContainerDirective } from './fundamentals/search/portals/portal-search-button-container.directive';
import { TranslationsService } from './services/translations.service';
import { NgxOvInputReadonlyComponent } from './components/forms/input-readonly/input-readonly.component';
import { NgxOvMenubarComponent } from './components/navigatie/menubar/menubar.component';
import { NgxOvMenubarItemComponent } from './components/navigatie/menubar/menubar-item.component';
import { PortalMenubarDirective } from './components/navigatie/menubar/portals/portal-menubar.directive';
import { NgxOvNoDataComponent } from './fundamentals/no-data/no-data.component';
import { NgxOvMessageBarComponent } from './components/data-visualisatie/message-bar/message-bar.component';
import { PortalMessageBarActionsDirective } from './components/data-visualisatie/message-bar/portals/portal-message-bar-actions.directive';
import { TabTitleContentDirective } from './components/navigatie/tabs/tab-title-content.directive';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BevestigingInterceptor } from './fundamentals/bevestiging/bevestiging.interceptor';
import { PortalSearchInfoDirective } from './fundamentals/search/portals/portal-search-info.directive';

export const flatpickerConfig: FlatpickrDefaultsInterface = {
  dateFormat: 'd-m-Y'
};

export const FOR_ROOT_CONFIG_TOKEN = new InjectionToken<NgxOvUiModuleConfig>(
  'forRoot() NgxOvUiModule configuration.'
);

export function provideConfigForService(
  config?: NgxOvUiModuleConfig
): NgxOvUiConfigServiceOptions {
  const serviceConfiguration = new NgxOvUiConfigServiceOptions();

  if (config) {
    if (typeof config.cypress === 'boolean') {
      serviceConfiguration.cypress = config.cypress;
    }

    if (config.uploadOptions) {
      if (typeof config.uploadOptions.acceptedFileTypes) {
        serviceConfiguration.uploadOptions.acceptedFileTypes =
          config.uploadOptions.acceptedFileTypes;
      }
      if (typeof config.uploadOptions.maxFileSizeInMB === 'number') {
        serviceConfiguration.uploadOptions.maxFileSizeInMB =
          config.uploadOptions.maxFileSizeInMB;
      }
      if (typeof config.uploadOptions.maxLengthFileName === 'number') {
        serviceConfiguration.uploadOptions.maxLengthFileName =
          config.uploadOptions.maxLengthFileName;
      }
    }
  }

  return serviceConfiguration;
}

// making hammer config (3)
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL }
  };
}

const TD_DATA_TABLE = [
  NgxOvDataTableComponent,
  NgxOvDataTableTemplateDirective,

  NgxOvDataTableColumnComponent,
  NgxOvDataTableCellComponent,
  NgxOvDataTableRowComponent,
  NgxOvDataTableTableComponent,

  NgxOvPagerNavigationComponent,
  NgxOvPagerInfoComponent,
  NgxOvPagerPageSizeComponent,

  SortDataTablePipe,
  FilterDataTablePipe,
  PageDataTablePipe,
  CellValuePipe
];

const PUBLIC_COMPONENTS = [
  // atoms
  NgxOvBadgeComponent,
  NgxOvButtonComponent,
  NgxOvLinkComponent,
  NgxOvLoaderComponent,
  NgxOvPillComponent,

  // components
  NgxOvActionListComponent,
  NgxOvActionListItemComponent,
  NgxOvAgendaComponent,
  NgxOvAgendaItemComponent,
  NgxOvAlertComponent,
  NgxOvArrowNavigationComponent,
  NgxOvBannerComponent,
  NgxOvBreadcrumbComponent,
  NgxOvBreadcrumbItemComponent,
  NgxOvCardComponent,
  NgxOvCardGroupComponent,
  NgxOvContactCardComponent,
  NgxOvCookiebarComponent,
  NgxOvDoormatComponent,
  NgxOvDoormatACMComponent,
  NgxOvFlyoutComponent,
  NgxOvFlyoutItemComponent,
  NgxOvFooterComponent,
  NgxOvInternalFooterComponent,
  NgxOvGlobalAlertComponent,
  NgxOvHeaderComponent,
  NgxOvHeaderTitleLinkComponent,
  NgxOvInfoBlockComponent,
  NgxOvNavigationComponent,
  NgxOvNavigationItemComponent,
  NgxOvNavigationSubItemComponent,
  NgxOvNewsTeaserComponent,
  NgxOvNewsTeaserListComponent,
  NgxOvPanelComponent,
  NgxOvProfileComponent,
  NgxOvProgressBarComponent,
  NgxOvProgressBlockComponent,
  NgxOvSpotlightComponent,
  NgxOvTabsComponent,
  NgxOvTabComponent,
  NgxOvUploadComponent,
  NgxOvUiPagerComponent,
  NgxOvExpansionPanelItemComponent,
  NgxOvExpansionPanelListComponent,
  NgxOvControlPanelItemComponent,
  NgxOvControlPanelComponent,
  NgxOvModalComponent,
  NgxOvTooltipComponent,
  NgxOvWizardNavigationComponent,
  NgxOvSnackbarComponent,
  NgxOvDocumentViewerComponent,
  NgxOvMenubarComponent,
  NgxOvMenubarItemComponent,
  NgxOvMessageBarComponent,

  // forms
  NgxOvCheckboxComponent,
  NgxOvCheckboxNestedComponent,
  NgxOvCheckboxGroupComponent,
  NgxOvDatepickerComponent,
  NgxOvFormMessageComponent,
  NgxOvInputValidationComponent,
  NgxOvInputFieldComponent,
  NgxOvRadioGroupComponent,
  NgxOvRadioComponent,
  NgxOvTextareaComponent,
  NgxOvInputLabelComponent,
  NgxOvRequiredMarkComponent,
  NgxOvSelectComponent,
  ExtendedBindValuePipe,
  NgxOvBooleanIndicatorComponent,
  NgxOvToggleComponent,
  NgxOv3WayToggleComponent,
  NgxOvExtraInfoComponent,
  NgxOvToggleButtonGroupComponent,
  NgxOvToggleButtonComponent,
  NgxOvDatepickerInputsComponent,
  NgxOvStepperComponent,
  NgxOvStepComponent,
  NgxOvInputReadonlyComponent,

  // fundamentals
  NgxOv404Component,
  NgxOvNoDataComponent,
  NgxOvStatusMessageComponent,
  NgxOvLanguageSwitchComponent,
  NgxOvSearchComponent,
  NgxOvTypographyComponent,

  // datatable
  ...TD_DATA_TABLE,

  // layout
  MainLayoutComponent,
  NgxOvLoginPageComponent,
  NgxOvLoginPageLoginButtonComponent,
  NgxOvHighlightListComponent,
  NgxOvHighlightItemComponent,

  PapyrusUploadComponent
];

const PUBLIC_DIRECTIVES = [
  StopPropagationDirective,
  PortalHeaderDirective,
  PortalFooterDirective,
  PortalCookiebarDirective,
  PortalLeftNavigationDirective,
  PortalHeaderContentDirective,
  PortalTitleContentDirective,
  PortalRightContentDirective,
  PortalSearchFilterDirective,
  PortalSearchResultFilterDirective,
  PortalSearchResultFooterDirective,
  PortalSearchInfoDirective,
  PortalSearchButtonContainerDirective,
  PortalActionListItemActionsDirective,
  PortalActionListItemDetailDirective,
  PortalAgendaActionsDirective,
  PortalPanelActionsDirective,
  PortalRadioContentDirective,
  PortalRadioLabelDirective,
  PortalCheckboxContentDirective,
  PortalCardDirective,
  PortalCardTitleDirective,
  NgxOvBadgeDirective,
  ClickEqualsEnterDirective,
  PortalFlyoutItemContentDirective,
  PortalFlyoutBottomNavigationDirective,
  PortalFlyoutTriggerDirective,
  PortalExpansionPanelListEmptyStateDirective,
  PortalModalFooterDirective,
  PortalWizardNavigationLeftContentDirective,
  PortalWizardNavigationRightContentDirective,
  PortalSnackbarDirective,
  OvStepperNextDirective,
  OvStepperPreviousDirective,
  PortalMenubarDirective,
  PortalMessageBarActionsDirective,
  TippyDirective,
  TabTitleContentDirective
];
const PUBLIC_PIPES = [PathPipe];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    PortalModule,
    DragDropModule,
    NgSelectModule,
    FlatpickrModule.forRoot(flatpickerConfig),
    FormsModule,
    ReactiveFormsModule,
    HammerModule,
    A11yModule,
    CdkStepperModule,
    ...PUBLIC_COMPONENTS,
    ...PUBLIC_DIRECTIVES,
    ...PUBLIC_PIPES,
    SafePipe,
    HighlightSearchPipe,
    DomSanitizerPipe
  ],
  exports: [
    ...PUBLIC_COMPONENTS,
    ...PUBLIC_DIRECTIVES,
    ...PUBLIC_PIPES,
    NgSelectModule,
    FlatpickrModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BevestigingInterceptor,
      multi: true
    }
  ]
})
export class NgxOvUiModule {
  static forRoot(
    config?: NgxOvUiModuleConfig
  ): ModuleWithProviders<NgxOvUiModule> {
    return {
      ngModule: NgxOvUiModule,
      providers: [
        NgxOvDataTableService,
        NgxOvDataTableSortService,
        PagerService,
        LinkService,
        UploadService,
        NgxOvNavigationService,
        NgxOvModalService,
        NgxOvSnackbarService,
        NgxOvDatepickerInputsService,
        NgxOvMainLayoutService,
        TranslationsService,
        GdprService,
        { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
        { provide: FOR_ROOT_CONFIG_TOKEN, useValue: config },
        {
          provide: NgxOvUiConfigServiceOptions,
          useFactory: provideConfigForService,
          deps: [FOR_ROOT_CONFIG_TOKEN]
        }
      ]
    };
  }
}
