<div class="c-info-block">
  <div class="c-info-block__header" (click)="onToggleClicked()">
    <div class="c-info-block__icon">
      <i class="{{ icon }}" aria-hidden="true"></i>
    </div>

    <h2 class="c-info-block__title">{{ title }}</h2>

    <div class="c-info-block__collapsable">
      @if (isCollapsed) {
        <i
          class="fas fa-chevron-right"
          aria-hidden="true"
          [title]="infoBlockClosed"
        ></i>
      } @else {
        <i
          class="fas fa-chevron-down"
          aria-hidden="true"
          [title]="infoBlockOpen"
        ></i>
      }

      <span class="sr-only">{{
        isCollapsed ? infoBlockClosed : infoBlockOpen
      }}</span>
    </div>

    <div class="c-info-block__decorator"></div>
  </div>

  @if (!isCollapsed) {
    <div class="c-info-block__content">
      <ng-content></ng-content>
    </div>
  }
</div>
