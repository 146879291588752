/**
 * Naric
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 4.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional, Signal, inject }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext  }                           from '@angular/common/http';
import { CustomHttpParameterCodec }                        from '../encoder';

import { Observable, of, startWith }                                        from 'rxjs';
import { v4 as uuidv4 }                                      from 'uuid';
import { catchError, map } from 'rxjs/operators';
import { ApiCallState, isAngularSignal } from '../model/models';
import { derivedAsync } from 'ngxtension/derived-async';
import { CodeOmschrijvingRestDto } from '../model/codeOmschrijvingRestDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export const getDocumentstatusEnumSignal = () : Signal<ApiCallState<Array<CodeOmschrijvingRestDto>>> => {
    const service = inject(DocumentstatusEnumService);
    return derivedAsync(() =>
        service.getDocumentstatusEnum().pipe(
            map((res) => ({ loading: false, result: res })),
            startWith({ loading: true, result: null }),
            catchError((err) => of({ loading: false, error: err })),
        ),
        {
            initialValue: { loading: true, result: null, error: null }
        },
    );
};


@Injectable()
export class DocumentstatusEnumService {

    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Raadpleeg de lijst van enum waardes.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */



    public getDocumentstatusEnum(observe?: 'body', reportProgress?: boolean):
Observable<Array<CodeOmschrijvingRestDto>>;
    public getDocumentstatusEnum(observe?: 'response', reportProgress?: boolean):
Observable<HttpResponse<Array<CodeOmschrijvingRestDto>>>;
    public getDocumentstatusEnum(observe?: 'events', reportProgress?: boolean):
Observable<HttpEvent<Array<CodeOmschrijvingRestDto>>>;
    public getDocumentstatusEnum(observe: any = 'body', reportProgress: boolean = false ):
Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpParameterCodec()});
        let headers = this.defaultHeaders;

        // add request Id
        if (this.configuration.transactionTracing) {
            let requestId = uuidv4();
            headers = headers.set("X-Request-ID", requestId);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;charset=UTF-8'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

    let localVarPath = `/open-api/v1/enum/documentstatussen`;
        return this.httpClient.get<Array<CodeOmschrijvingRestDto>>(`${this.configuration.basePath}${localVarPath}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
