/**
 * Naric
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 4.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { KnoopRestDto } from '././knoopRestDto';


import {ValidatorFn, AbstractControlOptions, FormGroup, FormBuilder, FormArray} from '@angular/forms';
import { SwaggerValidators } from '@ov/ngx-ov-utils';

export interface IBevragingRestDto {
	huidig_scherm_antwoorden?: Array<string>;
	huidig_scherm_code: string;
	huidig_scherm_vraag: string;
	knopen: Array<KnoopRestDto>;
	start_datum: string;
	voortgangspercentage: number;
}


export class BevragingRestDto implements IBevragingRestDto {
	huidig_scherm_antwoorden?: Array<string>;
	huidig_scherm_code: string;
	huidig_scherm_vraag: string;
	knopen: Array<KnoopRestDto>;
	start_datum: string;
	voortgangspercentage: number;
}

export class BevragingRestDtoFormHelper {

    static generateFormWithSwaggerValidators(formBuilder: FormBuilder, controlsConfig: {[key: string]: any;}, options?: AbstractControlOptions | {[key: string]: any;} | null): FormGroup{
        if(controlsConfig["huidig_scherm_antwoorden"] != undefined && !(controlsConfig["huidig_scherm_antwoorden"] instanceof FormGroup) && !(controlsConfig["huidig_scherm_antwoorden"] instanceof FormArray)){
            controlsConfig["huidig_scherm_antwoorden"][1] = controlsConfig["huidig_scherm_antwoorden"][1].concat(BevragingRestDtoValidator.huidig_scherm_antwoorden_swagger_validator());
        }
        if(controlsConfig["huidig_scherm_code"] != undefined && !(controlsConfig["huidig_scherm_code"] instanceof FormGroup) && !(controlsConfig["huidig_scherm_code"] instanceof FormArray)){
            controlsConfig["huidig_scherm_code"][1] = controlsConfig["huidig_scherm_code"][1].concat(BevragingRestDtoValidator.huidig_scherm_code_swagger_validator());
        }
        if(controlsConfig["huidig_scherm_vraag"] != undefined && !(controlsConfig["huidig_scherm_vraag"] instanceof FormGroup) && !(controlsConfig["huidig_scherm_vraag"] instanceof FormArray)){
            controlsConfig["huidig_scherm_vraag"][1] = controlsConfig["huidig_scherm_vraag"][1].concat(BevragingRestDtoValidator.huidig_scherm_vraag_swagger_validator());
        }
        if(controlsConfig["knopen"] != undefined && !(controlsConfig["knopen"] instanceof FormGroup) && !(controlsConfig["knopen"] instanceof FormArray)){
            controlsConfig["knopen"][1] = controlsConfig["knopen"][1].concat(BevragingRestDtoValidator.knopen_swagger_validator());
        }
        if(controlsConfig["start_datum"] != undefined && !(controlsConfig["start_datum"] instanceof FormGroup) && !(controlsConfig["start_datum"] instanceof FormArray)){
            controlsConfig["start_datum"][1] = controlsConfig["start_datum"][1].concat(BevragingRestDtoValidator.start_datum_swagger_validator());
        }
        if(controlsConfig["voortgangspercentage"] != undefined && !(controlsConfig["voortgangspercentage"] instanceof FormGroup) && !(controlsConfig["voortgangspercentage"] instanceof FormArray)){
            controlsConfig["voortgangspercentage"][1] = controlsConfig["voortgangspercentage"][1].concat(BevragingRestDtoValidator.voortgangspercentage_swagger_validator());
        }
        if(options){
            return formBuilder.group(controlsConfig, options);
        }
        return formBuilder.group(controlsConfig);
    }
}

export class BevragingRestDtoValidator {

        /**
        * Active Validators for huidig_scherm_antwoorden:
        */
        static huidig_scherm_antwoorden_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

        /**
        * Active Validators for huidig_scherm_code:
        * Required validator
        */
        static huidig_scherm_code_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                validators.push(SwaggerValidators.required);
                return validators;
            };

        /**
        * Active Validators for huidig_scherm_vraag:
        * Required validator
        */
        static huidig_scherm_vraag_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                validators.push(SwaggerValidators.required);
                return validators;
            };

        /**
        * Active Validators for knopen:
        * Required validator
        */
        static knopen_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                validators.push(SwaggerValidators.required);
                return validators;
            };

        /**
        * Active Validators for start_datum:
        * Required validator
        */
        static start_datum_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                validators.push(SwaggerValidators.required);
                return validators;
            };

        /**
        * Active Validators for voortgangspercentage:
        * Minimum validator: 0
        * Maximum validator: 100
        * Required validator
        * Minimum Length: 0
        * Maximum Length: 100
        */
        static voortgangspercentage_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                validators.push(SwaggerValidators.min(0));
                validators.push(SwaggerValidators.max(100));
                validators.push(SwaggerValidators.required);
                validators.push(SwaggerValidators.minLength(0));
                validators.push(SwaggerValidators.maxLength(100));
                return validators;
            };

}



