import { Component, computed, model, OnInit } from '@angular/core';
import { NgxMaskService } from 'ngx-mask';
import { AsyncPipe, CommonModule } from '@angular/common';
import { NgxOvButtonComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/default-layout-blokken/button/button.component';
import { NgxOvSelectComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/select/select.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { NgxOvPanelComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/panel/panel.component';
import { NgxOvToggleComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/toggle/toggle.component';
import {
  geefActieveLandenUsingGETSignal,
  LandService
} from '@naricbo-open-api';
import { mockEten } from './mock/mock-eten';
import { PortalPanelActionsDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/panel/portals/portal-panel-actions.directive';
import { debounceTime, Subject, throwError } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { mockVestigingsplaatsen } from './mock/mock-vestigingsplaatsen';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  compareVestigingsplaats,
  nationaliteitSearchFn,
  vestigingsplaatsSearchFn
} from './page-forms-select.utils';
import { NgxOvEnumSelectionComponent } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/enum/enum-selection.component';
import { EnumConfig } from '../../../../projects/ngx-ov-ui/src/lib/fundamentals/enum/enum.config';

export interface selectForm {
  isReadonlyForm: FormControl<boolean>;
  nationaliteit: FormControl<string | null>;
  nationaliteitWithValue: FormControl<string | null>;
  land: FormControl<string | null>;
  landWithValue: FormControl<string | null>;
  landTypeahead: FormControl<string | null>;
  landTypeaheadWithValue: FormControl<string | null>;
  eten_1: FormControl<string | null>;
  eten_1WithValue: FormControl<string | null>;
  eten_2: FormControl<string | null>;
  eten_2WithValue: FormControl<string[] | null>;
  vestigingsplaats: FormControl<any[] | null>;
  generieke_enum_value: FormControl<any[] | null>;
  generieke_enum_value_alt: FormControl<any[] | null>;
}

@Component({
  selector: 'app-page-forms-select',
  templateUrl: './page-forms-select.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AsyncPipe,
    NgxOvButtonComponent,
    NgxOvSelectComponent,
    NgxOvPanelComponent,
    NgxOvToggleComponent,
    PortalPanelActionsDirective,
    NgSelectModule,
    NgxOvEnumSelectionComponent
  ],
  providers: [NgxMaskService, LandService]
})
export class PageFormsSelectComponent implements OnInit {
  form: FormGroup<selectForm>;

  altEnumConfig: EnumConfig = {
    enumUrl: 'http://localhost:3000/api/enum/alt'
  };

  isReadonlyForm = model<boolean>(false);
  landen = geefActieveLandenUsingGETSignal();
  landenTypeahead = computed(() => {
    if (!this.landen()?.result) {
      return [];
    }
    return this.landen()?.result?.filter((land) =>
      land.landnaam
        .toLowerCase()
        .includes(this.landenTypeaheadValue().toLowerCase())
    );
  });
  landenTypeaheadValue = model<string>('');
  mockEten = mockEten;
  mockVestigingsplaatsen = mockVestigingsplaatsen;
  vestigingsplaatsSearchFn = vestigingsplaatsSearchFn;
  nationaliteitSearchFn = nationaliteitSearchFn;
  compareVestigingsplaats = compareVestigingsplaats;
  landTypeaheadSubject$ = new Subject<string>();

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.form = this.fb.group({
      isReadonlyForm: [false],
      nationaliteit: ['', Validators.required],
      nationaliteitWithValue: ['Andere', Validators.required],
      land: ['', Validators.required],
      landWithValue: ['SE', Validators.required],
      landTypeahead: ['', Validators.required],
      landTypeaheadWithValue: ['SE', Validators.required],
      eten_1: ['', Validators.required],
      vestigingsplaats: [
        [{ geografie: 'Torhout' }, { code: '10' }],
        Validators.required
      ],
      eten_1WithValue: ['SPA', Validators.required],
      eten_2: [''],
      eten_2WithValue: [['SPA', 'STO']],
      generieke_enum_value: [],
      generieke_enum_value_alt: []
    });
    this.landTypeaheadSubject$
      .pipe(
        distinctUntilChanged(),
        filter((value) => !!value),
        filter((value) => value.length > 1),
        debounceTime(500)
      )
      .subscribe({
        next: (data) => {
          this.landenTypeaheadValue.set(data);
        },
        error: (error) => {
          return throwError(() => error);
        }
      });
    this.form.controls.isReadonlyForm.valueChanges.subscribe((val) =>
      this.isReadonlyForm.set(val)
    );
  }

  onSubmit() {}
  onPatch() {}
  changeRequiredField() {}
  onSave() {}
  onReset() {}
}