import { AfterViewInit, Component } from '@angular/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {
  AccessibilityHelp,
  Autosave,
  Bold,
  ClassicEditor,
  Essentials,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  Paragraph,
  SelectAll,
  Underline,
  Undo
} from 'ckeditor5';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { NgxOvInputLabelComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/input-label/input-label.component';

@Component({
  selector: 'app-page-editor',
  templateUrl: './page-editor.component.html',
  standalone: true,
  imports: [
    CKEditorModule,
    ReactiveFormsModule,
    AsyncPipe,
    JsonPipe,
    NgxOvInputLabelComponent
  ]
})
export class PageEditorComponent implements AfterViewInit {
  public Editor1 = ClassicEditor;
  public Editor2 = ClassicEditor;
  public config = {
    toolbar: [
      'undo',
      'redo',
      '|',
      'bold',
      'italic',
      'underline',
      '|',
      'link',
      '|',
      'bulletedList',
      'numberedList',
      'outdent',
      'indent'
    ],
    plugins: [
      AccessibilityHelp,
      Autosave,
      Bold,
      Essentials,
      Indent,
      IndentBlock,
      Italic,
      Link,
      List,
      Paragraph,
      SelectAll,
      Underline,
      Undo
    ],
    link: {
      addTargetToExternalLinks: true
    }
  };

  form = new FormGroup({
    editor1: new FormControl('<p>Test data editor 1.</p>', Validators.required),
    editor2: new FormControl('<p>Test data editor 2.</p>', Validators.required)
  });

  ngAfterViewInit() {
    // this.Editor1.defaultConfig.link.addTargetToExternalLinks = true;
  }
}
