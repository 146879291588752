<div class="container u-container-width">
  <div class="my-10">
    <h1>Modals</h1>
    <div class="grid grid-cols-12 gap-medium">
      <div class="col-span-12">
        <!-- Modal: ngxOvPortalModalFooter -->
        <ngx-ov-link
          [attr.data-cy]="'link-modal-prevent-close'"
          [text]="'Ik open een preventClose modal'"
          (click)="onOpenModal('modal-link')"
        ></ngx-ov-link>
      </div>
      <div class="col-span-12">
        <!-- Model: Angular autoclose on confirm -->
        <ngx-ov-button
          [attr.data-cy]="'button-modal-small'"
          [label]="'Small'"
          (buttonClick)="onOpenModal('modal-small')"
        ></ngx-ov-button>
      </div>
      <div class="col-span-12">
        <!-- Model: Angular autoclose on confirm -->
        <ngx-ov-button
          [attr.data-cy]="'button-modal-medium'"
          [label]="'Medium'"
          (buttonClick)="onOpenModal('modal-medium')"
        ></ngx-ov-button>
      </div>
      <div class="col-span-12">
        <!-- Model: Angular autoclose on confirm -->
        <ngx-ov-button
          [attr.data-cy]="'button-modal-large'"
          [label]="'Large'"
          (buttonClick)="onOpenModal('modal-large')"
        ></ngx-ov-button>
      </div>
      <div class="col-span-12">
        <!-- Model: Angular autoclose on confirm -->
        <ngx-ov-button
          [attr.data-cy]="'button-modal-xlarge'"
          [label]="'XLarge met veel tekst'"
          (buttonClick)="onOpenModal('modal-xlarge')"
        ></ngx-ov-button>
      </div>
      <div class="col-span-12">
        <!-- Model: Angular autoclose on confirm -->
        <ngx-ov-button
          [attr.data-cy]="'button-modal-danger'"
          [label]="'Sluit bij bevestigen'"
          (buttonClick)="onOpenModal('modal-danger')"
        ></ngx-ov-button>
      </div>
      <div class="col-span-12">
        <!-- Model: Angular events -->
        <ngx-ov-button
          [attr.data-cy]="'button-modal-transactional'"
          [label]="'Bevestigen voert logica uit'"
          (buttonClick)="isTransactionalPopupOpen = true"
        ></ngx-ov-button>
      </div>
      <div class="col-span-12">
        <!-- Model: data -->
        <ngx-ov-button
          [attr.data-cy]="'button-modal-passDataDemo'"
          [label]="'Open pass data demo modal'"
          (buttonClick)="onOpenModal('passDataDemo')"
        ></ngx-ov-button>
      </div>
      <div class="col-span-12">
        <!-- Model: data -->
        <ngx-ov-button
          [attr.data-cy]="'button-modal-passDataDemo2'"
          [label]="'Open pass data demo modal 2'"
          (buttonClick)="onOpenModal('passDataDemo2')"
        ></ngx-ov-button>
      </div>
      <div class="col-span-12">
        <!-- Model: nested custom footer -->
        <ngx-ov-button
          [attr.data-cy]="'button-modal-customFooter'"
          [label]="'Nested custom footer'"
          (buttonClick)="onOpenModal('customFooter')"
        ></ngx-ov-button>
      </div>

      <div class="col-span-12">
        <!-- Model: form -->
        <ngx-ov-button
          [attr.data-cy]="'button-modal-form'"
          [label]="'Modal with form'"
          (buttonClick)="onOpenModal('modal-form')"
        ></ngx-ov-button>
      </div>
    </div>
    <h1 class="mt-large">Snackbars</h1>
    <div>
      <ngx-ov-button
        [label]="'Open no config snackbar'"
        (buttonClick)="openNoConfigSnackbarWithService()"
      />
      <br />
      <br />
      <ngx-ov-button
        [label]="'Open no config snackbar zonder status'"
        (buttonClick)="openNoConfigSnackbarWithServiceAndNoType()"
      />
      <br />
      <br />
      <ngx-ov-button
        [label]="'Open successnackbar'"
        (buttonClick)="openSuccessSnackbarWithService()"
      />
      <br />
      <br />
      <ngx-ov-button
        [label]="'Open snackbar met component'"
        (buttonClick)="openBigSnackbarWithService()"
      />
      <br />
      <br />
      <ngx-ov-button
        [label]="'Open snackbar met component warning'"
        (buttonClick)="openWarningSnackbarWithService()"
      />
    </div>
    <div class="my-10">
      <ngx-ov-button
        (buttonClick)="openSmallSnackbar()"
        label="Open small Snackbar"
      ></ngx-ov-button>
    </div>
  </div>
  <div>
    <ngx-ov-modal [id]="'modal-small'" [title]="'test small'" [size]="'small'">
      <p>Deze modal is small.</p>
    </ngx-ov-modal>

    <ngx-ov-modal
      [id]="'modal-medium'"
      [title]="'test medium'"
      [size]="'medium'"
    >
      <p>Deze modal is medium.</p>
    </ngx-ov-modal>

    <ngx-ov-modal [id]="'modal-large'" [title]="'test large'" [size]="'large'">
      <p>Deze modal is large.</p>
    </ngx-ov-modal>

    <ngx-ov-modal
      [id]="'modal-xlarge'"
      [title]="'test xlarge'"
      [size]="'xlarge'"
    >
      <p>
        Deze modal is xlarge en bevat veel content die de volledige hoogte van
        de pagina inneemt.
      </p>
      <p *ngFor="let number of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
    </ngx-ov-modal>

    <ngx-ov-modal
      [id]="'modal-link'"
      [title]="'test link'"
      [preventClose]="true"
    >
      <p>
        Deze modal is geopend door op een link te klikken. Hij kan enkel
        gesloten worden door op de 'Sluit' knop te klikken.
      </p>
      <ng-template ngxOvPortalModalFooter>
        <ngx-ov-button
          [attr.data-cy]="'button-modal-prevent-close'"
          (buttonClick)="onCloseModal('modal-link')"
          [label]="'Sluit'"
        ></ngx-ov-button>
      </ng-template>
    </ngx-ov-modal>

    <ngx-ov-modal
      [id]="'modal-transactional'"
      [isOpen]="isTransactionalPopupOpen"
      [type]="'Transactional'"
      [title]="'Meer info'"
      (closePopup)="isTransactionalPopupOpen = false"
      (cancel)="isTransactionalPopupOpen = false"
      (confirm)="onConfirmTransactionalPopUp()"
      [isSaving]="isModalSaving$ | async"
    >
      <p>Dit is een transactional modal</p>
    </ngx-ov-modal>

    <ngx-ov-modal
      [id]="'modal-danger'"
      [type]="'Danger'"
      [title]="'Meer info'"
      [confirmIcon]="'trash'"
      [confirmText]="'Verwijder'"
      [allowOverflow]="true"
      [autoCloseOnConfirm]="true"
      [imageSrc]="'./assets/images/undraw_fixing_bugs.png'"
    >
      <!-- wat is bedoeling hiervan? -->
      <div style="overflow-y: visible">
        <ngx-ov-select
          [title]="'Select'"
          [items]="getSelectItems()"
        ></ngx-ov-select>
      </div>
      <p>WatGebeurtErMetEenHeelLangWoordDatDoorMoetGaanNaarDeVolgendeLijn?</p>
    </ngx-ov-modal>

    <ngx-ov-modal [id]="'passDataDemo'" [title]="'Pass data demo'">
      <ngx-ov-generic [attr.data-cy]="'generic-data-number'"> </ngx-ov-generic>
    </ngx-ov-modal>

    <ngx-ov-modal [id]="'passDataDemo2'" [title]="'Pass data demo 2'">
      <ngx-ov-generic [attr.data-cy]="'generic-data-string'"> </ngx-ov-generic>
    </ngx-ov-modal>

    <ngx-ov-modal
      [id]="'customFooter'"
      [size]="'large'"
      [title]="'Custom footer'"
    >
      <p>De content</p>
      <ngx-ov-alert [attr.data-cy]="'alert-custom-footer'">
        <ng-template ngxOvPortalModalFooter>
          <ngx-ov-link
            [attr.data-cy]="'link-custom-footer'"
            (click)="logToConsole('nested secondary clicked')"
            [text]="'Nested secondary'"
          ></ngx-ov-link>
          <ngx-ov-button
            [attr.data-cy]="'button-custom-footer'"
            (buttonClick)="logToConsole('nested primary clicked')"
            [label]="'Nested primary'"
          ></ngx-ov-button>
        </ng-template>
      </ngx-ov-alert>
    </ngx-ov-modal>

    <ngx-ov-modal
      [allowOverflow]="true"
      [id]="'modal-form'"
      [size]="'large'"
      [title]="'Modal met form'"
      [type]="'Transactional'"
      [confirmText]="'opslaan'"
      (confirm)="showAlertFormModal()"
    >
      <div [formGroup]="form" class="grid gap-medium">
        <div>
          <ngx-ov-input-field
            [formControl]="form.controls.voornaam"
            [label]="'Voornaam'"
            [attr.data-cy]="'input-voornaam'"
          ></ngx-ov-input-field>
        </div>

        <div>
          <ngx-ov-input-field
            [formControl]="form.controls.achternaam"
            [label]="'Achternaam'"
            [attr.data-cy]="'input-achternaam'"
          ></ngx-ov-input-field>
        </div>
      </div>
    </ngx-ov-modal>
  </div>
</div>
