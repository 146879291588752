import { Pipe, PipeTransform } from '@angular/core';

import * as R from 'ramda';

import { INgxOvDataTableColumn, isRowSelected } from '../data-table.utils';

export const filterDataTable = (
  data: any[],
  filterBy: INgxOvDataTableColumn[],
  selected?: 'all' | 'selected' | 'unselected',
  value?: any,
  uniqueId?: string
): any[] => {
  if (!data || !Array.isArray(data)) {
    return [];
  }
  return data
    .filter((row) => {
      if (selected === 'all') {
        return true;
      }
      if (selected === 'selected') {
        return isRowSelected(value, row, uniqueId);
      }
      return !isRowSelected(value, row, uniqueId);
    })
    .filter((row) => {
      let found = true;
      filterBy.forEach((filter) => {
        if (!filter.filter || !filter.filter.length) {
        } else {
          const rowValue = R.path(filter.name.split('.'), row);
          if (rowValue === undefined) found = false;
          if (
            typeof rowValue === 'string' &&
            !rowValue.length &&
            filter.filter.length
          )
            found = false;
          if (
            typeof rowValue === 'string' &&
            filter.filter &&
            (<string>rowValue)
              .toLowerCase()
              .search(filter.filter.toLowerCase()) === -1
          ) {
            found = false;
          }
          if (
            typeof rowValue === 'number' &&
            filter.filter &&
            (<number>rowValue)
              .toString()
              .search(filter.filter.toLowerCase()) === -1
          ) {
            found = false;
          }
        }
      });
      return found;
    });
};

@Pipe({
  name: 'filterDataTable',
  pure: false,
  standalone: true
})
export class FilterDataTablePipe implements PipeTransform {
  transform(
    data: any[],
    filterBy: INgxOvDataTableColumn[],
    selected?: 'all' | 'selected' | 'unselected',
    value?: any,
    uniqueId?: string
  ): any[] {
    return filterDataTable(data, filterBy, selected, value, uniqueId);
  }
}
