export * from './aanvraagbegeleiding.service';
import { AanvraagbegeleidingService } from './aanvraagbegeleiding.service';
import { BeroepService } from './beroep.service';
import { DiplomaniveauService } from './diplomaniveau.service';
import { DocumentdetailsService } from './documentdetails.service';
import { DocumentsoortService } from './documentsoort.service';
import { DocumentstatusEnumService } from './documentstatusEnum.service';
import { DocumenttypecontextService } from './documenttypecontext.service';
import { EnumService } from './enum.service';
import { GeoService } from './geo.service';
import { GeslachtService } from './geslacht.service';
import { LandService } from './land.service';
import { OnderwijsniveauEnumService } from './onderwijsniveauEnum.service';
import { OpenAPIApplicatieService } from './openAPIApplicatie.service';
import { OpenRaadpleegGUINameValuePairsService } from './openRaadpleegGUINameValuePairs.service';
import { OpleidingsverstrekkerService } from './opleidingsverstrekker.service';
import { TelefoonLandprefixService } from './telefoonLandprefix.service';
import { VakgebiedService } from './vakgebied.service';
import { VrijstellingsredenService } from './vrijstellingsreden.service';

export * from './beroep.service';
export * from './diplomaniveau.service';
export * from './documentdetails.service';
export * from './documentsoort.service';
export * from './documentstatusEnum.service';
export * from './documenttypecontext.service';
export * from './geo.service';
export * from './geslacht.service';
export * from './land.service';
export * from './onderwijsniveauEnum.service';
export * from './openAPIApplicatie.service';
export * from './openRaadpleegGUINameValuePairs.service';
export * from './opleidingsverstrekker.service';
export * from './telefoonLandprefix.service';
export * from './vakgebied.service';
export * from './vrijstellingsreden.service';
export const APIS = [AanvraagbegeleidingService, BeroepService, DiplomaniveauService, DocumentdetailsService, DocumentsoortService, DocumentstatusEnumService, DocumenttypecontextService, EnumService, GeoService, GeslachtService, LandService, OnderwijsniveauEnumService, OpenAPIApplicatieService, OpenRaadpleegGUINameValuePairsService, OpleidingsverstrekkerService, TelefoonLandprefixService, VakgebiedService, VrijstellingsredenService];
