import { Pipe, PipeTransform } from '@angular/core';

import * as R from 'ramda';

import {
  INgxOvDataTableColumn,
  isRowSelected,
  NgxOvDataTableColumnFilterType
} from '../data-table.utils';
import { parse } from 'date-fns';

export const filterDataTable = (
  data: any[],
  filterBy: INgxOvDataTableColumn[],
  selected?: 'all' | 'selected' | 'unselected',
  value?: any,
  uniqueId?: string
): any[] => {
  if (!data || !Array.isArray(data)) {
    return [];
  }
  return data
    .filter((row) => {
      if (selected === 'all') {
        return true;
      }
      if (selected === 'selected') {
        return isRowSelected(value, row, uniqueId);
      }
      return !isRowSelected(value, row, uniqueId);
    })
    .filter((row) => {
      let found = true;
      filterBy.forEach((filter) => {
        if (!filter.filter || !filter.filter.length) {
        } else {
          if (filter.filterType === NgxOvDataTableColumnFilterType.daterange) {
            const rowValue = R.path(filter.name.split('.'), row);
            const dateArray = filter?.filter?.split(' t/m ');

            if (Array.isArray(dateArray) && dateArray.length === 2) {
              const parsedDate = parse(rowValue, filter.dateFormat, new Date());
              const firstDate = parse(dateArray[0], 'dd-MM-yyyy', new Date());
              const lastDate = parse(dateArray[1], 'dd-MM-yyyy', new Date());
              const res1 = parsedDate.getTime() - firstDate.getTime();
              const res2 = lastDate.getTime() - parsedDate.getTime();

              if (firstDate.getTime() - parsedDate.getTime() > 0) {
                found = false;
              }
              if (parsedDate.getTime() - lastDate.getTime() > 0) {
                found = false;
              }
            }
          } else {
            const rowValue = R.path(filter.name.split('.'), row);
            if (rowValue === undefined) found = false;
            if (
              typeof rowValue === 'string' &&
              !rowValue.length &&
              filter.filter.length
            )
              found = false;
            if (
              typeof rowValue === 'string' &&
              filter.filter &&
              (<string>rowValue)
                .toLowerCase()
                .search(filter.filter.toLowerCase()) === -1
            ) {
              found = false;
            }
            if (
              typeof rowValue === 'number' &&
              filter.filter &&
              (<number>rowValue)
                .toString()
                .search(filter.filter.toLowerCase()) === -1
            ) {
              found = false;
            }
          }
        }
      });
      return found;
    });
};

@Pipe({
  name: 'filterDataTable',
  pure: true,
  standalone: true
})
export class FilterDataTablePipe implements PipeTransform {
  transform(
    data: any[],
    filterBy: INgxOvDataTableColumn[],
    selected?: 'all' | 'selected' | 'unselected',
    value?: any,
    uniqueId?: string
  ): any[] {
    return filterDataTable(data, filterBy, selected, value, uniqueId);
  }
}
