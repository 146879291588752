import {
  Component,
  computed,
  ContentChild,
  Inject,
  input,
  model,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  output,
  Self,
  signal,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {
  FormsModule,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  NgControl,
  NgModel
} from '@angular/forms';
import * as R from 'ramda';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { FormsBase } from '../forms-base';
import { TranslationsService } from '../../../services/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { trackItemByIndex } from '../../../helper';
import { ExtendedBindValuePipe } from './pipes/getExtendedBindValue.pipe';
import { HighlightSearchPipe } from '../../../pipes/highlight.pipe';
import { NgxOvInputValidationComponent } from '../input-validation/input-validation.component';
import { NgxOvPillComponent } from '../../diverse/pill/pill.component';
import { ClickEqualsEnterDirective } from '../../../directives/click-equals-enter';
import { NgxOvLinkComponent } from '../../default-layout-blokken/link/link.component';
import { NgxOvInputLabelComponent } from '../input-label/input-label.component';
import { AsyncPipe, JsonPipe, NgTemplateOutlet } from '@angular/common';
import { IsFixedSelectItemPipe } from './pipes/is-fixed-select-item.pipe';
import { NgxOvSelectService } from './select.service';

let identifier = 0;

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-select',
  templateUrl: './select.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [ExtendedBindValuePipe, NgxOvSelectService],
  standalone: true,
  imports: [
    NgxOvInputLabelComponent,
    NgxOvLinkComponent,
    ClickEqualsEnterDirective,
    NgSelectModule,
    FormsModule,
    NgxOvPillComponent,
    NgTemplateOutlet,
    NgxOvInputValidationComponent,
    ExtendedBindValuePipe,
    HighlightSearchPipe,
    AsyncPipe,
    JsonPipe,
    IsFixedSelectItemPipe
  ]
})
export class NgxOvSelectComponent
  extends FormsBase<any[] | any>
  implements OnDestroy, OnInit, OnChanges
{
  generalTrackByFn = trackItemByIndex;

  // custom inputs
  label = input<string | undefined>();
  classes = input<string>();
  isDisabled = input<boolean>();
  isBlocked = input<boolean>();

  // Inputs from ng-select
  bindLabel = input<string>('');
  bindValue = input<string>('');
  closeOnSelect = input<boolean>(true);
  groupBy = input<string>();
  selectableGroup = input<boolean>(false);
  selectableGroupAsModel = input<boolean>(true);
  isLoading = model<boolean>(false);
  multiple = input<boolean>(false);
  showCheckbox = input<boolean>(false);
  name = input<string>();
  placeholder = input<string>();
  searchable = input<boolean>(false);
  clearable = input<boolean>(true);
  isReadonly = input<boolean>(false);
  searchFn = input<any>(undefined);
  compareFn = input<(item: any, selected: any) => boolean>();
  notFoundText = input<string>();
  highlightSearch = input<boolean>(false);
  fixedItemsValues = input<any | any[]>();
  fixedItemsBindValue = input<string>();
  items = input<any[]>([]);
  closeText = input<string>();
  typeToSearchText = input<string>();
  typeahead = input<Subject<string>>();
  trackSelectItemsByFn = input<any>();
  virtualScroll = input<boolean>(false);
  appendToSelector = input<string>();
  emptyOnClose = input<boolean>(false);

  isOpen = signal(false);

  computedFixedItemValues = computed(() => {
    return Array.isArray(this.fixedItemsValues)
      ? this.fixedItemsValues()
      : [this.fixedItemsValues()];
  });

  computedFixedOptionSearchFn = computed(() => {
    return (term, item) => {
      return false;
    };
  });

  blur = output<any>();
  change = output<any>();
  close = output<any>();
  selectOption = output<any>();
  clear = output<any>();
  search = output<any>();

  @ViewChild(NgModel, { static: true }) model: NgModel;
  @ViewChild('spy', { static: false }) select: NgModel;
  @ViewChild('ngSelect') ngSelect: NgSelectComponent;
  @ViewChild('outlet', { read: ViewContainerRef, static: true })
  outletRef: ViewContainerRef;
  @ViewChild('content', { read: TemplateRef, static: true })
  contentRef: TemplateRef<any>;

  @ContentChild('ngxOvOptionTemplate') optionTemplate: TemplateRef<any>;
  @ContentChild('ngxOvLabelTemplate') labelTemplate: TemplateRef<any>;

  public identifier = `form-select-${(identifier += 1)}`;

  // -------------------------------------------------------------------------
  // Copy variables
  // -------------------------------------------------------------------------
  public notFoundTextCopy;
  public closeTextCopy;
  public typeToSearchTextCopy;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    @Self() @Optional() ngControl: NgControl,
    translationsService: TranslationsService,
    @Optional() translateService: TranslateService
  ) {
    super(
      validators,
      asyncValidators,
      ngControl,
      translateService,
      translationsService
    );
  }

  // -------------------------------------------------------------------------
  // Lifecycle methods
  // -------------------------------------------------------------------------
  ngOnInit(): void {
    this.getCopy();
    if (this.translateService) {
      this.translateService.onLangChange
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.getCopy();
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    super.init();
  }

  clearItem(fn: any, item: any) {
    if (!this.isDisabled()) {
      fn(item);
    }
  }

  isSelectPristine(): boolean {
    return this.model ? this.model.pristine : true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onBlur(value: string): void {
    this.blur.emit(value);
    this.isOpen.set(false);
  }

  onClose(e: any): void {
    this.close.emit(e);
    this.isOpen.set(false);
  }

  onSelect(e: any): void {
    this.selectOption.emit(e);
  }

  onClear(): void {
    this.clear.emit(null);
  }

  onSearch(e: any): void {
    this.search.emit(e);
  }

  onChange(): void {
    this.change.emit(this.value);
    if (this.closeOnSelect()) {
      this.closeSelect();
    }
  }

  /**
   * Default search function used if no custom search function is provided.
   * Fixed items will always match the search.
   * Non-fixed items text value will be matched by term text.
   * Note: this function should cannot call another function!
   * @param term: term to be searched
   * @param item: item from select
   */
  fixedOptionSearchFn(term: string, item: any): boolean {
    //const ngxOvSelectService: NgxOvSelectService = inject(NgxOvSelectService);

    if (item) {
      // Always show fixed items
      return true;
    } else {
      // Search text of item value
      let compareValue = item ? (item ? item[item.bindLabel] : item) : item;
      if (
        !(typeof compareValue === 'string' || compareValue instanceof String)
      ) {
        compareValue = String(compareValue);
      }
      return compareValue.toLocaleLowerCase().indexOf(term.toLowerCase()) > -1;
    }

    return true;
  }

  openSelect() {
    this.isOpen.set(true);
  }

  closeSelect() {
    this.isOpen.set(false);
    this.touch();
  }

  getItemByValue(value: any) {
    if (this.items() && Array.isArray(this.items())) {
      if (this.bindValue()) {
        const props = this.bindValue().split('.');
        const getValue = R.path(props);
        return this.items().find((item) => {
          return value === getValue(item);
        });
      }
      return this.items().find((item) => {
        return value === item;
      });
    }
    return null;
  }

  getDataCyCode(item: any) {
    if (item) {
      return item?.code ? item?.code : item?.value;
    }
    return null;
  }

  // -------------------------------------------------------------------------
  // Translation
  // -------------------------------------------------------------------------
  getCopy() {
    this.notFoundTextCopy =
      this.notFoundText() ?? this.getNgxOvUiTranslation('NOT_FOUND_TEXT');
    this.closeTextCopy =
      this.closeText() ?? this.getNgxOvUiTranslation('CLOSE_TEXT');
    this.typeToSearchTextCopy =
      this.typeToSearchText() ??
      this.getNgxOvUiTranslation('TYPE_TO_SEARCH_TEXT');
  }

  getNgxOvUiTranslation(code: string) {
    const translationKey = `FORMS.SELECT.${code.toUpperCase()}`;
    return this.translationsService.getDefaultComponentTranslation(
      translationKey
    );
  }
}
