/**
 * Naric
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 4.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */



import {ValidatorFn, AbstractControlOptions, FormGroup, FormBuilder, FormArray} from '@angular/forms';
import { SwaggerValidators } from '@ov/ngx-ov-utils';

export interface IGeoPostcodeRestDto {
	deel_gemeente_naam?: string;
	postcode?: string;
}


export class GeoPostcodeRestDto implements IGeoPostcodeRestDto {
	deel_gemeente_naam?: string;
	postcode?: string;
}

export class GeoPostcodeRestDtoFormHelper {

    static generateFormWithSwaggerValidators(formBuilder: FormBuilder, controlsConfig: {[key: string]: any;}, options?: AbstractControlOptions | {[key: string]: any;} | null): FormGroup{
        if(controlsConfig["deel_gemeente_naam"] != undefined && !(controlsConfig["deel_gemeente_naam"] instanceof FormGroup) && !(controlsConfig["deel_gemeente_naam"] instanceof FormArray)){
            controlsConfig["deel_gemeente_naam"][1] = controlsConfig["deel_gemeente_naam"][1].concat(GeoPostcodeRestDtoValidator.deel_gemeente_naam_swagger_validator());
        }
        if(controlsConfig["postcode"] != undefined && !(controlsConfig["postcode"] instanceof FormGroup) && !(controlsConfig["postcode"] instanceof FormArray)){
            controlsConfig["postcode"][1] = controlsConfig["postcode"][1].concat(GeoPostcodeRestDtoValidator.postcode_swagger_validator());
        }
        if(options){
            return formBuilder.group(controlsConfig, options);
        }
        return formBuilder.group(controlsConfig);
    }
}

export class GeoPostcodeRestDtoValidator {

        /**
        * Active Validators for deel_gemeente_naam:
        */
        static deel_gemeente_naam_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

        /**
        * Active Validators for postcode:
        */
        static postcode_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

}



