/**
 * Naric
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 4.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */



import {ValidatorFn, AbstractControlOptions, FormGroup, FormBuilder, FormArray} from '@angular/forms';
import { SwaggerValidators } from '@ov/ngx-ov-utils';

export interface IKnoopRestDto {
	antwoorden: Array<string>;
	scherm: string;
	volgnummer: number;
	voortgangspercentage: number;
	vraag: string;
}


export class KnoopRestDto implements IKnoopRestDto {
	antwoorden: Array<string>;
	scherm: string;
	volgnummer: number;
	voortgangspercentage: number;
	vraag: string;
}

export class KnoopRestDtoFormHelper {

    static generateFormWithSwaggerValidators(formBuilder: FormBuilder, controlsConfig: {[key: string]: any;}, options?: AbstractControlOptions | {[key: string]: any;} | null): FormGroup{
        if(controlsConfig["antwoorden"] != undefined && !(controlsConfig["antwoorden"] instanceof FormGroup) && !(controlsConfig["antwoorden"] instanceof FormArray)){
            controlsConfig["antwoorden"][1] = controlsConfig["antwoorden"][1].concat(KnoopRestDtoValidator.antwoorden_swagger_validator());
        }
        if(controlsConfig["scherm"] != undefined && !(controlsConfig["scherm"] instanceof FormGroup) && !(controlsConfig["scherm"] instanceof FormArray)){
            controlsConfig["scherm"][1] = controlsConfig["scherm"][1].concat(KnoopRestDtoValidator.scherm_swagger_validator());
        }
        if(controlsConfig["volgnummer"] != undefined && !(controlsConfig["volgnummer"] instanceof FormGroup) && !(controlsConfig["volgnummer"] instanceof FormArray)){
            controlsConfig["volgnummer"][1] = controlsConfig["volgnummer"][1].concat(KnoopRestDtoValidator.volgnummer_swagger_validator());
        }
        if(controlsConfig["voortgangspercentage"] != undefined && !(controlsConfig["voortgangspercentage"] instanceof FormGroup) && !(controlsConfig["voortgangspercentage"] instanceof FormArray)){
            controlsConfig["voortgangspercentage"][1] = controlsConfig["voortgangspercentage"][1].concat(KnoopRestDtoValidator.voortgangspercentage_swagger_validator());
        }
        if(controlsConfig["vraag"] != undefined && !(controlsConfig["vraag"] instanceof FormGroup) && !(controlsConfig["vraag"] instanceof FormArray)){
            controlsConfig["vraag"][1] = controlsConfig["vraag"][1].concat(KnoopRestDtoValidator.vraag_swagger_validator());
        }
        if(options){
            return formBuilder.group(controlsConfig, options);
        }
        return formBuilder.group(controlsConfig);
    }
}

export class KnoopRestDtoValidator {

        /**
        * Active Validators for antwoorden:
        * Required validator
        */
        static antwoorden_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                validators.push(SwaggerValidators.required);
                return validators;
            };

        /**
        * Active Validators for scherm:
        * Required validator
        */
        static scherm_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                validators.push(SwaggerValidators.required);
                return validators;
            };

        /**
        * Active Validators for volgnummer:
        * Required validator
        */
        static volgnummer_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                validators.push(SwaggerValidators.required);
                return validators;
            };

        /**
        * Active Validators for voortgangspercentage:
        * Minimum validator: 0
        * Maximum validator: 100
        * Required validator
        * Minimum Length: 0
        * Maximum Length: 100
        */
        static voortgangspercentage_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                validators.push(SwaggerValidators.min(0));
                validators.push(SwaggerValidators.max(100));
                validators.push(SwaggerValidators.required);
                validators.push(SwaggerValidators.minLength(0));
                validators.push(SwaggerValidators.maxLength(100));
                return validators;
            };

        /**
        * Active Validators for vraag:
        * Required validator
        */
        static vraag_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                validators.push(SwaggerValidators.required);
                return validators;
            };

}



