import { computed, Injectable, Signal, signal } from '@angular/core';
import { INgxOvDataTableColumn } from '../data-table.utils';
import { DataTableSettings } from './data-table-settings.model';

@Injectable({ providedIn: 'root' })
export class NgxOvDataTableSettingsService {
  dataTableSettings = signal<DataTableSettings>({});

  init(
    datatableId: string,
    columns: INgxOvDataTableColumn[],
    allowChangeVisibleSettings?: boolean,
    allowSettingFilterable?: boolean
  ) {
    const localstorageDataTableColumns = localStorage.getItem(
      this.getLocalstorageKey(datatableId)
    );
    if (localstorageDataTableColumns !== null) {
      this.dataTableSettings.set({
        ...this.dataTableSettings(),
        [datatableId]: {
          defaultColumns: columns,
          allowChangeVisibleSettings: signal(allowChangeVisibleSettings),
          allowChangeFilterableSettings: signal(allowSettingFilterable),
          overrideColumns: signal(JSON.parse(localstorageDataTableColumns))
        }
      });
    } else {
      this.dataTableSettings.set({
        ...this.dataTableSettings(),
        [datatableId]: {
          defaultColumns: columns,
          allowChangeVisibleSettings: signal(allowChangeVisibleSettings),
          allowChangeFilterableSettings: signal(allowSettingFilterable),
          overrideColumns: signal(columns)
        }
      });
    }
  }

  getColumns(key: string): Signal<INgxOvDataTableColumn[]> {
    return computed(() => {
      if (
        this.dataTableSettings() &&
        this.dataTableSettings()[key] &&
        this.dataTableSettings()[key]?.overrideColumns
      ) {
        try {
          return this.dataTableSettings()[key]?.defaultColumns.map((column) => {
            const override = this.dataTableSettings()
              [key]?.overrideColumns()
              .find((col) => col.name === column.name);
            return {
              ...column,
              visible: override.visible,
              filterable:
                column.filterable !== false ? override.filterable : false
            };
          });
        } catch (e) {
          return [];
        }
      }
      return [];
    });
  }

  saveToLocalStorage(datatableId: string, settings: any) {
    localStorage.setItem(
      this.getLocalstorageKey(datatableId),
      JSON.stringify(settings)
    );
  }

  getLocalstorageKey(datatableId: string) {
    return 'ngx-ov-datatableColumns-' + datatableId;
  }

  setDataTableSettings(key: string, dataTableColumns: INgxOvDataTableColumn[]) {
    this.dataTableSettings()[key].overrideColumns.set(dataTableColumns);
    this.saveToLocalStorage(key, dataTableColumns);
  }
}
