<div class="c-textarea {{ classes }}">
  <ngx-ov-input-label
    [text]="label"
    [required]="isRequired"
    [isReadonly]="isReadonly"
  ></ngx-ov-input-label>
  @if (!isReadonly) {
    <textarea
      class="c-textarea__input {{ classes }}"
      [class.is-error]="ngControl?.touched && !ngControl?.valid"
      [class.is-disabled]="isDisabled"
      [autocomplete]="autocomplete"
      id="{{ id }}"
      [name]="name"
      [(ngModel)]="value"
      #spy="ngModel"
      cols="40"
      rows="4"
      [placeholder]="placeholder"
      [attr.maxlength]="maxChar"
      (blur)="onBlur(value)"
    ></textarea>

    <div class="c-textarea__info pt-xsmall">
      <div style="width: 60%">
        @if (errors$ | async; as errors) {
          <div class="mt-xsmall">
            @if (ngControl?.dirty && ngControl?.status === 'INVALID') {
              <ngx-ov-input-validation
                [messages]="errors"
              ></ngx-ov-input-validation>
            }
          </div>
        }
      </div>
      <div style="width: 40%">
        @if (maxChar) {
          <div class="c-textarea__counter">
            ({{ value?.length || 0 }}/{{ maxChar }})
          </div>
        }
      </div>
    </div>
  } @else {
    <div
      class="u-form-readonly__input-readonly"
      [innerHTML]="value ? value : '-'"
    ></div>
  }
</div>
