<div
  class="{{ secondary ? 'c-flyout--secondary' : '' }}"
  [class.c-flyout]="!tertiary"
  (click)="flyoutService.toggle()"
>
  <div
    class="c-flyout__trigger__container"
    [attr.aria-expanded]="flyoutService._open"
  >
    <div
      class="c-flyout__trigger"
      [class.c-flyout__trigger_layout]="!tertiary"
      [class.c-flyout__trigger_default]="!portalTrigger && secondary"
    >
      @if (portalTrigger) {
        <ng-template [cdkPortalOutlet]="portalTrigger"></ng-template>
      } @else {
        @if (trigger && !secondary) {
          <p [innerHTML]="trigger"></p>
        }
        @if (secondary && triggerLabel) {
          <p [innerHTML]="triggerLabel"></p>
        }
        @if (!trigger && !secondary) {
          <i class="fas fa-bars" aria-hidden="true"></i>
        }
      }

      @if (flyoutService._open) {
        <div class="c-flyout__overlay" (click)="flyoutService.close()"></div>
        <div
          class="c-flyout__content"
          [class.c-flyout__content--mobile-fullscreen]="openMobileFullscreen"
          [class.c-flyout__content--right]="position.toUpperCase() === 'RIGHT'"
          [class.c-flyout--lite]="!header"
          [class.c-flyout--header-hidden-desktop]="headerDesktopHidden"
          [class.c-flyout__content--dark]="type !== 'light'"
          [style.min-width]="minWidth ? minWidth : ''"
        >
          <div class="c-flyout__header">
            <div class="c-flyout__header-title" [innerHTML]="header"></div>
            <div class="c-flyout__header-close">
              <i class="fas fa-times cursor-pointer" aria-hidden="true"></i>
            </div>
          </div>
          <ng-content></ng-content>
          <div class="c-flyout__bottom-navigation">
            @if (bottomNavigation) {
              <ng-template [cdkPortalOutlet]="bottomNavigation"></ng-template>
            }
          </div>
        </div>
      }
    </div>
    @if (triggerLabel && !secondary) {
      <div class="c-flyout__menu">
        <div class="c-flyout__trigger__label">
          <div [innerHTML]="triggerLabel"></div>
        </div>
        <i
          class="fas fa-chevron-{{
            flyoutService._open ? 'up' : 'down'
          }} cursor-pointer"
          aria-hidden="true"
        ></i>
      </div>
    }
    @if (triggerLabelMobile) {
      <div class="c-flyout__trigger__label-mobile">
        <div [innerHTML]="triggerLabelMobile"></div>
      </div>
    }
  </div>
</div>
