/**
 * Naric
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 4.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */



import {ValidatorFn, AbstractControlOptions, FormGroup, FormBuilder, FormArray} from '@angular/forms';
import { SwaggerValidators } from '@ov/ngx-ov-utils';

export interface IGeslachtOutputRestDto {
	afkorting?: string;
	code?: string;
	omschrijving?: string;
}


export class GeslachtOutputRestDto implements IGeslachtOutputRestDto {
	afkorting?: string;
	code?: string;
	omschrijving?: string;
}

export class GeslachtOutputRestDtoFormHelper {

    static generateFormWithSwaggerValidators(formBuilder: FormBuilder, controlsConfig: {[key: string]: any;}, options?: AbstractControlOptions | {[key: string]: any;} | null): FormGroup{
        if(controlsConfig["afkorting"] != undefined && !(controlsConfig["afkorting"] instanceof FormGroup) && !(controlsConfig["afkorting"] instanceof FormArray)){
            controlsConfig["afkorting"][1] = controlsConfig["afkorting"][1].concat(GeslachtOutputRestDtoValidator.afkorting_swagger_validator());
        }
        if(controlsConfig["code"] != undefined && !(controlsConfig["code"] instanceof FormGroup) && !(controlsConfig["code"] instanceof FormArray)){
            controlsConfig["code"][1] = controlsConfig["code"][1].concat(GeslachtOutputRestDtoValidator.code_swagger_validator());
        }
        if(controlsConfig["omschrijving"] != undefined && !(controlsConfig["omschrijving"] instanceof FormGroup) && !(controlsConfig["omschrijving"] instanceof FormArray)){
            controlsConfig["omschrijving"][1] = controlsConfig["omschrijving"][1].concat(GeslachtOutputRestDtoValidator.omschrijving_swagger_validator());
        }
        if(options){
            return formBuilder.group(controlsConfig, options);
        }
        return formBuilder.group(controlsConfig);
    }
}

export class GeslachtOutputRestDtoValidator {

        /**
        * Active Validators for afkorting:
        */
        static afkorting_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

        /**
        * Active Validators for code:
        */
        static code_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

        /**
        * Active Validators for omschrijving:
        */
        static omschrijving_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

}



