<div class="container">
  <h1>CKEditor 5 voorbeeld</h1>

  Documentatie:
  <a
    href="https://ckeditor.com/docs/ckeditor5/latest/getting-started/installation/angular.html"
    target="_blank"
    rel="noopener"
    >https://ckeditor.com/docs/ckeditor5/latest/getting-started/installation/angular.html</a
  ><br />
  Builder:
  <a
    href="https://ckeditor.com/ckeditor-5/builder/"
    rel="noopener"
    target="_blank"
    >https://ckeditor.com/ckeditor-5/builder/</a
  ><br />

  <form [formGroup]="form">
    <div class="grid grid-cols-1 gap-medium">
      <div>
        <ngx-ov-input-label [text]="'Editor 1'"></ngx-ov-input-label>
        <ckeditor
          [editor]="Editor1"
          [config]="config"
          formControlName="editor1"
        ></ckeditor>
      </div>
      <div>
        <ngx-ov-input-label [text]="'Editor 2'"></ngx-ov-input-label>
        <ckeditor
          [editor]="Editor2"
          [config]="config"
          formControlName="editor2"
        ></ckeditor>
      </div>
      <div>
        {{ form.getRawValue() | json }}
      </div>
      <div [innerHTML]="form.controls.editor1.getRawValue()"></div>
      <div [innerHTML]="form.controls.editor2.getRawValue()"></div>
    </div>
  </form>
</div>
