/**
 * Naric
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 4.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */



import {ValidatorFn, AbstractControlOptions, FormGroup, FormBuilder, FormArray} from '@angular/forms';
import { SwaggerValidators } from '@ov/ngx-ov-utils';

export interface IBeroepOutputRestDto {
	actief?: boolean;
	code?: string;
	naam?: string;
	sector_code?: BeroepOutputRestDto.SectorCodeEnum;
}


export class BeroepOutputRestDto implements IBeroepOutputRestDto {
	actief?: boolean;
	code?: string;
	naam?: string;
	sector_code?: BeroepOutputRestDto.SectorCodeEnum;
}

export class BeroepOutputRestDtoFormHelper {

    static generateFormWithSwaggerValidators(formBuilder: FormBuilder, controlsConfig: {[key: string]: any;}, options?: AbstractControlOptions | {[key: string]: any;} | null): FormGroup{
        if(controlsConfig["actief"] != undefined && !(controlsConfig["actief"] instanceof FormGroup) && !(controlsConfig["actief"] instanceof FormArray)){
            controlsConfig["actief"][1] = controlsConfig["actief"][1].concat(BeroepOutputRestDtoValidator.actief_swagger_validator());
        }
        if(controlsConfig["code"] != undefined && !(controlsConfig["code"] instanceof FormGroup) && !(controlsConfig["code"] instanceof FormArray)){
            controlsConfig["code"][1] = controlsConfig["code"][1].concat(BeroepOutputRestDtoValidator.code_swagger_validator());
        }
        if(controlsConfig["naam"] != undefined && !(controlsConfig["naam"] instanceof FormGroup) && !(controlsConfig["naam"] instanceof FormArray)){
            controlsConfig["naam"][1] = controlsConfig["naam"][1].concat(BeroepOutputRestDtoValidator.naam_swagger_validator());
        }
        if(controlsConfig["sector_code"] != undefined && !(controlsConfig["sector_code"] instanceof FormGroup) && !(controlsConfig["sector_code"] instanceof FormArray)){
            controlsConfig["sector_code"][1] = controlsConfig["sector_code"][1].concat(BeroepOutputRestDtoValidator.sector_code_swagger_validator());
        }
        if(options){
            return formBuilder.group(controlsConfig, options);
        }
        return formBuilder.group(controlsConfig);
    }
}

export class BeroepOutputRestDtoValidator {

        /**
        * Active Validators for actief:
        */
        static actief_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

        /**
        * Active Validators for code:
        */
        static code_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

        /**
        * Active Validators for naam:
        */
        static naam_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

        /**
        * Active Validators for sector_code:
        */
        static sector_code_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

}


	export namespace BeroepOutputRestDto {
			export type SectorCodeEnum = 'SECT001' | 'SECT002' | 'SECT003' | 'SECT004' | 'SECT005' | 'SECT006' | 'SECT007' | 'SECT008' | 'SECT009' | 'SECT010' | 'SECT011';
			export const SectorCodeEnum = {
					Sect001: 'SECT001' as SectorCodeEnum,
					Sect002: 'SECT002' as SectorCodeEnum,
					Sect003: 'SECT003' as SectorCodeEnum,
					Sect004: 'SECT004' as SectorCodeEnum,
					Sect005: 'SECT005' as SectorCodeEnum,
					Sect006: 'SECT006' as SectorCodeEnum,
					Sect007: 'SECT007' as SectorCodeEnum,
					Sect008: 'SECT008' as SectorCodeEnum,
					Sect009: 'SECT009' as SectorCodeEnum,
					Sect010: 'SECT010' as SectorCodeEnum,
					Sect011: 'SECT011' as SectorCodeEnum
				}
		}

