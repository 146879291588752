<div class="container u-form-show-optional p-small">
  <div>
    <div>
      <h2>Select component</h2>
    </div>
  </div>

  <div>
    <form [formGroup]="form" novalidate (submit)="onSubmit()">
      <div [ngClass]="{ 'u-form-readonly': isReadonlyForm() }">
        <ngx-ov-panel
          [title]="
            'Selects met async data ZONDER reeds een form value te hebben.'
          "
        >
          <ng-template ngxOvPanelActions>
            <div style="display: flex">
              <span>Readonly: &nbsp;</span>
              <ngx-ov-toggle
                [formControlName]="'isReadonlyForm'"
                [attr.data-cy]="'toggle-to-readonly'"
              ></ngx-ov-toggle>
            </div>
          </ng-template>
          <div class="grid grid-cols-12 gap-medium">
            <div class="col-span-12">
              <ngx-ov-select
                [attr.data-cy]="'select-nationaliteit'"
                (ngModelChange)="changeRequiredField()"
                [formControl]="form.controls.nationaliteit"
                [label]="'Welke nationaliteit heeft u?'"
                [items]="['België', 'Nederland', 'Andere']"
                [placeholder]="'--- kies land ---'"
                [searchable]="true"
                [fixedItemsValues]="['Andere']"
                [searchFn]="nationaliteitSearchFn"
                [isReadonly]="isReadonlyForm()"
              >
                <ng-template #ngxOvOptionTemplate let-item>
                  <label [attr.data-cy]="item">
                    {{ item }}
                  </label>
                </ng-template>
              </ngx-ov-select>
            </div>

            <div class="col-span-12">
              <ngx-ov-select
                [attr.data-cy]="'select-land'"
                (ngModelChange)="changeRequiredField()"
                [formControl]="form.controls.land"
                [label]="'In welk land woont u?'"
                [items]="landen()?.result"
                [bindLabel]="'landnaam'"
                [bindValue]="'landcode'"
                [placeholder]="'--- kies land ---'"
                [searchable]="true"
                [fixedItemsValues]="['AL', 'BE']"
                [fixedItemsBindValue]="'landcode'"
                [isReadonly]="isReadonlyForm()"
                [isLoading]="landen()?.loading"
              >
              </ngx-ov-select>
            </div>

            <div class="col-span-12">
              <ngx-ov-select
                [attr.data-cy]="'select-land-typeahead'"
                (ngModelChange)="changeRequiredField()"
                [formControl]="form.controls.landTypeahead"
                [label]="'In welk land woont u (type ahead)?'"
                [items]="landen().result"
                [bindLabel]="'landnaam'"
                [bindValue]="'landcode'"
                [placeholder]="'--- kies land ---'"
                [searchable]="true"
                [fixedItemsValues]="'Andere'"
                [isReadonly]="isReadonlyForm()"
                [emptyOnClose]="true"
              >
              </ngx-ov-select>
            </div>

            <div class="col-span-12">
              <ngx-ov-select
                [attr.data-cy]="'select-eten-single-select'"
                [formControlName]="'eten_1'"
                [label]="'Wat eet u graag? (1 keuze)'"
                [items]="mockEten | async"
                [bindLabel]="'name'"
                [bindValue]="'code'"
                [multiple]="false"
                [searchable]="true"
                [isReadonly]="isReadonlyForm()"
              >
                <ng-template #ngxOvOptionTemplate let-item>
                  <label [attr.data-cy]="item.code">
                    <strong>{{ item.name }}</strong>
                  </label>
                </ng-template>
              </ngx-ov-select>
            </div>

            <div class="col-span-12 appendToContainer" style="display: inline">
              <ngx-ov-select
                [attr.data-cy]="'select-eten-multi-select'"
                [formControlName]="'eten_2'"
                [label]="'Wat eet u graag? (meerdere keuzes)'"
                [items]="mockEten | async"
                [bindLabel]="'name'"
                [multiple]="true"
                [showCheckbox]="true"
                [closeOnSelect]="false"
                [isReadonly]="isReadonlyForm()"
                [appendToSelector]=""
              >
                <ng-template #ngxOvOptionTemplate let-item>
                  <label [attr.data-cy]="item.code ? item.code : item.name">
                    <strong>{{ item.type }}</strong
                    >: {{ item.name }}
                  </label>
                </ng-template>
                <ng-template #ngxOvLabelTemplate let-item
                  >{{ item.name }}&nbsp;({{ item.type }})
                </ng-template>
              </ngx-ov-select>
            </div>
            <div class="col-span-12"></div>
          </div>
        </ngx-ov-panel>

        <ngx-ov-panel
          [title]="'Selects met async data MET reeds een form value.'"
        >
          <ng-template ngxOvPanelActions>
            <div style="display: flex">
              <span>Readonly: &nbsp;</span>
              <ngx-ov-toggle
                [formControlName]="'isReadonlyForm'"
                [attr.data-cy]="'toggle-to-readonly-with-default-value'"
              ></ngx-ov-toggle>
            </div>
          </ng-template>

          <!-- Verberg required markers met helper class: u-form-hide-required -->

          <div class="grid grid-cols-12 gap-medium u-form-hide-required">
            <div class="col-span-12">
              <ngx-ov-select
                [attr.data-cy]="'select-nationaliteit-with-default-value'"
                (ngModelChange)="changeRequiredField()"
                [formControlName]="'nationaliteitWithValue'"
                [label]="'Welke nationaliteit heeft u?'"
                [items]="['België', 'Nederland', 'Andere']"
                [placeholder]="'--- kies land ---'"
                [searchable]="true"
                [fixedItemsValues]="['Andere']"
                [searchFn]="nationaliteitSearchFn"
                [isReadonly]="isReadonlyForm()"
              >
              </ngx-ov-select>
            </div>

            <div class="col-span-12">
              <ngx-ov-select
                [attr.data-cy]="'select-vestigingsplaats'"
                [formControlName]="'vestigingsplaats'"
                [label]="'Vestigingsplaats'"
                [items]="mockVestigingsplaatsen"
                [bindLabel]="'instellings_naam'"
                [multiple]="true"
                [showCheckbox]="true"
                [closeOnSelect]="false"
                [searchable]="true"
                [searchFn]="vestigingsplaatsSearchFn"
                [groupBy]="'geografie'"
                [compareFn]="compareVestigingsplaats"
                [selectableGroup]="true"
                [selectableGroupAsModel]="true"
              >
                <ng-template #ngxOvOptionTemplate let-item>
                  <label [attr.data-cy]="item.instellings_naam">
                    {{ item.instellings_naam }}&nbsp;<span
                      class="text-grayscales-gray4"
                      >({{ item.geografie }})</span
                    >
                  </label>
                </ng-template>
              </ngx-ov-select>
            </div>

            <div class="col-span-12">
              <ngx-ov-select
                [attr.data-cy]="'select-nationaliteit-with-default-value'"
                (ngModelChange)="changeRequiredField()"
                [formControlName]="'landWithValue'"
                [label]="'In welk land woont u?'"
                [items]="landen()?.result"
                [bindLabel]="'landnaam'"
                [bindValue]="'landcode'"
                [placeholder]="'--- kies land ---'"
                [searchable]="true"
                [fixedItemsValues]="'Andere'"
                [isReadonly]="isReadonlyForm()"
                [isLoading]="landen()?.loading"
              >
              </ngx-ov-select>
            </div>

            <div class="col-span-12">
              <ngx-ov-select
                [attr.data-cy]="'select-land-typeahead-with-default-value'"
                (ngModelChange)="changeRequiredField()"
                [formControlName]="'landTypeaheadWithValue'"
                [label]="'In welk land woont u (type ahead)?'"
                [items]="landenTypeahead()"
                [bindLabel]="'landnaam'"
                [bindValue]="'landcode'"
                [placeholder]="'--- kies land ---'"
                [searchable]="true"
                [fixedItemsValues]="'Andere'"
                [typeahead]="landTypeaheadSubject$"
                [isReadonly]="isReadonlyForm()"
                [emptyOnClose]="true"
              >
              </ngx-ov-select>
            </div>

            <div class="col-span-12">
              <ngx-ov-select
                [attr.data-cy]="'select-eten-single-select-with-default-value'"
                [formControlName]="'eten_1WithValue'"
                [label]="'Wat eet u graag? (1 keuze)'"
                [items]="mockEten | async"
                [bindLabel]="'name'"
                [bindValue]="'code'"
                [multiple]="false"
                [isLoading]="!(mockEten | async)"
                [isReadonly]="isReadonlyForm()"
              ></ngx-ov-select>
            </div>

            <div class="col-span-12 appendToContainer" style="display: inline">
              <ngx-ov-select
                [attr.data-cy]="'select-eten-multi-select-with-default-value'"
                [formControlName]="'eten_2WithValue'"
                [label]="'Wat eet u graag? (meerdere keuzes)'"
                [items]="mockEten | async"
                [bindLabel]="'name'"
                [bindValue]="'code'"
                [multiple]="true"
                [showCheckbox]="true"
                [closeOnSelect]="false"
                [isReadonly]="isReadonlyForm()"
                [isLoading]="!(mockEten | async)"
                [appendToSelector]=""
              >
                <ng-template #ngxOvOptionTemplate let-item>
                  <label [attr.data-cy]="item.code ? item.code : item.name">
                    <strong>{{ item.type }}</strong
                    >: {{ item.name }}
                  </label>
                </ng-template>
                <ng-template #ngxOvLabelTemplate let-item>
                  {{ item.name }}&nbsp;({{ item.type }})
                </ng-template>
              </ngx-ov-select>
            </div>
            <div class="col-span-12"></div>
          </div>
        </ngx-ov-panel>
        <div class="my-10 gap-medium u-position-btn-prim-sec">
          <!-- Krijgen geen outline omdat ze niet voorzien zijn van een (click)-->
          <ngx-ov-button
            [isTertiary]="true"
            [label]="'Patch'"
            (buttonClick)="onPatch()"
          ></ngx-ov-button>
          <ngx-ov-button
            [isTertiary]="true"
            [label]="'Reset'"
            (buttonClick)="onReset()"
          ></ngx-ov-button>
          <ngx-ov-button
            [isSecondary]="true"
            [label]="'annuleren'"
          ></ngx-ov-button>

          {{ form.errors | json }}
          <ngx-ov-button
            [attr.data-cy]="'button-submit'"
            [label]="'Submit'"
            [isDisabled]="form.valid"
            (buttonClick)="onSave()"
          ></ngx-ov-button>
        </div>
      </div>
    </form>
  </div>
  valid ? {{ form.valid }}
  {{ form.getRawValue() | json }}
</div>
