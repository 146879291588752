// =============================================================================
// Component
// =============================================================================
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { CodeOmschrijvingRestDto } from './enum-selection.model';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { NgxOvSelectComponent } from '../../components/forms/select/select.component';
import { NgxOvInputLabelComponent } from '../../components/forms/input-label/input-label.component';
import { EnumSelectionService } from './enum-selection.service';
import { EnumConfig } from './enum.config';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ngx-ov-enum-selection',
  templateUrl: './enum-selection.component.html',
  standalone: true,
  imports: [
    NgxOvSelectComponent,
    CommonModule,
    NgxOvInputLabelComponent,
    ReactiveFormsModule
  ]
})
export class NgxOvEnumSelectionComponent implements OnInit {
  @Input() enumCode: string;
  @Input() parentForm: UntypedFormGroup;
  @Input() required: boolean;
  @Input() searchable: boolean;
  @Input() inputLabel = 'inputLabel';
  @Input() placeholder: string;
  @Input() customFormControlName = 'formControlName';
  @Input() isDisabled = false;
  @Input() alternateConfig: EnumConfig;

  @Output() selectionChange = new EventEmitter<any>();
  @Output() emitError = new EventEmitter<any>();
  enumWaarden: CodeOmschrijvingRestDto[];

  constructor(private enumSelectionService: EnumSelectionService) {}

  ngOnInit(): void {
    this.enumSelectionService
      .findEnumValues(this.enumCode, this.alternateConfig)
      .subscribe({
        next: (value) => {
          this.enumWaarden = value.content;
        },
        error: (err) => this.emitError.emit(err)
      });
  }
}