<div
  class="c-select {{ classes }}"
  [class.is-error]="ngControl?.touched && ngControl?.status === 'INVALID'"
  [class.is-disabled]="isDisabled"
  [class.c-input-field--block]="isBlocked"
  [class.open-search]="isOpen()"
  [class.searchable]="searchable"
>
  <div class="c-select__header">
    <div class="c-select__label_container">
      @if (label()) {
        <ngx-ov-input-label
          [text]="label()"
          [required]="isRequired"
          [for]="identifier"
          [isReadonly]="isReadonly()"
        ></ngx-ov-input-label>
      }
    </div>

    <div class="c-select__close_search">
      <ngx-ov-link (click)="closeSelect()" [text]="closeTextCopy"></ngx-ov-link>
    </div>
  </div>

  @if (!isReadonly()) {
    <ng-select
      #ngSelect
      [name]="name()"
      [items]="items()"
      [bindLabel]="bindLabel()"
      [bindValue]="bindValue()"
      [(ngModel)]="value"
      [ngModelOptions]="{ standalone: true }"
      id="{{ identifier }}"
      [closeOnSelect]="closeOnSelect()"
      [groupBy]="groupBy()"
      [selectableGroup]="selectableGroup()"
      [selectableGroupAsModel]="selectableGroupAsModel()"
      [loading]="isLoading()"
      [multiple]="multiple()"
      [placeholder]="placeholder()"
      [searchable]="searchable()"
      [searchFn]="searchFn() || undefined"
      [disabled]="isDisabled()"
      [clearable]="clearable()"
      [notFoundText]="notFoundTextCopy"
      [compareWith]="compareFn()"
      [typeahead]="typeahead()"
      [typeToSearchText]="typeToSearchTextCopy"
      [trackByFn]="trackSelectItemsByFn()"
      [virtualScroll]="virtualScroll()"
      [appendTo]="appendToSelector()"
      (open)="openSelect()"
      (change)="onChange()"
      (blur)="onBlur(value)"
      (close)="onClose($event)"
      (select)="onSelect($event)"
      (clear)="onClear()"
      (search)="onSearch($event)"
    >
      <ng-template ng-optgroup-tmp let-item="item">
        {{ item.geografie || 'Unnamed group' }}
      </ng-template>

      @if (multiple()) {
        @if (labelTemplate) {
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <ngx-ov-pill (click)="clear(item)" [isFilter]="true">
              <ng-container
                *ngTemplateOutlet="labelTemplate; context: { $implicit: item }"
              >
              </ng-container>
            </ngx-ov-pill>
          </ng-template>
        }
      }

      @if (labelTemplate && !multiple()) {
        <ng-template ng-label-tmp let-item="item">
          <ng-container
            *ngTemplateOutlet="labelTemplate; context: { $implicit: item }"
          >
          </ng-container>
        </ng-template>
      }

      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
        let-search="searchTerm"
      >
        <div
          class="c-checkbox__container"
          [class.c-select__fixed-item]="
            item | isFixedSelectItem: fixedItemsValues() : fixedItemsBindValue()
          "
        >
          @if (multiple() && showCheckbox()) {
            <div class="c-checkbox__toggle">
              <input
                class="c-checkbox__toggle_input"
                type="checkbox"
                id="{{ identifier }}-checkbox-{{ index }}"
                [(ngModel)]="item$.selected"
                [disabled]="isDisabled()"
                tabindex="-1"
              />
              <i
                [attr.data-cy]="'checkbox-click'"
                class="c-checkbox__toggle_box"
                tabindex="-1"
                aria-hidden="true"
              ></i>
            </div>
          }

          @if (optionTemplate) {
            <ng-container
              *ngTemplateOutlet="
                optionTemplate;
                context: { $implicit: item, search: search }
              "
            >
            </ng-container>
          } @else {
            <div>
              <label
                [attr.data-cy]="getDataCyCode(item)"
                [innerHTML]="
                  item
                    ? bindLabel()
                      ? (item
                        | extendedBindValue: bindLabel()
                        | highlightSearch: search : highlightSearch())
                      : (item | highlightSearch: search : highlightSearch())
                    : ''
                "
              >
              </label>
            </div>
          }
        </div>
      </ng-template>
    </ng-select>
  } @else {
    @if (value && value.length) {
      @if (!multiple()) {
        <div class="u-form-readonly__input-readonly">
          @if (!labelTemplate) {
            <div
              [innerHTML]="
                bindLabel()
                  ? (getItemByValue(value) | extendedBindValue: bindLabel())
                  : value
              "
            ></div>
          }
          @if (labelTemplate) {
            <ng-container
              *ngTemplateOutlet="
                labelTemplate;
                context: { $implicit: getItemByValue(value) }
              "
            >
            </ng-container>
          }
        </div>
      }

      @if (multiple()) {
        <ul class="u-form-readonly__input-readonly">
          @for (v of value; track generalTrackByFn) {
            <li>
              @if (!labelTemplate) {
                <ng-container>
                  {{
                    bindLabel()
                      ? ({ value: v } | extendedBindValue: bindLabel())
                      : v
                  }}
                </ng-container>
              }

              @if (labelTemplate) {
                <ng-container
                  *ngTemplateOutlet="
                    labelTemplate;
                    context: { $implicit: getItemByValue(v) }
                  "
                >
                </ng-container>
              }
            </li>
          }
        </ul>
      }
    } @else {
      <div class="u-form-readonly__input-readonly">-</div>
    }
  }
</div>
@if (errors$ | async; as errors) {
  <div class="mt-xsmall">
    @if (ngControl?.touched && ngControl?.status === 'INVALID') {
      <ngx-ov-input-validation [messages]="errors"></ngx-ov-input-validation>
    }
  </div>
}
