/**
 * Naric
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 4.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */



import {ValidatorFn, AbstractControlOptions, FormGroup, FormBuilder, FormArray} from '@angular/forms';
import { SwaggerValidators } from '@ov/ngx-ov-utils';

export interface IGuiNameValuePairRestDto {
	numeric_value?: number;
	value?: string;
}


export class GuiNameValuePairRestDto implements IGuiNameValuePairRestDto {
	numeric_value?: number;
	value?: string;
}

export class GuiNameValuePairRestDtoFormHelper {

    static generateFormWithSwaggerValidators(formBuilder: FormBuilder, controlsConfig: {[key: string]: any;}, options?: AbstractControlOptions | {[key: string]: any;} | null): FormGroup{
        if(controlsConfig["numeric_value"] != undefined && !(controlsConfig["numeric_value"] instanceof FormGroup) && !(controlsConfig["numeric_value"] instanceof FormArray)){
            controlsConfig["numeric_value"][1] = controlsConfig["numeric_value"][1].concat(GuiNameValuePairRestDtoValidator.numeric_value_swagger_validator());
        }
        if(controlsConfig["value"] != undefined && !(controlsConfig["value"] instanceof FormGroup) && !(controlsConfig["value"] instanceof FormArray)){
            controlsConfig["value"][1] = controlsConfig["value"][1].concat(GuiNameValuePairRestDtoValidator.value_swagger_validator());
        }
        if(options){
            return formBuilder.group(controlsConfig, options);
        }
        return formBuilder.group(controlsConfig);
    }
}

export class GuiNameValuePairRestDtoValidator {

        /**
        * Active Validators for numeric_value:
        */
        static numeric_value_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

        /**
        * Active Validators for value:
        */
        static value_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

}



