<div [formGroup]="parentForm" class="grid grid-cols-12 gap-medium">
    <div class="col-span-4">
        <ngx-ov-input-label
                [text]="inputLabel"
                [required]="required"
        ></ngx-ov-input-label>
    </div>
    <div *ngIf="enumWaarden" class="col-span-8">
        <ngx-ov-select
                       [bindLabel]="'omschrijving'"
                       [bindValue]="'code'"
                       [items]="enumWaarden"
                       [formControlName]="customFormControlName"
                       [searchable]="searchable"
                       [placeholder]="placeholder"
                       [isDisabled]="isDisabled"
        ></ngx-ov-select>
    </div>
</div>