import { Pipe, PipeTransform } from '@angular/core';
import * as R from 'ramda';

export const getUniqueValuesByColumn = (data: any[], column: string): any[] => {
  if (!data || !Array.isArray(data)) {
    return [];
  } else {
    const values = data.map((row) => {
      const getValue = R.path(column.split('.'));
      return getValue(row);
    });
    return R.uniq(values)
      .filter((val) => !!val)
      .sort((a, b) => {
        return a?.toLowerCase().localeCompare(b?.toLowerCase());
      });
  }
};

@Pipe({
  name: 'datatableGetUniqueValuesForColumn',
  pure: true,
  standalone: true
})
export class DatatableGetUniqueValuesForColumnPipe implements PipeTransform {
  transform(data: any[], column: string): any[] {
    return getUniqueValuesByColumn(data, column);
  }
}
