export const vestigingsplaatsSearchFn = (term: string, item: any): boolean => {
  return !!(
    item?.geografie.toLowerCase()?.includes(term.toLowerCase()) ||
    item?.instellings_naam.toLowerCase()?.includes(term.toLowerCase())
  );
};

export const nationaliteitSearchFn = (term: string, item: any): boolean => {
  return item === 'Andere' || item.toLowerCase().includes(term.toLowerCase());
};

export const compareVestigingsplaats = (item, selected) => {
  if (selected.geografie && item.geografie) {
    return item.geografie === selected.geografie;
  }
  if (item.code && selected.code) {
    return item.code === selected.code;
  }
  return false;
};
