export const mockVestigingsplaatsen = [
  {
    code: '1',
    geannuleerd: false,
    instellings_nummer: '53454',
    instellings_naam: 'CVO VIVA West-Vlaanderen vzw',
    geografie: 'Torhout',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '10',
    geannuleerd: false,
    instellings_nummer: '33753',
    instellings_naam: 'CVO Vilvoorde',
    geografie: 'Vilvoorde',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '1000',
    geannuleerd: false,
    instellings_nummer: '113688',
    instellings_naam: 'CVO Technicum Noord-Antwerpen',
    geografie: 'Brasschaat',
    geldigheids_periode: {
      geldig_van: '2012-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1002',
    geannuleerd: false,
    instellings_nummer: '126607',
    instellings_naam: 'CBE Ligo Gent-Meetjesland-Leieland',
    geografie: 'Aalter',
    geldigheids_periode: {
      geldig_van: '2012-12-13',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1004',
    geannuleerd: false,
    instellings_nummer: '126607',
    instellings_naam: 'CBE Ligo Gent-Meetjesland-Leieland',
    geografie: 'Maldegem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2013-01-21',
      laatst_gewijzigd_door: 'MWOV'
    }
  },
  {
    code: '1005',
    geannuleerd: true,
    instellings_nummer: '33498',
    instellings_naam: 'CVO Strombeek - Grimbergen',
    geografie: 'Vilvoorde',
    geldigheids_periode: {
      geldig_van: '2013-01-07',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2013-01-25',
      laatst_gewijzigd_door: 'MWOV'
    }
  },
  {
    code: '1006',
    geannuleerd: false,
    instellings_nummer: '48488',
    instellings_naam: 'GO! CVO Cervo',
    geografie: 'Mesen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2013-02-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1007',
    geannuleerd: false,
    instellings_nummer: '117391',
    instellings_naam: 'CVO Roeselare',
    geografie: 'Lichtervelde',
    geldigheids_periode: {
      geldig_van: '2013-02-01'
    },
    audit: {
      laatst_gewijzigd_op: '2013-01-25',
      laatst_gewijzigd_door: 'MWOV'
    }
  },
  {
    code: '1008',
    geannuleerd: false,
    instellings_nummer: '117391',
    instellings_naam: 'CVO Roeselare',
    geografie: 'Staden',
    geldigheids_periode: {
      geldig_van: '2013-02-01'
    },
    audit: {
      laatst_gewijzigd_op: '2013-01-25',
      laatst_gewijzigd_door: 'MWOV'
    }
  },
  {
    code: '101',
    geannuleerd: false,
    instellings_nummer: '31104',
    instellings_naam: 'CVO LBC-NVK L 31104',
    geografie: 'Antwerpen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1010',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Lint',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2021-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1012',
    geannuleerd: false,
    instellings_nummer: '47654',
    instellings_naam: 'CVO Hoger Instituut der Kempen',
    geografie: 'Westerlo',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2013-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1013',
    geannuleerd: false,
    instellings_nummer: '46532',
    instellings_naam: 'GO! CVO Rivierenland',
    geografie: 'Kontich',
    geldigheids_periode: {
      geldig_van: '2013-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1014',
    geannuleerd: false,
    instellings_nummer: '33555',
    instellings_naam: 'CVO Tervuren - Hoeilaart',
    geografie: 'Kortenberg',
    geldigheids_periode: {
      geldig_van: '2013-09-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2013-05-30',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1015',
    geannuleerd: false,
    instellings_nummer: '33191',
    instellings_naam: 'CVO Meise-Jette',
    geografie: 'Vilvoorde',
    geldigheids_periode: {
      geldig_van: '2013-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1016',
    geannuleerd: false,
    instellings_nummer: '33498',
    instellings_naam: 'CVO Strombeek - Grimbergen',
    geografie: 'Vilvoorde',
    geldigheids_periode: {
      geldig_van: '2013-09-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2013-05-30',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1017',
    geannuleerd: false,
    instellings_nummer: '39834',
    instellings_naam: 'CVO LBC-NVK Beringen',
    geografie: 'Tessenderlo',
    geldigheids_periode: {
      geldig_van: '2013-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1018',
    geannuleerd: false,
    instellings_nummer: '126607',
    instellings_naam: 'CBE Ligo Gent-Meetjesland-Leieland',
    geografie: 'Zulte',
    geldigheids_periode: {
      geldig_van: '2013-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2013-09-17',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1020',
    geannuleerd: false,
    instellings_nummer: '126581',
    instellings_naam: 'CBE Ligo Waas & Dender',
    geografie: 'Wichelen',
    geldigheids_periode: {
      geldig_van: '2013-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2013-10-22',
      laatst_gewijzigd_door: '88061035967'
    }
  },
  {
    code: '1022',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Kasterlee',
    geldigheids_periode: {
      geldig_van: '2013-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2013-10-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1024',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Edegem',
    geldigheids_periode: {
      geldig_van: '2013-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2013-11-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1026',
    geannuleerd: false,
    instellings_nummer: '126607',
    instellings_naam: 'CBE Ligo Gent-Meetjesland-Leieland',
    geografie: 'Nazareth',
    geldigheids_periode: {
      geldig_van: '2013-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2014-02-17',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1027',
    geannuleerd: false,
    instellings_nummer: '126607',
    instellings_naam: 'CBE Ligo Gent-Meetjesland-Leieland',
    geografie: 'Gavere',
    geldigheids_periode: {
      geldig_van: '2013-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2014-02-17',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1028',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Grobbendonk',
    geldigheids_periode: {
      geldig_van: '2013-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2014-03-31',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1030',
    geannuleerd: false,
    instellings_nummer: '130765',
    instellings_naam: 'PCVO Limburg',
    geografie: 'Dilsen-Stokkem',
    geldigheids_periode: {
      geldig_van: '2014-05-01'
    },
    audit: {
      laatst_gewijzigd_op: '2014-09-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1031',
    geannuleerd: false,
    instellings_nummer: '130765',
    instellings_naam: 'PCVO Limburg',
    geografie: 'Maasmechelen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2014-05-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1032',
    geannuleerd: false,
    instellings_nummer: '130765',
    instellings_naam: 'PCVO Limburg',
    geografie: 'Hasselt',
    geldigheids_periode: {
      geldig_van: '2014-05-01'
    },
    audit: {
      laatst_gewijzigd_op: '2014-09-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1033',
    geannuleerd: false,
    instellings_nummer: '130765',
    instellings_naam: 'PCVO Limburg',
    geografie: 'Diepenbeek',
    geldigheids_periode: {
      geldig_van: '2014-05-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-08-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1034',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Kortrijk',
    geldigheids_periode: {
      geldig_van: '2014-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2014-05-20',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1035',
    geannuleerd: false,
    instellings_nummer: '39231',
    instellings_naam: 'CVO VSPW - Hasselt',
    geografie: 'Genk',
    geldigheids_periode: {
      geldig_van: '2014-09-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1036',
    geannuleerd: false,
    instellings_nummer: '39231',
    instellings_naam: 'CVO VSPW - Hasselt',
    geografie: 'Overpelt',
    geldigheids_periode: {
      geldig_van: '2014-09-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1037',
    geannuleerd: false,
    instellings_nummer: '39231',
    instellings_naam: 'CVO VSPW - Hasselt',
    geografie: 'Maasmechelen',
    geldigheids_periode: {
      geldig_van: '2014-09-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1038',
    geannuleerd: false,
    instellings_nummer: '46524',
    instellings_naam: 'GO! CVO HORITO Turnhout',
    geografie: 'Mol',
    geldigheids_periode: {
      geldig_van: '2014-09-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2014-05-20',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1040',
    geannuleerd: false,
    instellings_nummer: '112615',
    instellings_naam: 'GO! CVO Altus',
    geografie: 'Kortrijk',
    geldigheids_periode: {
      geldig_van: '2014-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-08-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1041',
    geannuleerd: false,
    instellings_nummer: '112615',
    instellings_naam: 'GO! CVO Altus',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '2014-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-08-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1042',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Brugge',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2014-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-02-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1043',
    geannuleerd: false,
    instellings_nummer: '47654',
    instellings_naam: 'CVO Hoger Instituut der Kempen',
    geografie: 'Herentals',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2014-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1044',
    geannuleerd: false,
    instellings_nummer: '30619',
    instellings_naam: 'Technische Scholen Mechelen - CVO',
    geografie: 'Leuven',
    geldigheids_periode: {
      geldig_van: '2014-09-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2014-05-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1045',
    geannuleerd: false,
    instellings_nummer: '48462',
    instellings_naam: 'GO! CVO Focus',
    geografie: 'Aalst',
    geldigheids_periode: {
      geldig_van: '2014-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-08-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1046',
    geannuleerd: false,
    instellings_nummer: '48462',
    instellings_naam: 'GO! CVO Focus',
    geografie: 'Geraardsbergen',
    geldigheids_periode: {
      geldig_van: '2014-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-08-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1047',
    geannuleerd: false,
    instellings_nummer: '37895',
    instellings_naam: 'CVO KISP-VAZOV',
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '2014-09-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2014-06-04',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1048',
    geannuleerd: false,
    instellings_nummer: '35881',
    instellings_naam: 'CVO Kisp - VTI Aalst',
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '2014-09-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2014-06-04',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1049',
    geannuleerd: false,
    instellings_nummer: '28431',
    instellings_naam: 'CVO LBC-NVK B 28431',
    geografie: 'Sint-Niklaas',
    geldigheids_periode: {
      geldig_van: '2014-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-08-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1050',
    geannuleerd: false,
    instellings_nummer: '47654',
    instellings_naam: 'CVO Hoger Instituut der Kempen',
    geografie: 'Turnhout',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2014-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1051',
    geannuleerd: false,
    instellings_nummer: '28431',
    instellings_naam: 'CVO LBC-NVK B 28431',
    geografie: 'Geel',
    geldigheids_periode: {
      geldig_van: '2014-09-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2017-12-06',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1052',
    geannuleerd: false,
    instellings_nummer: '34884',
    instellings_naam: 'CVO MIRAS 34884',
    geografie: 'Brugge',
    geldigheids_periode: {
      geldig_van: '2014-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1053',
    geannuleerd: false,
    instellings_nummer: '130864',
    instellings_naam: 'GO! CVO VOLT',
    geografie: 'Tienen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2014-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1054',
    geannuleerd: false,
    instellings_nummer: '130864',
    instellings_naam: 'GO! CVO VOLT',
    geografie: 'Leuven',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2014-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1055',
    geannuleerd: false,
    instellings_nummer: '130864',
    instellings_naam: 'GO! CVO VOLT',
    geografie: 'Landen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2014-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1056',
    geannuleerd: false,
    instellings_nummer: '130864',
    instellings_naam: 'GO! CVO VOLT',
    geografie: 'Sint-Truiden',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2014-09-01',
      geldig_tot: '2021-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1057',
    geannuleerd: false,
    instellings_nummer: '130864',
    instellings_naam: 'GO! CVO VOLT',
    geografie: 'Gingelom',
    geldigheids_periode: {
      geldig_van: '2014-09-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-05-22',
      laatst_gewijzigd_door: '80090108576'
    }
  },
  {
    code: '1058',
    geannuleerd: false,
    instellings_nummer: '48462',
    instellings_naam: 'GO! CVO Focus',
    geografie: 'Wetteren',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2014-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1059',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Sint-Amands',
    geldigheids_periode: {
      geldig_van: '2014-09-01',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1061',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Olen',
    geldigheids_periode: {
      geldig_van: '2014-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2014-10-17',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1063',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Lichtervelde',
    geldigheids_periode: {
      geldig_van: '2014-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2014-11-17',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1065',
    geannuleerd: false,
    instellings_nummer: '46524',
    instellings_naam: 'GO! CVO HORITO Turnhout',
    geografie: 'Zoersel',
    geldigheids_periode: {
      geldig_van: '2015-02-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-01-20',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1067',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Opwijk',
    geldigheids_periode: {
      geldig_van: '2014-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-01-27',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1069',
    geannuleerd: false,
    instellings_nummer: '126581',
    instellings_naam: 'CBE Ligo Waas & Dender',
    geografie: 'Kruibeke',
    geldigheids_periode: {
      geldig_van: '2014-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-03-25',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '107',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Antwerpen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1071',
    geannuleerd: false,
    instellings_nummer: '126541',
    instellings_naam: 'CBE Ligo Oost-Brabant',
    geografie: 'Tielt-Winge',
    geldigheids_periode: {
      geldig_van: '2014-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-04-28',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1073',
    geannuleerd: false,
    instellings_nummer: '131318',
    instellings_naam: 'CVO Gent',
    geografie: 'Gent',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-02-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1075',
    geannuleerd: false,
    instellings_nummer: '35881',
    instellings_naam: 'CVO Kisp - VTI Aalst',
    geografie: 'Oudenaarde',
    geldigheids_periode: {
      geldig_van: '2015-09-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-05-19',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1076',
    geannuleerd: false,
    instellings_nummer: '35881',
    instellings_naam: 'CVO Kisp - VTI Aalst',
    geografie: 'Ronse',
    geldigheids_periode: {
      geldig_van: '2015-09-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-05-19',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1077',
    geannuleerd: false,
    instellings_nummer: '37895',
    instellings_naam: 'CVO KISP-VAZOV',
    geografie: 'Aalst',
    geldigheids_periode: {
      geldig_van: '2015-09-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-05-19',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1078',
    geannuleerd: false,
    instellings_nummer: '131383',
    instellings_naam: 'CVO Qrios Zuid',
    geografie: 'Genk',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-05-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1079',
    geannuleerd: false,
    instellings_nummer: '131383',
    instellings_naam: 'CVO Qrios Zuid',
    geografie: 'Hasselt',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-05-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '108',
    geannuleerd: false,
    instellings_nummer: '46482',
    instellings_naam: 'GO! CVO IVORAN Kapellen',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '1080',
    geannuleerd: false,
    instellings_nummer: '131383',
    instellings_naam: 'CVO Qrios Zuid',
    geografie: 'Herk-de-Stad',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-05-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1081',
    geannuleerd: false,
    instellings_nummer: '131383',
    instellings_naam: 'CVO Qrios Zuid',
    geografie: 'Overpelt',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-05-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1082',
    geannuleerd: false,
    instellings_nummer: '131383',
    instellings_naam: 'CVO Qrios Zuid',
    geografie: 'Maasmechelen',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-05-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1083',
    geannuleerd: false,
    instellings_nummer: '131383',
    instellings_naam: 'CVO Qrios Zuid',
    geografie: 'Tongeren',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-05-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1084',
    geannuleerd: false,
    instellings_nummer: '131375',
    instellings_naam: 'CVO Qrios Noord',
    geografie: 'Houthalen-Helchteren',
    geldigheids_periode: {
      geldig_van: '2015-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1085',
    geannuleerd: false,
    instellings_nummer: '131375',
    instellings_naam: 'CVO Qrios Noord',
    geografie: 'Genk',
    geldigheids_periode: {
      geldig_van: '2015-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1086',
    geannuleerd: false,
    instellings_nummer: '131375',
    instellings_naam: 'CVO Qrios Noord',
    geografie: 'Maasmechelen',
    geldigheids_periode: {
      geldig_van: '2015-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1087',
    geannuleerd: false,
    instellings_nummer: '131375',
    instellings_naam: 'CVO Qrios Noord',
    geografie: 'Overpelt',
    geldigheids_periode: {
      geldig_van: '2015-09-01',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1088',
    geannuleerd: false,
    instellings_nummer: '131375',
    instellings_naam: 'CVO Qrios Noord',
    geografie: 'Hamont-Achel',
    geldigheids_periode: {
      geldig_van: '2015-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1089',
    geannuleerd: false,
    instellings_nummer: '131375',
    instellings_naam: 'CVO Qrios Noord',
    geografie: 'Maaseik',
    geldigheids_periode: {
      geldig_van: '2015-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '109',
    geannuleerd: false,
    instellings_nummer: '47654',
    instellings_naam: 'CVO Hoger Instituut der Kempen',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2014-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2014-05-20',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1090',
    geannuleerd: false,
    instellings_nummer: '131375',
    instellings_naam: 'CVO Qrios Noord',
    geografie: 'Peer',
    geldigheids_periode: {
      geldig_van: '2015-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1091',
    geannuleerd: false,
    instellings_nummer: '131375',
    instellings_naam: 'CVO Qrios Noord',
    geografie: 'Neerpelt',
    geldigheids_periode: {
      geldig_van: '2015-09-01',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1092',
    geannuleerd: true,
    instellings_nummer: '31401',
    instellings_naam: 'CVO LBC-NVK Turnhout',
    geografie: 'Wuustwezel',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-06-01',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1093',
    geannuleerd: false,
    instellings_nummer: '117721',
    instellings_naam: 'CVO Vitant',
    geografie: 'Turnhout',
    geldigheids_periode: {
      geldig_van: '2015-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-08-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1094',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Beveren',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-06-01',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1095',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Buggenhout',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-06-01',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1096',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Dendermonde',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-06-01',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1097',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Hamme',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1098',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Lokeren',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1099',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Sint-Niklaas',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-06-01',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '11',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Vilvoorde',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '110',
    geannuleerd: false,
    instellings_nummer: '48629',
    instellings_naam: 'CVO Sopro',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '1100',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Wetteren',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-06-01',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1101',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Zele',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-06-01',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1103',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Boom',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-06-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1104',
    geannuleerd: false,
    instellings_nummer: '33191',
    instellings_naam: 'CVO Meise-Jette',
    geografie: 'Halle',
    geldigheids_periode: {
      geldig_van: '2015-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1105',
    geannuleerd: false,
    instellings_nummer: '33753',
    instellings_naam: 'CVO Vilvoorde',
    geografie: 'Halle',
    geldigheids_periode: {
      geldig_van: '2015-09-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-06-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1106',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '2015-09-01',
      geldig_tot: '2015-09-02'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-12',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1107',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Kapelle-op-den-Bos',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-09-24',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1109',
    geannuleerd: false,
    instellings_nummer: '126607',
    instellings_naam: 'CBE Ligo Gent-Meetjesland-Leieland',
    geografie: 'Evergem',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-02',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '111',
    geannuleerd: false,
    instellings_nummer: '48934',
    instellings_naam: 'GO! CVO VIVA Antwerpen',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1111',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Opglabbeek',
    geldigheids_periode: {
      geldig_van: '2015-09-01',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1112',
    geannuleerd: false,
    instellings_nummer: '46532',
    instellings_naam: 'GO! CVO Rivierenland',
    geografie: 'Rumst',
    geldigheids_periode: {
      geldig_van: '2016-02-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1113',
    geannuleerd: false,
    instellings_nummer: '47837',
    instellings_naam: 'CVO ISBO',
    geografie: 'Wachtebeke',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2016-02-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1114',
    geannuleerd: false,
    instellings_nummer: '34066',
    instellings_naam: 'SNT  CVO',
    geografie: 'Damme',
    geldigheids_periode: {
      geldig_van: '2016-02-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-02-01',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1115',
    geannuleerd: false,
    instellings_nummer: '41129',
    instellings_naam: 'GO! CVO EduKempen',
    geografie: 'Retie',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2016-02-04'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1116',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Retie',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-02-04',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1118',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Lummen',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-02-17',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1120',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Arendonk',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-02-23',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1122',
    geannuleerd: false,
    instellings_nummer: '30271',
    instellings_naam: 'CVO HIK',
    geografie: 'Ravels',
    geldigheids_periode: {
      geldig_van: '2016-03-10',
      geldig_tot: '2016-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2016-03-11',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1123',
    geannuleerd: false,
    instellings_nummer: '41129',
    instellings_naam: 'GO! CVO EduKempen',
    geografie: 'Ravels',
    geldigheids_periode: {
      geldig_van: '2016-03-11',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2017-01-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1124',
    geannuleerd: false,
    instellings_nummer: '42051',
    instellings_naam: 'GO! CVO Avelgem/Harelbeke',
    geografie: 'Ingelmunster',
    geldigheids_periode: {
      geldig_van: '2016-03-17',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2017-10-09',
      laatst_gewijzigd_door: '76122930124'
    }
  },
  {
    code: '1126',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Ravels',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-03-21',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1128',
    geannuleerd: false,
    instellings_nummer: '126516',
    instellings_naam: 'CBE Ligo Antwerpen',
    geografie: 'Zwijndrecht',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-04-25',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1130',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Lille',
    geldigheids_periode: {
      geldig_van: '2015-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-04-27',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1132',
    geannuleerd: false,
    instellings_nummer: '126607',
    instellings_naam: 'CBE Ligo Gent-Meetjesland-Leieland',
    geografie: 'Knesselare',
    geldigheids_periode: {
      geldig_van: '2015-09-01',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1134',
    geannuleerd: false,
    instellings_nummer: '35402',
    instellings_naam: 'CVO Kokelaarstraat',
    geografie: 'Lichtervelde',
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-06-02',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1136',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Hasselt',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-01-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1137',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Herk-de-Stad',
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-06-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1138',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Sint-Truiden',
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-06-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1139',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Tongeren',
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-06-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '114',
    geannuleerd: false,
    instellings_nummer: '112441',
    instellings_naam: 'PCVO Toekomstonderwijs',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '1140',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Genk',
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-06-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1141',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Overpelt',
    geldigheids_periode: {
      geldig_van: '2016-09-01',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1142',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Maasmechelen',
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-06-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1143',
    geannuleerd: false,
    instellings_nummer: '131888',
    instellings_naam: 'CVO Groeipunt (131888)',
    geografie: 'Assenede',
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-06-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1144',
    geannuleerd: false,
    instellings_nummer: '131888',
    instellings_naam: 'CVO Groeipunt (131888)',
    geografie: 'Deinze',
    geldigheids_periode: {
      geldig_van: '2016-09-01',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1145',
    geannuleerd: false,
    instellings_nummer: '131888',
    instellings_naam: 'CVO Groeipunt (131888)',
    geografie: 'Eeklo',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-01-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1146',
    geannuleerd: false,
    instellings_nummer: '131888',
    instellings_naam: 'CVO Groeipunt (131888)',
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-06-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1147',
    geannuleerd: false,
    instellings_nummer: '39834',
    instellings_naam: 'CVO LBC-NVK Beringen',
    geografie: 'Leopoldsburg',
    geldigheids_periode: {
      geldig_van: '2016-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1148',
    geannuleerd: false,
    instellings_nummer: '112599',
    instellings_naam: 'GO! CVO 3 Hofsteden',
    geografie: 'Lendelede',
    geldigheids_periode: {
      geldig_van: '2016-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1149',
    geannuleerd: false,
    instellings_nummer: '132001',
    instellings_naam: 'CVO Creo',
    geografie: 'Roeselare',
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-06-15',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '115',
    geannuleerd: false,
    instellings_nummer: '112508',
    instellings_naam: 'CVO VTI SP Lier-Antwerpen',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1150',
    geannuleerd: false,
    instellings_nummer: '132001',
    instellings_naam: 'CVO Creo',
    geografie: 'Hooglede',
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-06-15',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1151',
    geannuleerd: false,
    instellings_nummer: '132001',
    instellings_naam: 'CVO Creo',
    geografie: 'Lichtervelde',
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-06-15',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1152',
    geannuleerd: false,
    instellings_nummer: '132001',
    instellings_naam: 'CVO Creo',
    geografie: 'Moorslede',
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-06-15',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1153',
    geannuleerd: false,
    instellings_nummer: '132001',
    instellings_naam: 'CVO Creo',
    geografie: 'Ruiselede',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2016-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1154',
    geannuleerd: false,
    instellings_nummer: '132001',
    instellings_naam: 'CVO Creo',
    geografie: 'Staden',
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-06-15',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1155',
    geannuleerd: false,
    instellings_nummer: '132001',
    instellings_naam: 'CVO Creo',
    geografie: 'Tielt',
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-06-15',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1156',
    geannuleerd: false,
    instellings_nummer: '112599',
    instellings_naam: 'GO! CVO 3 Hofsteden',
    geografie: 'Izegem',
    geldigheids_periode: {
      geldig_van: '2016-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1157',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Vleteren',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-06-22',
      laatst_gewijzigd_door: '88061035967'
    }
  },
  {
    code: '1158',
    geannuleerd: false,
    instellings_nummer: '132134',
    instellings_naam: 'CVO Master Talent',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2016-11-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1160',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Lo-Reninge',
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2017-01-25',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1162',
    geannuleerd: false,
    instellings_nummer: '40139',
    instellings_naam: 'PCVO Talen - Informatica Voeren',
    geografie: 'Riemst',
    geldigheids_periode: {
      geldig_van: '2017-02-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1163',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Nieuwerkerken',
    geldigheids_periode: {
      geldig_van: '2017-02-01'
    },
    audit: {
      laatst_gewijzigd_op: '2017-01-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1164',
    geannuleerd: false,
    instellings_nummer: '126607',
    instellings_naam: 'CBE Ligo Gent-Meetjesland-Leieland',
    geografie: 'Waarschoot',
    geldigheids_periode: {
      geldig_van: '2016-09-01',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1166',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Moorslede',
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2017-02-22',
      laatst_gewijzigd_door: '93100741738'
    }
  },
  {
    code: '1168',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Zonnebeke',
    geldigheids_periode: {
      geldig_van: '2016-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2017-03-27',
      laatst_gewijzigd_door: '93100741738'
    }
  },
  {
    code: '117',
    geannuleerd: false,
    instellings_nummer: '112706',
    instellings_naam: 'GO! CVO Deurne',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2012-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2013-01-25',
      laatst_gewijzigd_door: 'MWOV'
    }
  },
  {
    code: '1170',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Lennik',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2017-04-25',
      laatst_gewijzigd_door: '93100741738'
    }
  },
  {
    code: '1172',
    geannuleerd: false,
    instellings_nummer: '113696',
    instellings_naam: 'CVO Tanera',
    geografie: 'Denderleeuw',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2017-05-10',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1173',
    geannuleerd: false,
    instellings_nummer: '113696',
    instellings_naam: 'CVO Tanera',
    geografie: 'Geraardsbergen',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2017-05-10',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1174',
    geannuleerd: false,
    instellings_nummer: '32383',
    instellings_naam: 'CVO Semper',
    geografie: 'Vilvoorde',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-06',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1175',
    geannuleerd: false,
    instellings_nummer: '32383',
    instellings_naam: 'CVO Semper',
    geografie: 'Grimbergen',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-06',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1176',
    geannuleerd: false,
    instellings_nummer: '32383',
    instellings_naam: 'CVO Semper',
    geografie: 'Halle',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-06',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1177',
    geannuleerd: false,
    instellings_nummer: '32383',
    instellings_naam: 'CVO Semper',
    geografie: 'Steenokkerzeel',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-06',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1178',
    geannuleerd: false,
    instellings_nummer: '32383',
    instellings_naam: 'CVO Semper',
    geografie: 'Wemmel',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-06',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '118',
    geannuleerd: false,
    instellings_nummer: '113688',
    instellings_naam: 'CVO Technicum Noord-Antwerpen',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1180',
    geannuleerd: false,
    instellings_nummer: '112615',
    instellings_naam: 'GO! CVO Altus',
    geografie: 'Blankenberge',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1182',
    geannuleerd: false,
    instellings_nummer: '112599',
    instellings_naam: 'GO! CVO 3 Hofsteden',
    geografie: 'Waregem',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1183',
    geannuleerd: false,
    instellings_nummer: '112599',
    instellings_naam: 'GO! CVO 3 Hofsteden',
    geografie: 'Harelbeke',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1184',
    geannuleerd: false,
    instellings_nummer: '112599',
    instellings_naam: 'GO! CVO 3 Hofsteden',
    geografie: 'Wervik',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1186',
    geannuleerd: false,
    instellings_nummer: '35881',
    instellings_naam: 'CVO Kisp - VTI Aalst',
    geografie: 'Geraardsbergen',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1188',
    geannuleerd: false,
    instellings_nummer: '113712',
    instellings_naam: 'GO! hét CVO Pro',
    geografie: 'Lede',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-14',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '119',
    geannuleerd: false,
    instellings_nummer: '115444',
    instellings_naam: 'Stedelijk CVO Encora 115444',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1190',
    geannuleerd: false,
    instellings_nummer: '48934',
    instellings_naam: 'GO! CVO VIVA Antwerpen',
    geografie: 'Kapellen',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1191',
    geannuleerd: false,
    instellings_nummer: '54486',
    instellings_naam: 'CVO Technisch Instituut Sint-Jozef',
    geografie: 'Laakdal',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1192',
    geannuleerd: false,
    instellings_nummer: '54486',
    instellings_naam: 'CVO Technisch Instituut Sint-Jozef',
    geografie: 'Lummen',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1194',
    geannuleerd: false,
    instellings_nummer: '28531',
    instellings_naam: 'CVO Encora 028531',
    geografie: 'Antwerpen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1195',
    geannuleerd: false,
    instellings_nummer: '30619',
    instellings_naam: 'Technische Scholen Mechelen - CVO',
    geografie: 'Mechelen',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1196',
    geannuleerd: false,
    instellings_nummer: '31401',
    instellings_naam: 'CVO LBC-NVK Turnhout',
    geografie: 'Hoogstraten',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1197',
    geannuleerd: false,
    instellings_nummer: '31898',
    instellings_naam: 'CVO Hageland - Aarschot',
    geografie: 'Leuven',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1198',
    geannuleerd: false,
    instellings_nummer: '34066',
    instellings_naam: 'SNT  CVO',
    geografie: 'Brugge',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1199',
    geannuleerd: false,
    instellings_nummer: '35972',
    instellings_naam: 'CVO Kisp - VTH Aalst',
    geografie: 'Dendermonde',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '120',
    geannuleerd: false,
    instellings_nummer: '117721',
    instellings_naam: 'CVO Vitant',
    geografie: 'Antwerpen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-01-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1200',
    geannuleerd: false,
    instellings_nummer: '37119',
    instellings_naam: 'PCVO VSPW',
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1201',
    geannuleerd: false,
    instellings_nummer: '37895',
    instellings_naam: 'CVO KISP-VAZOV',
    geografie: 'Oudenaarde',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2018-05-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1202',
    geannuleerd: false,
    instellings_nummer: '32383',
    instellings_naam: 'CVO Semper',
    geografie: 'Brussels Hoofdstedelijk Gewest',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-02-20',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1203',
    geannuleerd: false,
    instellings_nummer: '32491',
    instellings_naam: 'CVO Lethas Brussel',
    geografie: 'Brussels Hoofdstedelijk Gewest',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-02-21',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1204',
    geannuleerd: false,
    instellings_nummer: '33191',
    instellings_naam: 'CVO Meise-Jette',
    geografie: 'Brussels Hoofdstedelijk Gewest',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1205',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Beveren',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-05-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1206',
    geannuleerd: false,
    instellings_nummer: '41129',
    instellings_naam: 'GO! CVO EduKempen',
    geografie: 'Merksplas',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1207',
    geannuleerd: false,
    instellings_nummer: '41129',
    instellings_naam: 'GO! CVO EduKempen',
    geografie: 'Turnhout',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1208',
    geannuleerd: false,
    instellings_nummer: '41129',
    instellings_naam: 'GO! CVO EduKempen',
    geografie: 'Hoogstraten',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1209',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Antwerpen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '121',
    geannuleerd: false,
    instellings_nummer: '48595',
    instellings_naam: 'CVO MIRAS 48595',
    geografie: 'Anzegem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1210',
    geannuleerd: false,
    instellings_nummer: '46524',
    instellings_naam: 'GO! CVO HORITO Turnhout',
    geografie: 'Merksplas',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2018-05-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1211',
    geannuleerd: false,
    instellings_nummer: '47837',
    instellings_naam: 'CVO ISBO',
    geografie: 'Gent',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1212',
    geannuleerd: false,
    instellings_nummer: '48421',
    instellings_naam: 'GO! CVO JANITOR',
    geografie: 'Beveren',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1213',
    geannuleerd: false,
    instellings_nummer: '48421',
    instellings_naam: 'GO! CVO JANITOR',
    geografie: 'Dendermonde',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1214',
    geannuleerd: false,
    instellings_nummer: '48512',
    instellings_naam: 'CVO SVG',
    geografie: 'Brugge',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-05-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1215',
    geannuleerd: false,
    instellings_nummer: '48901',
    instellings_naam: 'CVO Creo 48901',
    geografie: 'Ruiselede',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1216',
    geannuleerd: false,
    instellings_nummer: '56366',
    instellings_naam: 'CVO COOVI',
    geografie: 'Brussels Hoofdstedelijk Gewest',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1217',
    geannuleerd: false,
    instellings_nummer: '110536',
    instellings_naam: 'GO! CVO De Vlaamse Ardennen',
    geografie: 'Oudenaarde',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1218',
    geannuleerd: false,
    instellings_nummer: '112599',
    instellings_naam: 'GO! CVO 3 Hofsteden',
    geografie: 'Ruiselede',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1219',
    geannuleerd: false,
    instellings_nummer: '112714',
    instellings_naam: 'GO! CVO - STEP',
    geografie: 'Hasselt',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1220',
    geannuleerd: false,
    instellings_nummer: '117077',
    instellings_naam: 'PCVO Groeipunt',
    geografie: 'Oudenaarde',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1221',
    geannuleerd: false,
    instellings_nummer: '117168',
    instellings_naam: 'Vrij Tech. Inst. Leuven - CVO',
    geografie: 'Leuven',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1222',
    geannuleerd: false,
    instellings_nummer: '117747',
    instellings_naam: 'CVO VTI Brugge',
    geografie: 'Brugge',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1223',
    geannuleerd: false,
    instellings_nummer: '125468',
    instellings_naam: 'GO! CVO De Oranjerie',
    geografie: 'Leuven',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1224',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Mechelen',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-05-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1225',
    geannuleerd: false,
    instellings_nummer: '130765',
    instellings_naam: 'PCVO Limburg',
    geografie: 'Hasselt',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1226',
    geannuleerd: false,
    instellings_nummer: '130864',
    instellings_naam: 'GO! CVO VOLT',
    geografie: 'Leuven',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1227',
    geannuleerd: false,
    instellings_nummer: '131318',
    instellings_naam: 'CVO Gent',
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-05-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1228',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Dendermonde',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1229',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Beveren',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '123',
    geannuleerd: false,
    instellings_nummer: '31096',
    instellings_naam: 'CVO - Vormingslg. Soc. en Ped. Werk Mol',
    geografie: 'Arendonk',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1230',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Hasselt',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-05-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1231',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Tongeren',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-05-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1232',
    geannuleerd: false,
    instellings_nummer: '131888',
    instellings_naam: 'CVO Groeipunt (131888)',
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-05-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1233',
    geannuleerd: false,
    instellings_nummer: '132001',
    instellings_naam: 'CVO Creo',
    geografie: 'Ruiselede',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-05-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1234',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-05-14',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1236',
    geannuleerd: false,
    instellings_nummer: '39222',
    instellings_naam: 'PCVO Moderne Talen Hasselt',
    geografie: 'Hasselt',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-05-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1238',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Malle',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2017-09-06',
      laatst_gewijzigd_door: '93100741738'
    }
  },
  {
    code: '124',
    geannuleerd: false,
    instellings_nummer: '46524',
    instellings_naam: 'GO! CVO HORITO Turnhout',
    geografie: 'Arendonk',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '1240',
    geannuleerd: false,
    instellings_nummer: '48488',
    instellings_naam: 'GO! CVO Cervo',
    geografie: 'Ieper',
    geldigheids_periode: {
      geldig_van: '2018-02-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-05-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1241',
    geannuleerd: false,
    instellings_nummer: '48595',
    instellings_naam: 'CVO MIRAS 48595',
    geografie: 'Dentergem',
    geldigheids_periode: {
      geldig_van: '2018-02-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1242',
    geannuleerd: false,
    instellings_nummer: '48595',
    instellings_naam: 'CVO MIRAS 48595',
    geografie: 'Wielsbeke',
    geldigheids_periode: {
      geldig_van: '2018-02-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1243',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Keerbergen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2018-02-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1244',
    geannuleerd: false,
    instellings_nummer: '130765',
    instellings_naam: 'PCVO Limburg',
    geografie: 'Tongeren',
    geldigheids_periode: {
      geldig_van: '2018-02-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-01-24',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1246',
    geannuleerd: false,
    instellings_nummer: '110536',
    instellings_naam: 'GO! CVO De Vlaamse Ardennen',
    geografie: 'Avelgem',
    geldigheids_periode: {
      geldig_van: '2018-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1247',
    geannuleerd: false,
    instellings_nummer: '110536',
    instellings_naam: 'GO! CVO De Vlaamse Ardennen',
    geografie: 'Harelbeke',
    geldigheids_periode: {
      geldig_van: '2018-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1248',
    geannuleerd: false,
    instellings_nummer: '110536',
    instellings_naam: 'GO! CVO De Vlaamse Ardennen',
    geografie: 'Ingelmunster',
    geldigheids_periode: {
      geldig_van: '2018-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1249',
    geannuleerd: false,
    instellings_nummer: '110536',
    instellings_naam: 'GO! CVO De Vlaamse Ardennen',
    geografie: 'Spiere-Helkijn',
    geldigheids_periode: {
      geldig_van: '2018-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '125',
    geannuleerd: false,
    instellings_nummer: '54486',
    instellings_naam: 'CVO Technisch Instituut Sint-Jozef',
    geografie: 'Arendonk',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1250',
    geannuleerd: false,
    instellings_nummer: '41129',
    instellings_naam: 'GO! CVO EduKempen',
    geografie: 'Beerse',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1251',
    geannuleerd: false,
    instellings_nummer: '41129',
    instellings_naam: 'GO! CVO EduKempen',
    geografie: 'Herentals',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1252',
    geannuleerd: false,
    instellings_nummer: '41129',
    instellings_naam: 'GO! CVO EduKempen',
    geografie: 'Zoersel',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1253',
    geannuleerd: false,
    instellings_nummer: '41129',
    instellings_naam: 'GO! CVO EduKempen',
    geografie: 'Balen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1254',
    geannuleerd: false,
    instellings_nummer: '41129',
    instellings_naam: 'GO! CVO EduKempen',
    geografie: 'Geel',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1255',
    geannuleerd: false,
    instellings_nummer: '41129',
    instellings_naam: 'GO! CVO EduKempen',
    geografie: 'Mol',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1256',
    geannuleerd: false,
    instellings_nummer: '41129',
    instellings_naam: 'GO! CVO EduKempen',
    geografie: 'Westerlo',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1258',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Aalst',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-04-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1259',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Oudenaarde',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1260',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Oudenaarde',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1261',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Ronse',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1262',
    geannuleerd: false,
    instellings_nummer: '117077',
    instellings_naam: 'PCVO Groeipunt',
    geografie: 'Aalst',
    geldigheids_periode: {
      geldig_van: '2018-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1263',
    geannuleerd: false,
    instellings_nummer: '117077',
    instellings_naam: 'PCVO Groeipunt',
    geografie: 'Deinze',
    geldigheids_periode: {
      geldig_van: '2018-09-01',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1264',
    geannuleerd: false,
    instellings_nummer: '117077',
    instellings_naam: 'PCVO Groeipunt',
    geografie: 'Dendermonde',
    geldigheids_periode: {
      geldig_van: '2018-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1265',
    geannuleerd: false,
    instellings_nummer: '117077',
    instellings_naam: 'PCVO Groeipunt',
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '2018-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1266',
    geannuleerd: false,
    instellings_nummer: '117077',
    instellings_naam: 'PCVO Groeipunt',
    geografie: 'Geraardsbergen',
    geldigheids_periode: {
      geldig_van: '2018-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1267',
    geannuleerd: false,
    instellings_nummer: '117077',
    instellings_naam: 'PCVO Groeipunt',
    geografie: 'Ronse',
    geldigheids_periode: {
      geldig_van: '2018-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1268',
    geannuleerd: false,
    instellings_nummer: '117077',
    instellings_naam: 'PCVO Groeipunt',
    geografie: 'Wortegem-Petegem',
    geldigheids_periode: {
      geldig_van: '2018-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '127',
    geannuleerd: false,
    instellings_nummer: '33191',
    instellings_naam: 'CVO Meise-Jette',
    geografie: 'Asse',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1270',
    geannuleerd: false,
    instellings_nummer: '31401',
    instellings_naam: 'CVO LBC-NVK Turnhout',
    geografie: 'Hoogstraten',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1271',
    geannuleerd: false,
    instellings_nummer: '56366',
    instellings_naam: 'CVO COOVI',
    geografie: 'Brussels Hoofdstedelijk Gewest',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1272',
    geannuleerd: false,
    instellings_nummer: '130864',
    instellings_naam: 'GO! CVO VOLT',
    geografie: 'Leuven',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1273',
    geannuleerd: false,
    instellings_nummer: '125468',
    instellings_naam: 'GO! CVO De Oranjerie',
    geografie: 'Leuven',
    geldigheids_periode: {
      geldig_van: '2017-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1274',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-05-18',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1276',
    geannuleerd: false,
    instellings_nummer: '112581',
    instellings_naam: 'GO! CVO Cursa',
    geografie: 'Bilzen',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-05-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1277',
    geannuleerd: false,
    instellings_nummer: '112581',
    instellings_naam: 'GO! CVO Cursa',
    geografie: 'Borgloon',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-05-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1278',
    geannuleerd: false,
    instellings_nummer: '112581',
    instellings_naam: 'GO! CVO Cursa',
    geografie: 'Lanaken',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1279',
    geannuleerd: false,
    instellings_nummer: '112581',
    instellings_naam: 'GO! CVO Cursa',
    geografie: 'Sint-Truiden',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '128',
    geannuleerd: false,
    instellings_nummer: '33233',
    instellings_naam: 'CVO M&T',
    geografie: 'Asse',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1280',
    geannuleerd: false,
    instellings_nummer: '112581',
    instellings_naam: 'GO! CVO Cursa',
    geografie: 'Tongeren',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-05-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1282',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Hoeilaart',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-06-01',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1283',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Kortenberg',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-06-01',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1284',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Kraainem',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-06-01',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1285',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Overijse',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-06-01',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1286',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Tervuren',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-01-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1287',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Wezembeek-Oppem',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-06-01',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1288',
    geannuleerd: false,
    instellings_nummer: '117721',
    instellings_naam: 'CVO Vitant',
    geografie: 'Kapellen',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-06-13',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1289',
    geannuleerd: false,
    instellings_nummer: '130765',
    instellings_naam: 'PCVO Limburg',
    geografie: 'Bilzen',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-06-13',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '129',
    geannuleerd: false,
    instellings_nummer: '115451',
    instellings_naam: 'Provinciaal CVO Meetjesland',
    geografie: 'Assenede',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '1290',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Halen',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-06-13',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1292',
    geannuleerd: false,
    instellings_nummer: '32383',
    instellings_naam: 'CVO Semper',
    geografie: 'Meise',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-06-13',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1294',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Ieper',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-06-14',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1296',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Kampenhout',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-08-27',
      laatst_gewijzigd_door: '93100741738'
    }
  },
  {
    code: '1298',
    geannuleerd: false,
    instellings_nummer: '126607',
    instellings_naam: 'CBE Ligo Gent-Meetjesland-Leieland',
    geografie: 'Destelbergen',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-09-12',
      laatst_gewijzigd_door: '93100741738'
    }
  },
  {
    code: '130',
    geannuleerd: false,
    instellings_nummer: '42051',
    instellings_naam: 'GO! CVO Avelgem/Harelbeke',
    geografie: 'Avelgem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '1300',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Aalter (fusie)',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1301',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Deinze (fusie)',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1302',
    geannuleerd: false,
    instellings_nummer: '117077',
    instellings_naam: 'PCVO Groeipunt',
    geografie: 'Deinze (fusie)',
    geldigheids_periode: {
      geldig_van: '2018-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1303',
    geannuleerd: false,
    instellings_nummer: '131888',
    instellings_naam: 'CVO Groeipunt (131888)',
    geografie: 'Deinze (fusie)',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-12-05',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1304',
    geannuleerd: false,
    instellings_nummer: '110536',
    instellings_naam: 'GO! CVO De Vlaamse Ardennen',
    geografie: 'Kruisem',
    geldigheids_periode: {
      geldig_van: '2018-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1305',
    geannuleerd: false,
    instellings_nummer: '131375',
    instellings_naam: 'CVO Qrios Noord',
    geografie: 'Pelt',
    geldigheids_periode: {
      geldig_van: '2018-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1306',
    geannuleerd: false,
    instellings_nummer: '44339',
    instellings_naam: 'GO! CVO Lino',
    geografie: 'Pelt',
    geldigheids_periode: {
      geldig_van: '2018-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1307',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Pelt',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-12-05',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1308',
    geannuleerd: false,
    instellings_nummer: '46532',
    instellings_naam: 'GO! CVO Rivierenland',
    geografie: 'Puurs-Sint-Amands',
    geldigheids_periode: {
      geldig_van: '2018-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1309',
    geannuleerd: false,
    instellings_nummer: '126607',
    instellings_naam: 'CBE Ligo Gent-Meetjesland-Leieland',
    geografie: 'Deinze (fusie)',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-12-05',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '131',
    geannuleerd: false,
    instellings_nummer: '48595',
    instellings_naam: 'CVO MIRAS 48595',
    geografie: 'Avelgem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '1310',
    geannuleerd: false,
    instellings_nummer: '126607',
    instellings_naam: 'CBE Ligo Gent-Meetjesland-Leieland',
    geografie: 'Aalter (fusie)',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-12-05',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1311',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Oudsbergen',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-12-05',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1312',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Pelt',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-12-05',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1313',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Puurs-Sint-Amands',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-12-05',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1314',
    geannuleerd: false,
    instellings_nummer: '126607',
    instellings_naam: 'CBE Ligo Gent-Meetjesland-Leieland',
    geografie: 'Lievegem',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-12-10',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1315',
    geannuleerd: false,
    instellings_nummer: '126599',
    instellings_naam: 'CBE Ligo Zuid-Oost-Vlaanderen',
    geografie: 'Kruisem',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2018-12-10',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1316',
    geannuleerd: false,
    instellings_nummer: '48512',
    instellings_naam: 'CVO SVG',
    geografie: 'Beernem',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-01-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1317',
    geannuleerd: false,
    instellings_nummer: '48512',
    instellings_naam: 'CVO SVG',
    geografie: 'Oostkamp',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-01-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1318',
    geannuleerd: false,
    instellings_nummer: '48512',
    instellings_naam: 'CVO SVG',
    geografie: 'Torhout',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-01-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '132',
    geannuleerd: false,
    instellings_nummer: '112532',
    instellings_naam: 'GO! CVO Kempen',
    geografie: 'Balen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '1320',
    geannuleerd: false,
    instellings_nummer: '30271',
    instellings_naam: 'CVO HIK',
    geografie: 'Arendonk',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1321',
    geannuleerd: false,
    instellings_nummer: '30271',
    instellings_naam: 'CVO HIK',
    geografie: 'Geel',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-01-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1322',
    geannuleerd: false,
    instellings_nummer: '30271',
    instellings_naam: 'CVO HIK',
    geografie: 'Laakdal',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-01-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1323',
    geannuleerd: false,
    instellings_nummer: '30271',
    instellings_naam: 'CVO HIK',
    geografie: 'Lummen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1324',
    geannuleerd: false,
    instellings_nummer: '30271',
    instellings_naam: 'CVO HIK',
    geografie: 'Mol',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-01-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1325',
    geannuleerd: false,
    instellings_nummer: '30271',
    instellings_naam: 'CVO HIK',
    geografie: 'Tessenderlo',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-01-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1326',
    geannuleerd: false,
    instellings_nummer: '30271',
    instellings_naam: 'CVO HIK',
    geografie: 'Westerlo',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1328',
    geannuleerd: false,
    instellings_nummer: '117721',
    instellings_naam: 'CVO Vitant',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '2019-02-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-01-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1330',
    geannuleerd: false,
    instellings_nummer: '32491',
    instellings_naam: 'CVO Lethas Brussel',
    geografie: 'Brussels Hoofdstedelijk Gewest',
    geldigheids_periode: {
      geldig_van: '2019-02-01',
      geldig_tot: '2020-01-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-02-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1332',
    geannuleerd: false,
    instellings_nummer: '29389',
    instellings_naam: 'Stedelijk CVO Encora 029389',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '2019-02-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-01-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1334',
    geannuleerd: false,
    instellings_nummer: '56366',
    instellings_naam: 'CVO COOVI',
    geografie: 'Vilvoorde',
    geldigheids_periode: {
      geldig_van: '2019-02-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1335',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Anzegem',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-01-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1336',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Dentergem',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-01-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1337',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Harelbeke',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1338',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Menen',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-01-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1339',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Roeselare',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-01-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '134',
    geannuleerd: false,
    instellings_nummer: '117747',
    instellings_naam: 'CVO VTI Brugge',
    geografie: 'Beernem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1340',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Tielt',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-01-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1341',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Waregem',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-01-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1342',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Wielsbeke',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-01-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1344',
    geannuleerd: false,
    instellings_nummer: '132001',
    instellings_naam: 'CVO Creo',
    geografie: 'Deerlijk',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-03-04',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1345',
    geannuleerd: false,
    instellings_nummer: '132001',
    instellings_naam: 'CVO Creo',
    geografie: 'Harelbeke',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-03-04',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1346',
    geannuleerd: false,
    instellings_nummer: '132001',
    instellings_naam: 'CVO Creo',
    geografie: 'Kortrijk',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-03-04',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1347',
    geannuleerd: false,
    instellings_nummer: '132001',
    instellings_naam: 'CVO Creo',
    geografie: 'Kuurne',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-03-04',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1348',
    geannuleerd: false,
    instellings_nummer: '132001',
    instellings_naam: 'CVO Creo',
    geografie: 'Menen',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-03-04',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1349',
    geannuleerd: false,
    instellings_nummer: '132001',
    instellings_naam: 'CVO Creo',
    geografie: 'Wevelgem',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '135',
    geannuleerd: false,
    instellings_nummer: '46524',
    instellings_naam: 'GO! CVO HORITO Turnhout',
    geografie: 'Beerse',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '1350',
    geannuleerd: false,
    instellings_nummer: '32383',
    instellings_naam: 'CVO Semper',
    geografie: 'Asse',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1351',
    geannuleerd: false,
    instellings_nummer: '32383',
    instellings_naam: 'CVO Semper',
    geografie: 'Londerzeel',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-03-06',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1352',
    geannuleerd: false,
    instellings_nummer: '39222',
    instellings_naam: 'PCVO Moderne Talen Hasselt',
    geografie: 'Voeren',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-03-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1353',
    geannuleerd: false,
    instellings_nummer: '39222',
    instellings_naam: 'PCVO Moderne Talen Hasselt',
    geografie: 'Maasmechelen',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-03-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1354',
    geannuleerd: false,
    instellings_nummer: '130765',
    instellings_naam: 'PCVO Limburg',
    geografie: 'Riemst',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-03-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1355',
    geannuleerd: false,
    instellings_nummer: '130765',
    instellings_naam: 'PCVO Limburg',
    geografie: 'Voeren',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-03-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1356',
    geannuleerd: false,
    instellings_nummer: '41285',
    instellings_naam: 'GO! CVO Brussel',
    geografie: 'Brussels Hoofdstedelijk Gewest',
    geldigheids_periode: {
      geldig_van: '2019-09-01',
      geldig_tot: '2020-01-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-02-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1357',
    geannuleerd: false,
    instellings_nummer: '41285',
    instellings_naam: 'GO! CVO Brussel',
    geografie: 'Brussels Hoofdstedelijk Gewest',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-02-21',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1358',
    geannuleerd: false,
    instellings_nummer: '41285',
    instellings_naam: 'GO! CVO Brussel',
    geografie: 'Vilvoorde',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1360',
    geannuleerd: false,
    instellings_nummer: '126516',
    instellings_naam: 'CBE Ligo Antwerpen',
    geografie: 'Wijnegem',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-03-14',
      laatst_gewijzigd_door: '93100741738'
    }
  },
  {
    code: '1362',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Asse',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1363',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Dendermonde',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1364',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Dilbeek',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-01-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1365',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Geraardsbergen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1366',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Dendermonde',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1367',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Liedekerke',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1368',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Merchtem',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1369',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Roosdaal',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '137',
    geannuleerd: false,
    instellings_nummer: '33431',
    instellings_naam: 'GO! GLTT CVO',
    geografie: 'Beersel',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1370',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Ternat',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1372',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Boom',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1373',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Bornem',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2024-05-31',
      laatst_gewijzigd_door: '999999999'
    }
  },
  {
    code: '1374',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Brasschaat',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1375',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Edegem',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01',
      geldig_tot: '2021-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1376',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Essen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2024-05-31',
      laatst_gewijzigd_door: '999999999'
    }
  },
  {
    code: '1377',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Kalmthout',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1378',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Kapellen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1379',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Kontich',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1380',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Lier',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1381',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Lint',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1382',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Mechelen',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1383',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Mortsel',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1384',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Niel',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2024-05-31',
      laatst_gewijzigd_door: '999999999'
    }
  },
  {
    code: '1385',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Nijlen',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1386',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Puurs-Sint-Amands',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1387',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Rumst',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1388',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Schoten',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1389',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Stabroek',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '139',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Beersel',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1390',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Wijnegem',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1391',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Willebroek',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1392',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Zoersel',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1394',
    geannuleerd: true,
    instellings_nummer: '33431',
    instellings_naam: 'GO! GLTT CVO',
    geografie: 'Brussels Hoofdstedelijk Gewest',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1395',
    geannuleerd: true,
    instellings_nummer: '33431',
    instellings_naam: 'GO! GLTT CVO',
    geografie: 'Liedekerke',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1396',
    geannuleerd: true,
    instellings_nummer: '33431',
    instellings_naam: 'GO! GLTT CVO',
    geografie: 'Tremelo',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1397',
    geannuleerd: true,
    instellings_nummer: '33431',
    instellings_naam: 'GO! GLTT CVO',
    geografie: 'Vilvoorde',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1398',
    geannuleerd: false,
    instellings_nummer: '113712',
    instellings_naam: 'GO! hét CVO Pro',
    geografie: 'Liedekerke',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1399',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Tremelo',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '14',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Vilvoorde',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '140',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Begijnendijk',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1400',
    geannuleerd: false,
    instellings_nummer: '130864',
    instellings_naam: 'GO! CVO VOLT',
    geografie: 'Aarschot',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1401',
    geannuleerd: false,
    instellings_nummer: '130864',
    instellings_naam: 'GO! CVO VOLT',
    geografie: 'Begijnendijk',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1402',
    geannuleerd: false,
    instellings_nummer: '130864',
    instellings_naam: 'GO! CVO VOLT',
    geografie: 'Boutersem',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1403',
    geannuleerd: false,
    instellings_nummer: '130864',
    instellings_naam: 'GO! CVO VOLT',
    geografie: 'Diest',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1404',
    geannuleerd: false,
    instellings_nummer: '130864',
    instellings_naam: 'GO! CVO VOLT',
    geografie: 'Haacht',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1405',
    geannuleerd: false,
    instellings_nummer: '130864',
    instellings_naam: 'GO! CVO VOLT',
    geografie: 'Herent',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1406',
    geannuleerd: false,
    instellings_nummer: '130864',
    instellings_naam: 'GO! CVO VOLT',
    geografie: 'Holsbeek',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1407',
    geannuleerd: false,
    instellings_nummer: '130864',
    instellings_naam: 'GO! CVO VOLT',
    geografie: 'Lubbeek',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1408',
    geannuleerd: false,
    instellings_nummer: '130864',
    instellings_naam: 'GO! CVO VOLT',
    geografie: 'Rotselaar',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1409',
    geannuleerd: false,
    instellings_nummer: '130864',
    instellings_naam: 'GO! CVO VOLT',
    geografie: 'Scherpenheuvel-Zichem',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1410',
    geannuleerd: false,
    instellings_nummer: '130864',
    instellings_naam: 'GO! CVO VOLT',
    geografie: 'Tielt-Winge',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1411',
    geannuleerd: false,
    instellings_nummer: '130864',
    instellings_naam: 'GO! CVO VOLT',
    geografie: 'Zoutleeuw',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1412',
    geannuleerd: false,
    instellings_nummer: '48462',
    instellings_naam: 'GO! CVO Focus',
    geografie: 'Beveren',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1413',
    geannuleerd: false,
    instellings_nummer: '48462',
    instellings_naam: 'GO! CVO Focus',
    geografie: 'Beveren',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1414',
    geannuleerd: false,
    instellings_nummer: '48462',
    instellings_naam: 'GO! CVO Focus',
    geografie: 'Dendermonde',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1415',
    geannuleerd: false,
    instellings_nummer: '48462',
    instellings_naam: 'GO! CVO Focus',
    geografie: 'Sint-Gillis-Waas',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-01-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1416',
    geannuleerd: false,
    instellings_nummer: '48462',
    instellings_naam: 'GO! CVO Focus',
    geografie: 'Sint-Niklaas',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1417',
    geannuleerd: false,
    instellings_nummer: '48462',
    instellings_naam: 'GO! CVO Focus',
    geografie: 'Temse',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1418',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Aarschot',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-14',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1419',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Bierbeek',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '142',
    geannuleerd: false,
    instellings_nummer: '39834',
    instellings_naam: 'CVO LBC-NVK Beringen',
    geografie: 'Beringen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1420',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Diest',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-14',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1421',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Leuven',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-14',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1422',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Hamont-Achel',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-14',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1423',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Houthalen-Helchteren',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-14',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1424',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Kortenberg',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-14',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1425',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Leuven',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-14',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1426',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Maaseik',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-14',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1427',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Peer',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-14',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1428',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Tienen',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-14',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '143',
    geannuleerd: false,
    instellings_nummer: '44339',
    instellings_naam: 'GO! CVO Lino',
    geografie: 'Beringen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1430',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Bredene',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1431',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Ruiselede',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-17',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1432',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Harelbeke',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-17',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1433',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Izegem',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-17',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1434',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Koekelare',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1435',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Kortrijk',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-01-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1436',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Ledegem',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-17',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1437',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Lendelede',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1438',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Menen',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-17',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1439',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Meulebeke',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-17',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1440',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Moorslede',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-17',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1441',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Oostende',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-17',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1442',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Roeselare',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-17',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1443',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Ruiselede',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1444',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Tielt',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-17',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1445',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Torhout',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-17',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1446',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Waregem',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1447',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Wervik',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-17',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1448',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Wevelgem',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-17',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1449',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Wingene',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-17',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1450',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Zwevegem',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-17',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1452',
    geannuleerd: false,
    instellings_nummer: '112581',
    instellings_naam: 'GO! CVO Cursa',
    geografie: 'Hasselt',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-29',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1453',
    geannuleerd: false,
    instellings_nummer: '112581',
    instellings_naam: 'GO! CVO Cursa',
    geografie: 'Hasselt',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1454',
    geannuleerd: false,
    instellings_nummer: '112581',
    instellings_naam: 'GO! CVO Cursa',
    geografie: 'Herk-de-Stad',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-29',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1455',
    geannuleerd: false,
    instellings_nummer: '112581',
    instellings_naam: 'GO! CVO Cursa',
    geografie: 'Zonhoven',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-05-29',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1456',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-18',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1457',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-18',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1458',
    geannuleerd: false,
    instellings_nummer: '32383',
    instellings_naam: 'CVO Semper',
    geografie: 'Brussels Hoofdstedelijk Gewest',
    geldigheids_periode: {
      geldig_van: '2019-09-01',
      geldig_tot: '2020-01-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-02-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1459',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Zutendaal',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-18',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '146',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Beveren',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1460',
    geannuleerd: false,
    instellings_nummer: '37119',
    instellings_naam: 'PCVO VSPW',
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '2019-09-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1461',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-18',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1462',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Huldenberg',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-18',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1464',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Tessenderlo',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-21',
      laatst_gewijzigd_door: '93100741738'
    }
  },
  {
    code: '1465',
    geannuleerd: false,
    instellings_nummer: '126581',
    instellings_naam: 'CBE Ligo Waas & Dender',
    geografie: 'Stekene',
    geldigheids_periode: {
      geldig_van: '2018-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-21',
      laatst_gewijzigd_door: '93100741738'
    }
  },
  {
    code: '1466',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Sint-Genesius-Rode',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-08-27',
      laatst_gewijzigd_door: '88061035967'
    }
  },
  {
    code: '1468',
    geannuleerd: false,
    instellings_nummer: '126573',
    instellings_naam: 'CBE Ligo Limburg Zuid',
    geografie: 'Halen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2019-09-05',
      laatst_gewijzigd_door: '88061035967'
    }
  },
  {
    code: '147',
    geannuleerd: false,
    instellings_nummer: '48421',
    instellings_naam: 'GO! CVO JANITOR',
    geografie: 'Beveren',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1470',
    geannuleerd: false,
    instellings_nummer: '41285',
    instellings_naam: 'GO! CVO Brussel',
    geografie: 'Oud-Heverlee',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2019-09-01',
      geldig_tot: '2022-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-08-31',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1472',
    geannuleerd: false,
    instellings_nummer: '126516',
    instellings_naam: 'CBE Ligo Antwerpen',
    geografie: 'Mortsel',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-01-14',
      laatst_gewijzigd_door: '88061035967'
    }
  },
  {
    code: '1474',
    geannuleerd: false,
    instellings_nummer: '131888',
    instellings_naam: 'CVO Groeipunt (131888)',
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-01-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1475',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Aalst',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-01-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1476',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Deinze (fusie)',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-01-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1477',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Gent',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1478',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Geraardsbergen',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-01-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1479',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Oudenaarde',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '148',
    geannuleerd: false,
    instellings_nummer: '115469',
    instellings_naam: 'Provinciaal CVO Waas en Durme',
    geografie: 'Beveren',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1480',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Ninove',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1481',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Oudenaarde',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-01-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1482',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Ronse',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-01-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1483',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Wortegem-Petegem',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-01-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1484',
    geannuleerd: false,
    instellings_nummer: '131482',
    instellings_naam: 'CVO Groeipunt (131482)',
    geografie: 'Zottegem',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-01-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1486',
    geannuleerd: false,
    instellings_nummer: '37119',
    instellings_naam: 'PCVO VSPW',
    geografie: 'Eeklo',
    geldigheids_periode: {
      geldig_van: '2020-02-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1488',
    geannuleerd: false,
    instellings_nummer: '126573',
    instellings_naam: 'CBE Ligo Limburg Zuid',
    geografie: 'Borgloon',
    geldigheids_periode: {
      geldig_van: '2019-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-02-10',
      laatst_gewijzigd_door: '83083006657'
    }
  },
  {
    code: '149',
    geannuleerd: false,
    instellings_nummer: '33043',
    instellings_naam: 'HIRL CVO',
    geografie: 'Bierbeek',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2012-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2013-02-07',
      laatst_gewijzigd_door: '80090108576'
    }
  },
  {
    code: '1490',
    geannuleerd: false,
    instellings_nummer: '113712',
    instellings_naam: 'GO! hét CVO Pro',
    geografie: 'Avelgem',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-02-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1491',
    geannuleerd: false,
    instellings_nummer: '113712',
    instellings_naam: 'GO! hét CVO Pro',
    geografie: 'Oudenaarde',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-02-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1492',
    geannuleerd: false,
    instellings_nummer: '113712',
    instellings_naam: 'GO! hét CVO Pro',
    geografie: 'Harelbeke',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1493',
    geannuleerd: false,
    instellings_nummer: '113712',
    instellings_naam: 'GO! hét CVO Pro',
    geografie: 'Ingelmunster',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-02-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1494',
    geannuleerd: false,
    instellings_nummer: '113712',
    instellings_naam: 'GO! hét CVO Pro',
    geografie: 'Kruisem',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-02-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1495',
    geannuleerd: false,
    instellings_nummer: '113712',
    instellings_naam: 'GO! hét CVO Pro',
    geografie: 'Oudenaarde',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1496',
    geannuleerd: false,
    instellings_nummer: '113712',
    instellings_naam: 'GO! hét CVO Pro',
    geografie: 'Ronse',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-02-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1497',
    geannuleerd: false,
    instellings_nummer: '113712',
    instellings_naam: 'GO! hét CVO Pro',
    geografie: 'Spiere-Helkijn',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-02-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1498',
    geannuleerd: false,
    instellings_nummer: '113712',
    instellings_naam: 'GO! hét CVO Pro',
    geografie: 'Waregem',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '15',
    geannuleerd: false,
    instellings_nummer: '40139',
    instellings_naam: 'PCVO Talen - Informatica Voeren',
    geografie: 'Voeren',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '150',
    geannuleerd: false,
    instellings_nummer: '117168',
    instellings_naam: 'Vrij Tech. Inst. Leuven - CVO',
    geografie: 'Bierbeek',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1500',
    geannuleerd: false,
    instellings_nummer: '31104',
    instellings_naam: 'CVO LBC-NVK L 31104',
    geografie: 'Beringen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1501',
    geannuleerd: false,
    instellings_nummer: '31104',
    instellings_naam: 'CVO LBC-NVK L 31104',
    geografie: 'Beveren',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01',
      geldig_tot: '2021-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1502',
    geannuleerd: false,
    instellings_nummer: '31104',
    instellings_naam: 'CVO LBC-NVK L 31104',
    geografie: 'Brasschaat',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1503',
    geannuleerd: false,
    instellings_nummer: '31104',
    instellings_naam: 'CVO LBC-NVK L 31104',
    geografie: 'Mechelen',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-04-02',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1504',
    geannuleerd: false,
    instellings_nummer: '31104',
    instellings_naam: 'CVO LBC-NVK L 31104',
    geografie: 'Herk-de-Stad',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01',
      geldig_tot: '2021-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1505',
    geannuleerd: false,
    instellings_nummer: '31104',
    instellings_naam: 'CVO LBC-NVK L 31104',
    geografie: 'Hoogstraten',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1506',
    geannuleerd: false,
    instellings_nummer: '31104',
    instellings_naam: 'CVO LBC-NVK L 31104',
    geografie: 'Kalmthout',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01',
      geldig_tot: '2021-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1507',
    geannuleerd: false,
    instellings_nummer: '31104',
    instellings_naam: 'CVO LBC-NVK L 31104',
    geografie: 'Leopoldsburg',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-04-02',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1508',
    geannuleerd: false,
    instellings_nummer: '31104',
    instellings_naam: 'CVO LBC-NVK L 31104',
    geografie: 'Lummen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01',
      geldig_tot: '2021-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1509',
    geannuleerd: false,
    instellings_nummer: '31104',
    instellings_naam: 'CVO LBC-NVK L 31104',
    geografie: 'Mechelen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '151',
    geannuleerd: false,
    instellings_nummer: '112541',
    instellings_naam: 'GO! CVO Zuid-Limburg',
    geografie: 'Bilzen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '1510',
    geannuleerd: false,
    instellings_nummer: '31104',
    instellings_naam: 'CVO LBC-NVK L 31104',
    geografie: 'Sint-Katelijne-Waver',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1511',
    geannuleerd: false,
    instellings_nummer: '31104',
    instellings_naam: 'CVO LBC-NVK L 31104',
    geografie: 'Sint-Niklaas',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1512',
    geannuleerd: false,
    instellings_nummer: '31104',
    instellings_naam: 'CVO LBC-NVK L 31104',
    geografie: 'Stekene',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01',
      geldig_tot: '2021-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1513',
    geannuleerd: false,
    instellings_nummer: '31104',
    instellings_naam: 'CVO LBC-NVK L 31104',
    geografie: 'Tessenderlo',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-04-02',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1514',
    geannuleerd: false,
    instellings_nummer: '31104',
    instellings_naam: 'CVO LBC-NVK L 31104',
    geografie: 'Turnhout',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1515',
    geannuleerd: false,
    instellings_nummer: '31104',
    instellings_naam: 'CVO LBC-NVK L 31104',
    geografie: 'Waasmunster',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01',
      geldig_tot: '2021-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1516',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Antwerpen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1517',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Mechelen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1518',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Sint-Katelijne-Waver',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1519',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Brasschaat',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '152',
    geannuleerd: false,
    instellings_nummer: '34066',
    instellings_naam: 'SNT  CVO',
    geografie: 'Blankenberge',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '1520',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Mortsel',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1521',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Hoogstraten',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-04-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1522',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Hoogstraten',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-01-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1523',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Hoogstraten',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1524',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Turnhout',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1525',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Beringen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1526',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Herk-de-Stad',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1527',
    geannuleerd: true,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Leopoldsburg',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-04-06',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1528',
    geannuleerd: true,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Lummen',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-04-06',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1529',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Tessenderlo',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-01-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '153',
    geannuleerd: false,
    instellings_nummer: '53454',
    instellings_naam: 'CVO VIVA West-Vlaanderen vzw',
    geografie: 'Blankenberge',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1530',
    geannuleerd: false,
    instellings_nummer: '28431',
    instellings_naam: 'CVO LBC-NVK B 28431',
    geografie: 'Beringen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1531',
    geannuleerd: false,
    instellings_nummer: '28431',
    instellings_naam: 'CVO LBC-NVK B 28431',
    geografie: 'Beveren',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1532',
    geannuleerd: false,
    instellings_nummer: '28431',
    instellings_naam: 'CVO LBC-NVK B 28431',
    geografie: 'Brasschaat',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1533',
    geannuleerd: false,
    instellings_nummer: '28431',
    instellings_naam: 'CVO LBC-NVK B 28431',
    geografie: 'Hoogstraten',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1534',
    geannuleerd: false,
    instellings_nummer: '28431',
    instellings_naam: 'CVO LBC-NVK B 28431',
    geografie: 'Kalmthout',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01',
      geldig_tot: '2021-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1535',
    geannuleerd: false,
    instellings_nummer: '28431',
    instellings_naam: 'CVO LBC-NVK B 28431',
    geografie: 'Lummen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1536',
    geannuleerd: false,
    instellings_nummer: '28431',
    instellings_naam: 'CVO LBC-NVK B 28431',
    geografie: 'Mechelen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1537',
    geannuleerd: false,
    instellings_nummer: '28431',
    instellings_naam: 'CVO LBC-NVK B 28431',
    geografie: 'Mortsel',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1538',
    geannuleerd: false,
    instellings_nummer: '28431',
    instellings_naam: 'CVO LBC-NVK B 28431',
    geografie: 'Sint-Katelijne-Waver',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1539',
    geannuleerd: false,
    instellings_nummer: '28431',
    instellings_naam: 'CVO LBC-NVK B 28431',
    geografie: 'Stekene',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1540',
    geannuleerd: false,
    instellings_nummer: '28431',
    instellings_naam: 'CVO LBC-NVK B 28431',
    geografie: 'Turnhout',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1542',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Beersel',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-04-08',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1543',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Drogenbos',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-04-08',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1544',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Halle',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1545',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Herne',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-04-08',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1546',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Lennik',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-04-08',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1547',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Linkebeek',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-04-08',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1548',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Sint-Genesius-Rode',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-04-08',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1549',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Sint-Pieters-Leeuw',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-04-08',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '155',
    geannuleerd: false,
    instellings_nummer: '28431',
    instellings_naam: 'CVO LBC-NVK B 28431',
    geografie: 'Boom',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1550',
    geannuleerd: false,
    instellings_nummer: '28431',
    instellings_naam: 'CVO LBC-NVK B 28431',
    geografie: 'Sint-Niklaas',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1551',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Antwerpen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1552',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Brasschaat',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1553',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Destelbergen',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1554',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Gavere',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1555',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Lier',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1556',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Lochristi',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1557',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Moerbeke',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1558',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Wachtebeke',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-04-09',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '156',
    geannuleerd: false,
    instellings_nummer: '46532',
    instellings_naam: 'GO! CVO Rivierenland',
    geografie: 'Boom',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1560',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Blankenberge',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-05-08',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1561',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Brugge',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1562',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Knokke-Heist',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1563',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Maldegem',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-05-08',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1564',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Oostkamp',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01',
      geldig_tot: '2021-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '1566',
    geannuleerd: false,
    instellings_nummer: '112581',
    instellings_naam: 'GO! CVO Cursa',
    geografie: 'Beringen',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-05-11',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1567',
    geannuleerd: false,
    instellings_nummer: '112581',
    instellings_naam: 'GO! CVO Cursa',
    geografie: 'Ham',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-05-11',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1568',
    geannuleerd: false,
    instellings_nummer: '112581',
    instellings_naam: 'GO! CVO Cursa',
    geografie: 'Leopoldsburg',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-05-11',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1569',
    geannuleerd: false,
    instellings_nummer: '112581',
    instellings_naam: 'GO! CVO Cursa',
    geografie: 'Lommel',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '157',
    geannuleerd: false,
    instellings_nummer: '48934',
    instellings_naam: 'GO! CVO VIVA Antwerpen',
    geografie: 'Boom',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '1570',
    geannuleerd: false,
    instellings_nummer: '112581',
    instellings_naam: 'GO! CVO Cursa',
    geografie: 'Pelt',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-05-11',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1572',
    geannuleerd: false,
    instellings_nummer: '131318',
    instellings_naam: 'CVO Gent',
    geografie: 'Evergem',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-02-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1573',
    geannuleerd: false,
    instellings_nummer: '131318',
    instellings_naam: 'CVO Gent',
    geografie: 'Melle',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1574',
    geannuleerd: false,
    instellings_nummer: '131318',
    instellings_naam: 'CVO Gent',
    geografie: 'Merelbeke',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-02-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1576',
    geannuleerd: false,
    instellings_nummer: '113712',
    instellings_naam: 'GO! hét CVO Pro',
    geografie: 'Zottegem',
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-04',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1578',
    geannuleerd: true,
    instellings_nummer: '47837',
    instellings_naam: 'CVO ISBO',
    geografie: 'Eeklo',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-29',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1579',
    geannuleerd: true,
    instellings_nummer: '112615',
    instellings_naam: 'GO! CVO Altus',
    geografie: 'Brugge',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-29',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '158',
    geannuleerd: false,
    instellings_nummer: '117721',
    instellings_naam: 'CVO Vitant',
    geografie: 'Boom',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-01-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1580',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Brugge',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-29',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '1582',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Zemst',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '67091434130'
    }
  },
  {
    code: '1583',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Kalmthout',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2021-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1584',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Lummen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2021-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1586',
    geannuleerd: true,
    instellings_nummer: '28431',
    instellings_naam: 'CVO LBC-NVK B 28431',
    geografie: 'Herk-de-Stad',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2021-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-05',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1587',
    geannuleerd: true,
    instellings_nummer: '28431',
    instellings_naam: 'CVO LBC-NVK B 28431',
    geografie: 'Waasmunster',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2021-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-05',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1588',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Londerzeel',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2020-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-27',
      laatst_gewijzigd_door: '67091434130'
    }
  },
  {
    code: '159',
    geannuleerd: false,
    instellings_nummer: '112541',
    instellings_naam: 'GO! CVO Zuid-Limburg',
    geografie: 'Borgloon',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '1590',
    geannuleerd: false,
    instellings_nummer: '117721',
    instellings_naam: 'CVO Vitant',
    geografie: 'Brasschaat',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2021-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1592',
    geannuleerd: false,
    instellings_nummer: '137828',
    instellings_naam: 'GO! CVO Scala',
    geografie: 'Gistel',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2021-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1594',
    geannuleerd: false,
    instellings_nummer: '131871',
    instellings_naam: 'CVO Qrios',
    geografie: 'Zonhoven',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2021-09-01',
      geldig_tot: '2022-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-07-13',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1596',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Vosselaar',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2021-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-08-30',
      laatst_gewijzigd_door: '67091434130'
    }
  },
  {
    code: '1598',
    geannuleerd: false,
    instellings_nummer: '126607',
    instellings_naam: 'CBE Ligo Gent-Meetjesland-Leieland',
    geografie: 'Lochristi',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2021-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-28',
      laatst_gewijzigd_door: '67091434130'
    }
  },
  {
    code: '16',
    geannuleerd: false,
    instellings_nummer: '47654',
    instellings_naam: 'CVO Hoger Instituut der Kempen',
    geografie: 'Vorselaar',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '160',
    geannuleerd: false,
    instellings_nummer: '46532',
    instellings_naam: 'GO! CVO Rivierenland',
    geografie: 'Bornem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1600',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Antwerpen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2022-02-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-01-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1602',
    geannuleerd: false,
    instellings_nummer: '126599',
    instellings_naam: 'CBE Ligo Zuid-Oost-Vlaanderen',
    geografie: 'Erpe-Mere',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2021-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-02-09',
      laatst_gewijzigd_door: '74102411711'
    }
  },
  {
    code: '1604',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Brugge',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2022-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-02-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1605',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Beernem',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2022-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-02-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1606',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Oostkamp',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2022-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-02-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1607',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Torhout',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2022-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-02-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1609',
    geannuleerd: false,
    instellings_nummer: '129701',
    instellings_naam: 'CVO test',
    geografie: 'Aalst',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2021-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-12-20',
      laatst_gewijzigd_door: '999999999'
    }
  },
  {
    code: '161',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Boutersem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '1611',
    geannuleerd: false,
    instellings_nummer: '34066',
    instellings_naam: 'SNT  CVO',
    geografie: 'Lichtervelde',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '2023-01-01'
    },
    audit: {
      laatst_gewijzigd_op: '2023-12-07',
      laatst_gewijzigd_door: '999999999'
    }
  },
  {
    code: '165',
    geannuleerd: false,
    instellings_nummer: '30891',
    instellings_naam: 'CVO LBC-NVK Berchem-Brasschaat',
    geografie: 'Brasschaat',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '166',
    geannuleerd: false,
    instellings_nummer: '46482',
    instellings_naam: 'GO! CVO IVORAN Kapellen',
    geografie: 'Brasschaat',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '168',
    geannuleerd: false,
    instellings_nummer: '48934',
    instellings_naam: 'GO! CVO VIVA Antwerpen',
    geografie: 'Brasschaat',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '169',
    geannuleerd: false,
    instellings_nummer: '41129',
    instellings_naam: 'GO! CVO EduKempen',
    geografie: 'Brecht',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '17',
    geannuleerd: false,
    instellings_nummer: '41285',
    instellings_naam: 'GO! CVO Brussel',
    geografie: 'Brussels Hoofdstedelijk Gewest',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '170',
    geannuleerd: false,
    instellings_nummer: '48637',
    instellings_naam: 'GO! CVO De Avondschool Oostende',
    geografie: 'Bredene',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '172',
    geannuleerd: false,
    instellings_nummer: '112581',
    instellings_naam: 'GO! CVO Cursa',
    geografie: 'Bree',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '173',
    geannuleerd: false,
    instellings_nummer: '34066',
    instellings_naam: 'SNT  CVO',
    geografie: 'Brugge',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '174',
    geannuleerd: false,
    instellings_nummer: '34091',
    instellings_naam: 'CVO Sint-Godelieve',
    geografie: 'Brugge',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '176',
    geannuleerd: false,
    instellings_nummer: '48512',
    instellings_naam: 'CVO SVG',
    geografie: 'Brugge',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '177',
    geannuleerd: false,
    instellings_nummer: '53454',
    instellings_naam: 'CVO VIVA West-Vlaanderen vzw',
    geografie: 'Brugge',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '178',
    geannuleerd: false,
    instellings_nummer: '112615',
    instellings_naam: 'GO! CVO Altus',
    geografie: 'Brugge',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '179',
    geannuleerd: false,
    instellings_nummer: '117747',
    instellings_naam: 'CVO VTI Brugge',
    geografie: 'Brugge',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '18',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Waasmunster',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '180',
    geannuleerd: false,
    instellings_nummer: '32367',
    instellings_naam: 'CVO -  Hogere Leergang. Fisc. Soc. Wetenschap',
    geografie: 'Brussels Hoofdstedelijk Gewest',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '184',
    geannuleerd: false,
    instellings_nummer: '33498',
    instellings_naam: 'CVO Strombeek - Grimbergen',
    geografie: 'Brussels Hoofdstedelijk Gewest',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '187',
    geannuleerd: false,
    instellings_nummer: '115477',
    instellings_naam: 'Provinciaal CVO Scheldeland',
    geografie: 'Buggenhout',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '189',
    geannuleerd: false,
    instellings_nummer: '48488',
    instellings_naam: 'GO! CVO Cervo',
    geografie: 'De Panne',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '19',
    geannuleerd: false,
    instellings_nummer: '34901',
    instellings_naam: 'CVO MIRAS 34901',
    geografie: 'Waregem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '190',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'De Panne',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '192',
    geannuleerd: false,
    instellings_nummer: '112516',
    instellings_naam: 'GO! CVO Panta Rhei',
    geografie: 'De Pinte',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '193',
    geannuleerd: false,
    instellings_nummer: '48901',
    instellings_naam: 'CVO Creo 48901',
    geografie: 'Deerlijk',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '194',
    geannuleerd: false,
    instellings_nummer: '105353',
    instellings_naam: 'PCVO VIVA',
    geografie: 'Deinze',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '196',
    geannuleerd: false,
    instellings_nummer: '112516',
    instellings_naam: 'GO! CVO Panta Rhei',
    geografie: 'Deinze',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '197',
    geannuleerd: false,
    instellings_nummer: '115451',
    instellings_naam: 'Provinciaal CVO Meetjesland',
    geografie: 'Deinze',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '198',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Deinze',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '2',
    geannuleerd: false,
    instellings_nummer: '117747',
    instellings_naam: 'CVO VTI Brugge',
    geografie: 'Torhout',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '20',
    geannuleerd: false,
    instellings_nummer: '48595',
    instellings_naam: 'CVO MIRAS 48595',
    geografie: 'Waregem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '200',
    geannuleerd: false,
    instellings_nummer: '113712',
    instellings_naam: 'GO! hét CVO Pro',
    geografie: 'Denderleeuw',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '202',
    geannuleerd: false,
    instellings_nummer: '35972',
    instellings_naam: 'CVO Kisp - VTH Aalst',
    geografie: 'Dendermonde',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '203',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Dendermonde',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '204',
    geannuleerd: false,
    instellings_nummer: '48421',
    instellings_naam: 'GO! CVO JANITOR',
    geografie: 'Dendermonde',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '205',
    geannuleerd: false,
    instellings_nummer: '48462',
    instellings_naam: 'GO! CVO Focus',
    geografie: 'Dendermonde',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '206',
    geannuleerd: false,
    instellings_nummer: '105353',
    instellings_naam: 'PCVO VIVA',
    geografie: 'Dendermonde',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '207',
    geannuleerd: false,
    instellings_nummer: '115477',
    instellings_naam: 'Provinciaal CVO Scheldeland',
    geografie: 'Dendermonde',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '208',
    geannuleerd: false,
    instellings_nummer: '47837',
    instellings_naam: 'CVO ISBO',
    geografie: 'Destelbergen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '21',
    geannuleerd: false,
    instellings_nummer: '48901',
    instellings_naam: 'CVO Creo 48901',
    geografie: 'Waregem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '210',
    geannuleerd: false,
    instellings_nummer: '39081',
    instellings_naam: 'PCVO Handel Hasselt',
    geografie: 'Diepenbeek',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2014-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2014-11-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '211',
    geannuleerd: false,
    instellings_nummer: '39164',
    instellings_naam: 'CVO LIMLO',
    geografie: 'Diepenbeek',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '214',
    geannuleerd: false,
    instellings_nummer: '31898',
    instellings_naam: 'CVO Hageland - Aarschot',
    geografie: 'Diest',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '215',
    geannuleerd: false,
    instellings_nummer: '33043',
    instellings_naam: 'HIRL CVO',
    geografie: 'Diest',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2012-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2013-02-07',
      laatst_gewijzigd_door: '80090108576'
    }
  },
  {
    code: '216',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Diest',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '218',
    geannuleerd: false,
    instellings_nummer: '125468',
    instellings_naam: 'GO! CVO De Oranjerie',
    geografie: 'Diest',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '219',
    geannuleerd: false,
    instellings_nummer: '48488',
    instellings_naam: 'GO! CVO Cervo',
    geografie: 'Diksmuide',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '22',
    geannuleerd: false,
    instellings_nummer: '53454',
    instellings_naam: 'CVO VIVA West-Vlaanderen vzw',
    geografie: 'Waregem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '220',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Diksmuide',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '221',
    geannuleerd: false,
    instellings_nummer: '53454',
    instellings_naam: 'CVO VIVA West-Vlaanderen vzw',
    geografie: 'Diksmuide',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '223',
    geannuleerd: false,
    instellings_nummer: '33233',
    instellings_naam: 'CVO M&T',
    geografie: 'Dilbeek',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '224',
    geannuleerd: false,
    instellings_nummer: '33431',
    instellings_naam: 'GO! GLTT CVO',
    geografie: 'Dilbeek',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '226',
    geannuleerd: false,
    instellings_nummer: '112342',
    instellings_naam: 'PCVO  Maasland',
    geografie: 'Dilsen-Stokkem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2014-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2014-11-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '227',
    geannuleerd: false,
    instellings_nummer: '33431',
    instellings_naam: 'GO! GLTT CVO',
    geografie: 'Drogenbos',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '228',
    geannuleerd: false,
    instellings_nummer: '48934',
    instellings_naam: 'GO! CVO VIVA Antwerpen',
    geografie: 'Edegem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '23',
    geannuleerd: false,
    instellings_nummer: '110536',
    instellings_naam: 'GO! CVO De Vlaamse Ardennen',
    geografie: 'Waregem',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-04-13',
      laatst_gewijzigd_door: '88061035967'
    }
  },
  {
    code: '230',
    geannuleerd: false,
    instellings_nummer: '115451',
    instellings_naam: 'Provinciaal CVO Meetjesland',
    geografie: 'Eeklo',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '231',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Eeklo',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '235',
    geannuleerd: false,
    instellings_nummer: '46482',
    instellings_naam: 'GO! CVO IVORAN Kapellen',
    geografie: 'Essen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '236',
    geannuleerd: false,
    instellings_nummer: '48934',
    instellings_naam: 'GO! CVO VIVA Antwerpen',
    geografie: 'Essen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '24',
    geannuleerd: false,
    instellings_nummer: '32383',
    instellings_naam: 'CVO Semper',
    geografie: 'Brussels Hoofdstedelijk Gewest',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '241',
    geannuleerd: false,
    instellings_nummer: '112516',
    instellings_naam: 'GO! CVO Panta Rhei',
    geografie: 'Evergem',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2023-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '243',
    geannuleerd: false,
    instellings_nummer: '47837',
    instellings_naam: 'CVO ISBO',
    geografie: 'Gavere',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '245',
    geannuleerd: false,
    instellings_nummer: '47654',
    instellings_naam: 'CVO Hoger Instituut der Kempen',
    geografie: 'Geel',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '246',
    geannuleerd: false,
    instellings_nummer: '54486',
    instellings_naam: 'CVO Technisch Instituut Sint-Jozef',
    geografie: 'Geel',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '247',
    geannuleerd: false,
    instellings_nummer: '112532',
    instellings_naam: 'GO! CVO Kempen',
    geografie: 'Geel',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '248',
    geannuleerd: false,
    instellings_nummer: '38984',
    instellings_naam: 'CVO Vrije Leergangen Limburg',
    geografie: 'Genk',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '250',
    geannuleerd: false,
    instellings_nummer: '112581',
    instellings_naam: 'GO! CVO Cursa',
    geografie: 'Genk',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '251',
    geannuleerd: false,
    instellings_nummer: '36657',
    instellings_naam: 'CVO De Bargie',
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '252',
    geannuleerd: false,
    instellings_nummer: '37119',
    instellings_naam: 'PCVO VSPW',
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '254',
    geannuleerd: false,
    instellings_nummer: '47837',
    instellings_naam: 'CVO ISBO',
    geografie: 'Gent',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '255',
    geannuleerd: false,
    instellings_nummer: '48512',
    instellings_naam: 'CVO SVG',
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '257',
    geannuleerd: false,
    instellings_nummer: '105353',
    instellings_naam: 'PCVO VIVA',
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '258',
    geannuleerd: false,
    instellings_nummer: '105361',
    instellings_naam: 'CVO Leerdorp',
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '261',
    geannuleerd: false,
    instellings_nummer: '112516',
    instellings_naam: 'GO! CVO Panta Rhei',
    geografie: 'Gent',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2023-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '263',
    geannuleerd: false,
    instellings_nummer: '115451',
    instellings_naam: 'Provinciaal CVO Meetjesland',
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '264',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Gent',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '265',
    geannuleerd: false,
    instellings_nummer: '126508',
    instellings_naam: "'het Perspectief'Provinciaal CVO",
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '266',
    geannuleerd: false,
    instellings_nummer: '36574',
    instellings_naam: 'Technisch Instituut Sint-Jozef CVO',
    geografie: 'Geraardsbergen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '267',
    geannuleerd: false,
    instellings_nummer: '105353',
    instellings_naam: 'PCVO VIVA',
    geografie: 'Geraardsbergen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '27',
    geannuleerd: false,
    instellings_nummer: '33191',
    instellings_naam: 'CVO Meise-Jette',
    geografie: 'Wemmel',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '270',
    geannuleerd: false,
    instellings_nummer: '113712',
    instellings_naam: 'GO! hét CVO Pro',
    geografie: 'Geraardsbergen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '271',
    geannuleerd: false,
    instellings_nummer: '112698',
    instellings_naam: 'GO! CVO Leuven/Landen',
    geografie: 'Gingelom',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2014-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-03-11',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '272',
    geannuleerd: false,
    instellings_nummer: '34066',
    instellings_naam: 'SNT  CVO',
    geografie: 'Gistel',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '273',
    geannuleerd: false,
    instellings_nummer: '48488',
    instellings_naam: 'GO! CVO Cervo',
    geografie: 'Gistel',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '274',
    geannuleerd: false,
    instellings_nummer: '33498',
    instellings_naam: 'CVO Strombeek - Grimbergen',
    geografie: 'Grimbergen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '277',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Haacht',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '278',
    geannuleerd: false,
    instellings_nummer: '125468',
    instellings_naam: 'GO! CVO De Oranjerie',
    geografie: 'Haacht',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '279',
    geannuleerd: false,
    instellings_nummer: '33431',
    instellings_naam: 'GO! GLTT CVO',
    geografie: 'Halle',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '28',
    geannuleerd: false,
    instellings_nummer: '33498',
    instellings_naam: 'CVO Strombeek - Grimbergen',
    geografie: 'Wemmel',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '280',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Halle',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '281',
    geannuleerd: false,
    instellings_nummer: '39834',
    instellings_naam: 'CVO LBC-NVK Beringen',
    geografie: 'Ham',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '282',
    geannuleerd: false,
    instellings_nummer: '44339',
    instellings_naam: 'GO! CVO Lino',
    geografie: 'Ham',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '284',
    geannuleerd: false,
    instellings_nummer: '48462',
    instellings_naam: 'GO! CVO Focus',
    geografie: 'Hamme',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '285',
    geannuleerd: false,
    instellings_nummer: '115469',
    instellings_naam: 'Provinciaal CVO Waas en Durme',
    geografie: 'Hamme',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '286',
    geannuleerd: false,
    instellings_nummer: '39792',
    instellings_naam: 'Vrij CVO Noord-Limburg',
    geografie: 'Hamont-Achel',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '287',
    geannuleerd: false,
    instellings_nummer: '34884',
    instellings_naam: 'CVO MIRAS 34884',
    geografie: 'Harelbeke',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '288',
    geannuleerd: false,
    instellings_nummer: '42051',
    instellings_naam: 'GO! CVO Avelgem/Harelbeke',
    geografie: 'Harelbeke',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '289',
    geannuleerd: false,
    instellings_nummer: '48901',
    instellings_naam: 'CVO Creo 48901',
    geografie: 'Harelbeke',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '290',
    geannuleerd: false,
    instellings_nummer: '53454',
    instellings_naam: 'CVO VIVA West-Vlaanderen vzw',
    geografie: 'Harelbeke',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '291',
    geannuleerd: false,
    instellings_nummer: '39081',
    instellings_naam: 'PCVO Handel Hasselt',
    geografie: 'Hasselt',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2014-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2014-11-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '292',
    geannuleerd: false,
    instellings_nummer: '39222',
    instellings_naam: 'PCVO Moderne Talen Hasselt',
    geografie: 'Hasselt',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '293',
    geannuleerd: false,
    instellings_nummer: '39231',
    instellings_naam: 'CVO VSPW - Hasselt',
    geografie: 'Hasselt',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '294',
    geannuleerd: false,
    instellings_nummer: '112714',
    instellings_naam: 'GO! CVO - STEP',
    geografie: 'Hasselt',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '3',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Tremelo',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '30',
    geannuleerd: false,
    instellings_nummer: '48488',
    instellings_naam: 'GO! CVO Cervo',
    geografie: 'Wervik',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '300',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Heist-op-den-Berg',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '302',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Herent',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '303',
    geannuleerd: false,
    instellings_nummer: '30271',
    instellings_naam: 'CVO HIK',
    geografie: 'Herentals',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-01-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '304',
    geannuleerd: false,
    instellings_nummer: '46524',
    instellings_naam: 'GO! CVO HORITO Turnhout',
    geografie: 'Herentals',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '306',
    geannuleerd: false,
    instellings_nummer: '39231',
    instellings_naam: 'CVO VSPW - Hasselt',
    geografie: 'Herk-de-Stad',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '307',
    geannuleerd: false,
    instellings_nummer: '39834',
    instellings_naam: 'CVO LBC-NVK Beringen',
    geografie: 'Herk-de-Stad',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '308',
    geannuleerd: false,
    instellings_nummer: '112714',
    instellings_naam: 'GO! CVO - STEP',
    geografie: 'Herk-de-Stad',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '309',
    geannuleerd: false,
    instellings_nummer: '33431',
    instellings_naam: 'GO! GLTT CVO',
    geografie: 'Herne',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '31',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Wervik',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '310',
    geannuleerd: false,
    instellings_nummer: '112516',
    instellings_naam: 'GO! CVO Panta Rhei',
    geografie: 'Herzele',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '312',
    geannuleerd: false,
    instellings_nummer: '39371',
    instellings_naam: 'CVO De Verdieping',
    geografie: 'Heusden-Zolder',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '315',
    geannuleerd: false,
    instellings_nummer: '33555',
    instellings_naam: 'CVO Tervuren - Hoeilaart',
    geografie: 'Hoeilaart',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '316',
    geannuleerd: false,
    instellings_nummer: '56366',
    instellings_naam: 'CVO COOVI',
    geografie: 'Hoeilaart',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '317',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Holsbeek',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '318',
    geannuleerd: false,
    instellings_nummer: '117391',
    instellings_naam: 'CVO Roeselare',
    geografie: 'Hooglede',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '319',
    geannuleerd: false,
    instellings_nummer: '31401',
    instellings_naam: 'CVO LBC-NVK Turnhout',
    geografie: 'Hoogstraten',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-04-13',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '32',
    geannuleerd: false,
    instellings_nummer: '53454',
    instellings_naam: 'CVO VIVA West-Vlaanderen vzw',
    geografie: 'Wervik',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '320',
    geannuleerd: false,
    instellings_nummer: '41129',
    instellings_naam: 'GO! CVO EduKempen',
    geografie: 'Hoogstraten',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '321',
    geannuleerd: false,
    instellings_nummer: '39371',
    instellings_naam: 'CVO De Verdieping',
    geografie: 'Houthalen-Helchteren',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '322',
    geannuleerd: false,
    instellings_nummer: '39421',
    instellings_naam: 'CVO T.I.K.B.',
    geografie: 'Houthalen-Helchteren',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '324',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Houthulst',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2021-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '325',
    geannuleerd: false,
    instellings_nummer: '48488',
    instellings_naam: 'GO! CVO Cervo',
    geografie: 'Ieper',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '326',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Ieper',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '328',
    geannuleerd: false,
    instellings_nummer: '48637',
    instellings_naam: 'GO! CVO De Avondschool Oostende',
    geografie: 'Izegem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '330',
    geannuleerd: false,
    instellings_nummer: '126979',
    instellings_naam: 'PCVO West-Vlaanderen',
    geografie: 'Izegem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '337',
    geannuleerd: false,
    instellings_nummer: '30891',
    instellings_naam: 'CVO LBC-NVK Berchem-Brasschaat',
    geografie: 'Kalmthout',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '338',
    geannuleerd: false,
    instellings_nummer: '46482',
    instellings_naam: 'GO! CVO IVORAN Kapellen',
    geografie: 'Kalmthout',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '339',
    geannuleerd: false,
    instellings_nummer: '48934',
    instellings_naam: 'GO! CVO VIVA Antwerpen',
    geografie: 'Kalmthout',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '34',
    geannuleerd: false,
    instellings_nummer: '54486',
    instellings_naam: 'CVO Technisch Instituut Sint-Jozef',
    geografie: 'Westerlo',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '340',
    geannuleerd: false,
    instellings_nummer: '113688',
    instellings_naam: 'CVO Technicum Noord-Antwerpen',
    geografie: 'Kalmthout',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '342',
    geannuleerd: false,
    instellings_nummer: '30891',
    instellings_naam: 'CVO LBC-NVK Berchem-Brasschaat',
    geografie: 'Kapellen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '343',
    geannuleerd: false,
    instellings_nummer: '46482',
    instellings_naam: 'GO! CVO IVORAN Kapellen',
    geografie: 'Kapellen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '346',
    geannuleerd: false,
    instellings_nummer: '112615',
    instellings_naam: 'GO! CVO Altus',
    geografie: 'Knokke-Heist',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '348',
    geannuleerd: false,
    instellings_nummer: '48637',
    instellings_naam: 'GO! CVO De Avondschool Oostende',
    geografie: 'Koekelare',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '35',
    geannuleerd: false,
    instellings_nummer: '112532',
    instellings_naam: 'GO! CVO Kempen',
    geografie: 'Westerlo',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '350',
    geannuleerd: false,
    instellings_nummer: '48488',
    instellings_naam: 'GO! CVO Cervo',
    geografie: 'Koksijde',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '351',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Koksijde',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '353',
    geannuleerd: false,
    instellings_nummer: '48934',
    instellings_naam: 'GO! CVO VIVA Antwerpen',
    geografie: 'Kontich',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '354',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Kortemark',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '355',
    geannuleerd: false,
    instellings_nummer: '117168',
    instellings_naam: 'Vrij Tech. Inst. Leuven - CVO',
    geografie: 'Kortenberg',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '356',
    geannuleerd: false,
    instellings_nummer: '34884',
    instellings_naam: 'CVO MIRAS 34884',
    geografie: 'Kortrijk',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '357',
    geannuleerd: false,
    instellings_nummer: '34901',
    instellings_naam: 'CVO MIRAS 34901',
    geografie: 'Kortrijk',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '358',
    geannuleerd: false,
    instellings_nummer: '48901',
    instellings_naam: 'CVO Creo 48901',
    geografie: 'Kortrijk',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '359',
    geannuleerd: false,
    instellings_nummer: '53454',
    instellings_naam: 'CVO VIVA West-Vlaanderen vzw',
    geografie: 'Kortrijk',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '36',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Wetteren',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '362',
    geannuleerd: false,
    instellings_nummer: '112599',
    instellings_naam: 'GO! CVO 3 Hofsteden',
    geografie: 'Kortrijk',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '363',
    geannuleerd: false,
    instellings_nummer: '126979',
    instellings_naam: 'PCVO West-Vlaanderen',
    geografie: 'Kortrijk',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '364',
    geannuleerd: false,
    instellings_nummer: '33555',
    instellings_naam: 'CVO Tervuren - Hoeilaart',
    geografie: 'Kraainem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '365',
    geannuleerd: false,
    instellings_nummer: '110536',
    instellings_naam: 'GO! CVO De Vlaamse Ardennen',
    geografie: 'Kruishoutem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-02-06',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '366',
    geannuleerd: false,
    instellings_nummer: '48901',
    instellings_naam: 'CVO Creo 48901',
    geografie: 'Kuurne',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '368',
    geannuleerd: false,
    instellings_nummer: '48462',
    instellings_naam: 'GO! CVO Focus',
    geografie: 'Laarne',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '369',
    geannuleerd: false,
    instellings_nummer: '112541',
    instellings_naam: 'GO! CVO Zuid-Limburg',
    geografie: 'Lanaken',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '37',
    geannuleerd: false,
    instellings_nummer: '112516',
    instellings_naam: 'GO! CVO Panta Rhei',
    geografie: 'Wetteren',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2014-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2014-06-27',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '370',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Landen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '371',
    geannuleerd: false,
    instellings_nummer: '112698',
    instellings_naam: 'GO! CVO Leuven/Landen',
    geografie: 'Landen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2014-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-03-11',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '372',
    geannuleerd: false,
    instellings_nummer: '48488',
    instellings_naam: 'GO! CVO Cervo',
    geografie: 'Langemark-Poelkapelle',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '373',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Langemark-Poelkapelle',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '374',
    geannuleerd: false,
    instellings_nummer: '48462',
    instellings_naam: 'GO! CVO Focus',
    geografie: 'Lebbeke',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '375',
    geannuleerd: false,
    instellings_nummer: '113696',
    instellings_naam: 'CVO Tanera',
    geografie: 'Lede',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '376',
    geannuleerd: false,
    instellings_nummer: '112599',
    instellings_naam: 'GO! CVO 3 Hofsteden',
    geografie: 'Ledegem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '377',
    geannuleerd: false,
    instellings_nummer: '33431',
    instellings_naam: 'GO! GLTT CVO',
    geografie: 'Lennik',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '379',
    geannuleerd: false,
    instellings_nummer: '44339',
    instellings_naam: 'GO! CVO Lino',
    geografie: 'Leopoldsburg',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '38',
    geannuleerd: false,
    instellings_nummer: '115477',
    instellings_naam: 'Provinciaal CVO Scheldeland',
    geografie: 'Wetteren',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '381',
    geannuleerd: false,
    instellings_nummer: '31898',
    instellings_naam: 'CVO Hageland - Aarschot',
    geografie: 'Leuven',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '382',
    geannuleerd: false,
    instellings_nummer: '32888',
    instellings_naam: 'CVO CLT',
    geografie: 'Leuven',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '383',
    geannuleerd: false,
    instellings_nummer: '33043',
    instellings_naam: 'HIRL CVO',
    geografie: 'Leuven',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2012-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2013-02-07',
      laatst_gewijzigd_door: '80090108576'
    }
  },
  {
    code: '384',
    geannuleerd: false,
    instellings_nummer: '33068',
    instellings_naam: 'Centrum voor Levende Talen - CVO',
    geografie: 'Leuven',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '386',
    geannuleerd: false,
    instellings_nummer: '33563',
    instellings_naam: 'CVO - De Nobel',
    geografie: 'Leuven',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2014-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-03-11',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '389',
    geannuleerd: false,
    instellings_nummer: '104761',
    instellings_naam: 'CVO - Sociale School Heverlee',
    geografie: 'Leuven',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '39',
    geannuleerd: false,
    instellings_nummer: '48901',
    instellings_naam: 'CVO Creo 48901',
    geografie: 'Wevelgem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '390',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Leuven',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '392',
    geannuleerd: false,
    instellings_nummer: '112698',
    instellings_naam: 'GO! CVO Leuven/Landen',
    geografie: 'Leuven',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2014-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-03-11',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '393',
    geannuleerd: false,
    instellings_nummer: '117168',
    instellings_naam: 'Vrij Tech. Inst. Leuven - CVO',
    geografie: 'Leuven',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '394',
    geannuleerd: false,
    instellings_nummer: '125468',
    instellings_naam: 'GO! CVO De Oranjerie',
    geografie: 'Leuven',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '395',
    geannuleerd: false,
    instellings_nummer: '33233',
    instellings_naam: 'CVO M&T',
    geografie: 'Liedekerke',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '396',
    geannuleerd: false,
    instellings_nummer: '35881',
    instellings_naam: 'CVO Kisp - VTI Aalst',
    geografie: 'Liedekerke',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '397',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Liedekerke',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '4',
    geannuleerd: false,
    instellings_nummer: '31401',
    instellings_naam: 'CVO LBC-NVK Turnhout',
    geografie: 'Turnhout',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-04-13',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '40',
    geannuleerd: false,
    instellings_nummer: '53454',
    instellings_naam: 'CVO VIVA West-Vlaanderen vzw',
    geografie: 'Wevelgem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '401',
    geannuleerd: false,
    instellings_nummer: '48934',
    instellings_naam: 'GO! CVO VIVA Antwerpen',
    geografie: 'Lier',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '402',
    geannuleerd: false,
    instellings_nummer: '112508',
    instellings_naam: 'CVO VTI SP Lier-Antwerpen',
    geografie: 'Lier',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '404',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Lier',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '405',
    geannuleerd: false,
    instellings_nummer: '33431',
    instellings_naam: 'GO! GLTT CVO',
    geografie: 'Linkebeek',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '406',
    geannuleerd: false,
    instellings_nummer: '48934',
    instellings_naam: 'GO! CVO VIVA Antwerpen',
    geografie: 'Lint',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '407',
    geannuleerd: false,
    instellings_nummer: '47837',
    instellings_naam: 'CVO ISBO',
    geografie: 'Lochristi',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '409',
    geannuleerd: false,
    instellings_nummer: '48462',
    instellings_naam: 'GO! CVO Focus',
    geografie: 'Lokeren',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '41',
    geannuleerd: false,
    instellings_nummer: '112599',
    instellings_naam: 'GO! CVO 3 Hofsteden',
    geografie: 'Wevelgem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '410',
    geannuleerd: false,
    instellings_nummer: '115469',
    instellings_naam: 'Provinciaal CVO Waas en Durme',
    geografie: 'Lokeren',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '411',
    geannuleerd: false,
    instellings_nummer: '44339',
    instellings_naam: 'GO! CVO Lino',
    geografie: 'Lommel',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '414',
    geannuleerd: false,
    instellings_nummer: '33191',
    instellings_naam: 'CVO Meise-Jette',
    geografie: 'Londerzeel',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '416',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Lubbeek',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '417',
    geannuleerd: false,
    instellings_nummer: '39371',
    instellings_naam: 'CVO De Verdieping',
    geografie: 'Lummen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '418',
    geannuleerd: false,
    instellings_nummer: '39834',
    instellings_naam: 'CVO LBC-NVK Beringen',
    geografie: 'Lummen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '42',
    geannuleerd: false,
    instellings_nummer: '33555',
    instellings_naam: 'CVO Tervuren - Hoeilaart',
    geografie: 'Wezembeek-Oppem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '421',
    geannuleerd: false,
    instellings_nummer: '39792',
    instellings_naam: 'Vrij CVO Noord-Limburg',
    geografie: 'Maaseik',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '422',
    geannuleerd: false,
    instellings_nummer: '112581',
    instellings_naam: 'GO! CVO Cursa',
    geografie: 'Maaseik',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '424',
    geannuleerd: false,
    instellings_nummer: '48892',
    instellings_naam: 'Campus de helix - CVO',
    geografie: 'Maasmechelen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '425',
    geannuleerd: false,
    instellings_nummer: '112342',
    instellings_naam: 'PCVO  Maasland',
    geografie: 'Maasmechelen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2014-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2014-11-07',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '426',
    geannuleerd: false,
    instellings_nummer: '112581',
    instellings_naam: 'GO! CVO Cursa',
    geografie: 'Maasmechelen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '428',
    geannuleerd: false,
    instellings_nummer: '112615',
    instellings_naam: 'GO! CVO Altus',
    geografie: 'Maldegem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '429',
    geannuleerd: false,
    instellings_nummer: '41129',
    instellings_naam: 'GO! CVO EduKempen',
    geografie: 'Malle',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '43',
    geannuleerd: false,
    instellings_nummer: '48934',
    instellings_naam: 'GO! CVO VIVA Antwerpen',
    geografie: 'Wijnegem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '430',
    geannuleerd: false,
    instellings_nummer: '30619',
    instellings_naam: 'Technische Scholen Mechelen - CVO',
    geografie: 'Mechelen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '431',
    geannuleerd: false,
    instellings_nummer: '48934',
    instellings_naam: 'GO! CVO VIVA Antwerpen',
    geografie: 'Mechelen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '432',
    geannuleerd: false,
    instellings_nummer: '112458',
    instellings_naam: 'CVO Campus Vijfhoek',
    geografie: 'Mechelen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '435',
    geannuleerd: false,
    instellings_nummer: '117721',
    instellings_naam: 'CVO Vitant',
    geografie: 'Mechelen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2021-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '436',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Mechelen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-01-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '437',
    geannuleerd: false,
    instellings_nummer: '33191',
    instellings_naam: 'CVO Meise-Jette',
    geografie: 'Meise',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '438',
    geannuleerd: false,
    instellings_nummer: '112516',
    instellings_naam: 'GO! CVO Panta Rhei',
    geografie: 'Melle',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '439',
    geannuleerd: false,
    instellings_nummer: '34884',
    instellings_naam: 'CVO MIRAS 34884',
    geografie: 'Menen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '440',
    geannuleerd: false,
    instellings_nummer: '48901',
    instellings_naam: 'CVO Creo 48901',
    geografie: 'Menen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '441',
    geannuleerd: false,
    instellings_nummer: '53454',
    instellings_naam: 'CVO VIVA West-Vlaanderen vzw',
    geografie: 'Menen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '442',
    geannuleerd: false,
    instellings_nummer: '112599',
    instellings_naam: 'GO! CVO 3 Hofsteden',
    geografie: 'Menen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '443',
    geannuleerd: false,
    instellings_nummer: '33233',
    instellings_naam: 'CVO M&T',
    geografie: 'Merchtem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '445',
    geannuleerd: false,
    instellings_nummer: '112516',
    instellings_naam: 'GO! CVO Panta Rhei',
    geografie: 'Merelbeke',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2023-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '446',
    geannuleerd: false,
    instellings_nummer: '41129',
    instellings_naam: 'GO! CVO EduKempen',
    geografie: 'Merksplas',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '447',
    geannuleerd: false,
    instellings_nummer: '46524',
    instellings_naam: 'GO! CVO HORITO Turnhout',
    geografie: 'Merksplas',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '448',
    geannuleerd: false,
    instellings_nummer: '112599',
    instellings_naam: 'GO! CVO 3 Hofsteden',
    geografie: 'Meulebeke',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '449',
    geannuleerd: false,
    instellings_nummer: '48488',
    instellings_naam: 'GO! CVO Cervo',
    geografie: 'Middelkerke',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '45',
    geannuleerd: false,
    instellings_nummer: '46532',
    instellings_naam: 'GO! CVO Rivierenland',
    geografie: 'Willebroek',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '450',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Middelkerke',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '451',
    geannuleerd: false,
    instellings_nummer: '47837',
    instellings_naam: 'CVO ISBO',
    geografie: 'Moerbeke',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '453',
    geannuleerd: false,
    instellings_nummer: '112516',
    instellings_naam: 'GO! CVO Panta Rhei',
    geografie: 'Moerbeke',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '454',
    geannuleerd: false,
    instellings_nummer: '31047',
    instellings_naam: 'CVO Sint-Lutgardis Mol',
    geografie: 'Mol',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '455',
    geannuleerd: false,
    instellings_nummer: '31096',
    instellings_naam: 'CVO - Vormingslg. Soc. en Ped. Werk Mol',
    geografie: 'Mol',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '457',
    geannuleerd: false,
    instellings_nummer: '112532',
    instellings_naam: 'GO! CVO Kempen',
    geografie: 'Mol',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '459',
    geannuleerd: false,
    instellings_nummer: '112599',
    instellings_naam: 'GO! CVO 3 Hofsteden',
    geografie: 'Moorslede',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '46',
    geannuleerd: false,
    instellings_nummer: '112599',
    instellings_naam: 'GO! CVO 3 Hofsteden',
    geografie: 'Wingene',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '460',
    geannuleerd: false,
    instellings_nummer: '117391',
    instellings_naam: 'CVO Roeselare',
    geografie: 'Moorslede',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '462',
    geannuleerd: false,
    instellings_nummer: '31104',
    instellings_naam: 'CVO LBC-NVK L 31104',
    geografie: 'Mortsel',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2021-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '464',
    geannuleerd: false,
    instellings_nummer: '48934',
    instellings_naam: 'GO! CVO VIVA Antwerpen',
    geografie: 'Mortsel',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '466',
    geannuleerd: false,
    instellings_nummer: '39792',
    instellings_naam: 'Vrij CVO Noord-Limburg',
    geografie: 'Neerpelt',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '467',
    geannuleerd: false,
    instellings_nummer: '46532',
    instellings_naam: 'GO! CVO Rivierenland',
    geografie: 'Niel',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '468',
    geannuleerd: false,
    instellings_nummer: '48488',
    instellings_naam: 'GO! CVO Cervo',
    geografie: 'Nieuwpoort',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-01-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '470',
    geannuleerd: false,
    instellings_nummer: '48934',
    instellings_naam: 'GO! CVO VIVA Antwerpen',
    geografie: 'Nijlen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '471',
    geannuleerd: false,
    instellings_nummer: '35972',
    instellings_naam: 'CVO Kisp - VTH Aalst',
    geografie: 'Ninove',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '474',
    geannuleerd: false,
    instellings_nummer: '117077',
    instellings_naam: 'PCVO Groeipunt',
    geografie: 'Ninove',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '475',
    geannuleerd: false,
    instellings_nummer: '48637',
    instellings_naam: 'GO! CVO De Avondschool Oostende',
    geografie: 'Oostende',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '476',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Oostende',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '477',
    geannuleerd: false,
    instellings_nummer: '53454',
    instellings_naam: 'CVO VIVA West-Vlaanderen vzw',
    geografie: 'Oostende',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '478',
    geannuleerd: false,
    instellings_nummer: '112516',
    instellings_naam: 'GO! CVO Panta Rhei',
    geografie: 'Oosterzele',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '479',
    geannuleerd: false,
    instellings_nummer: '34091',
    instellings_naam: 'CVO Sint-Godelieve',
    geografie: 'Oostkamp',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '48',
    geannuleerd: false,
    instellings_nummer: '105353',
    instellings_naam: 'PCVO VIVA',
    geografie: 'Wortegem-Petegem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '480',
    geannuleerd: false,
    instellings_nummer: '112615',
    instellings_naam: 'GO! CVO Altus',
    geografie: 'Oostkamp',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '482',
    geannuleerd: false,
    instellings_nummer: '37895',
    instellings_naam: 'CVO KISP-VAZOV',
    geografie: 'Oudenaarde',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '484',
    geannuleerd: false,
    instellings_nummer: '110536',
    instellings_naam: 'GO! CVO De Vlaamse Ardennen',
    geografie: 'Oudenaarde',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '485',
    geannuleerd: false,
    instellings_nummer: '117077',
    instellings_naam: 'PCVO Groeipunt',
    geografie: 'Oudenaarde',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '488',
    geannuleerd: false,
    instellings_nummer: '33555',
    instellings_naam: 'CVO Tervuren - Hoeilaart',
    geografie: 'Overijse',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '489',
    geannuleerd: false,
    instellings_nummer: '39792',
    instellings_naam: 'Vrij CVO Noord-Limburg',
    geografie: 'Overpelt',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '49',
    geannuleerd: false,
    instellings_nummer: '30891',
    instellings_naam: 'CVO LBC-NVK Berchem-Brasschaat',
    geografie: 'Wuustwezel',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '490',
    geannuleerd: false,
    instellings_nummer: '44339',
    instellings_naam: 'GO! CVO Lino',
    geografie: 'Overpelt',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-05-11',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '491',
    geannuleerd: false,
    instellings_nummer: '39792',
    instellings_naam: 'Vrij CVO Noord-Limburg',
    geografie: 'Peer',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '492',
    geannuleerd: false,
    instellings_nummer: '48488',
    instellings_naam: 'GO! CVO Cervo',
    geografie: 'Poperinge',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '493',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Poperinge',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '494',
    geannuleerd: false,
    instellings_nummer: '30619',
    instellings_naam: 'Technische Scholen Mechelen - CVO',
    geografie: 'Putte',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '495',
    geannuleerd: false,
    instellings_nummer: '46532',
    instellings_naam: 'GO! CVO Rivierenland',
    geografie: 'Puurs',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '498',
    geannuleerd: false,
    instellings_nummer: '41129',
    instellings_naam: 'GO! CVO EduKempen',
    geografie: 'Rijkevorsel',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '499',
    geannuleerd: false,
    instellings_nummer: '34901',
    instellings_naam: 'CVO MIRAS 34901',
    geografie: 'Roeselare',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '5',
    geannuleerd: false,
    instellings_nummer: '41129',
    instellings_naam: 'GO! CVO EduKempen',
    geografie: 'Turnhout',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '500',
    geannuleerd: false,
    instellings_nummer: '35402',
    instellings_naam: 'CVO Kokelaarstraat',
    geografie: 'Roeselare',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '501',
    geannuleerd: false,
    instellings_nummer: '48637',
    instellings_naam: 'GO! CVO De Avondschool Oostende',
    geografie: 'Roeselare',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '502',
    geannuleerd: false,
    instellings_nummer: '48901',
    instellings_naam: 'CVO Creo 48901',
    geografie: 'Roeselare',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '504',
    geannuleerd: false,
    instellings_nummer: '117391',
    instellings_naam: 'CVO Roeselare',
    geografie: 'Roeselare',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '505',
    geannuleerd: false,
    instellings_nummer: '37895',
    instellings_naam: 'CVO KISP-VAZOV',
    geografie: 'Ronse',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '506',
    geannuleerd: false,
    instellings_nummer: '105353',
    instellings_naam: 'PCVO VIVA',
    geografie: 'Ronse',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '507',
    geannuleerd: false,
    instellings_nummer: '110536',
    instellings_naam: 'GO! CVO De Vlaamse Ardennen',
    geografie: 'Ronse',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-04-13',
      laatst_gewijzigd_door: '88061035967'
    }
  },
  {
    code: '508',
    geannuleerd: false,
    instellings_nummer: '33233',
    instellings_naam: 'CVO M&T',
    geografie: 'Roosdaal',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '509',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Rotselaar',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '51',
    geannuleerd: false,
    instellings_nummer: '128521',
    instellings_naam: 'GO! CVO Crescendo',
    geografie: 'Zaventem',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '510',
    geannuleerd: false,
    instellings_nummer: '34066',
    instellings_naam: 'SNT  CVO',
    geografie: 'Ruiselede',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '511',
    geannuleerd: false,
    instellings_nummer: '48901',
    instellings_naam: 'CVO Creo 48901',
    geografie: 'Ruiselede',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '512',
    geannuleerd: false,
    instellings_nummer: '112599',
    instellings_naam: 'GO! CVO 3 Hofsteden',
    geografie: 'Ruiselede',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '513',
    geannuleerd: false,
    instellings_nummer: '117391',
    instellings_naam: 'CVO Roeselare',
    geografie: 'Ruiselede',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '514',
    geannuleerd: false,
    instellings_nummer: '126979',
    instellings_naam: 'PCVO West-Vlaanderen',
    geografie: 'Ruiselede',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '52',
    geannuleerd: false,
    instellings_nummer: '34066',
    instellings_naam: 'SNT  CVO',
    geografie: 'Zedelgem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '525',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Scherpenheuvel-Zichem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '527',
    geannuleerd: false,
    instellings_nummer: '125468',
    instellings_naam: 'GO! CVO De Oranjerie',
    geografie: 'Scherpenheuvel-Zichem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '528',
    geannuleerd: false,
    instellings_nummer: '112706',
    instellings_naam: 'GO! CVO Deurne',
    geografie: 'Schilde',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2022-06-07',
      laatst_gewijzigd_door: '999999999'
    }
  },
  {
    code: '529',
    geannuleerd: false,
    instellings_nummer: '46482',
    instellings_naam: 'GO! CVO IVORAN Kapellen',
    geografie: 'Schoten',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '53',
    geannuleerd: false,
    instellings_nummer: '48462',
    instellings_naam: 'GO! CVO Focus',
    geografie: 'Zele',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '530',
    geannuleerd: false,
    instellings_nummer: '48934',
    instellings_naam: 'GO! CVO VIVA Antwerpen',
    geografie: 'Schoten',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '536',
    geannuleerd: false,
    instellings_nummer: '33431',
    instellings_naam: 'GO! GLTT CVO',
    geografie: 'Sint-Genesius-Rode',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '54',
    geannuleerd: false,
    instellings_nummer: '115469',
    instellings_naam: 'Provinciaal CVO Waas en Durme',
    geografie: 'Zele',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '541',
    geannuleerd: false,
    instellings_nummer: '48421',
    instellings_naam: 'GO! CVO JANITOR',
    geografie: 'Sint-Gillis-Waas',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '547',
    geannuleerd: false,
    instellings_nummer: '30619',
    instellings_naam: 'Technische Scholen Mechelen - CVO',
    geografie: 'Sint-Katelijne-Waver',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '549',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Sint-Niklaas',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '55',
    geannuleerd: false,
    instellings_nummer: '47837',
    instellings_naam: 'CVO ISBO',
    geografie: 'Zelzate',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '550',
    geannuleerd: false,
    instellings_nummer: '48421',
    instellings_naam: 'GO! CVO JANITOR',
    geografie: 'Sint-Niklaas',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '551',
    geannuleerd: false,
    instellings_nummer: '115477',
    instellings_naam: 'Provinciaal CVO Scheldeland',
    geografie: 'Sint-Niklaas',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '552',
    geannuleerd: false,
    instellings_nummer: '33431',
    instellings_naam: 'GO! GLTT CVO',
    geografie: 'Sint-Pieters-Leeuw',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2021-09-01',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '553',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Sint-Pieters-Leeuw',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '556',
    geannuleerd: false,
    instellings_nummer: '48587',
    instellings_naam: 'KCST CVO',
    geografie: 'Sint-Truiden',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '557',
    geannuleerd: false,
    instellings_nummer: '112541',
    instellings_naam: 'GO! CVO Zuid-Limburg',
    geografie: 'Sint-Truiden',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '558',
    geannuleerd: false,
    instellings_nummer: '112698',
    instellings_naam: 'GO! CVO Leuven/Landen',
    geografie: 'Sint-Truiden',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2014-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-03-11',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '559',
    geannuleerd: false,
    instellings_nummer: '112714',
    instellings_naam: 'GO! CVO - STEP',
    geografie: 'Sint-Truiden',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '560',
    geannuleerd: false,
    instellings_nummer: '42051',
    instellings_naam: 'GO! CVO Avelgem/Harelbeke',
    geografie: 'Spiere-Helkijn',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '561',
    geannuleerd: false,
    instellings_nummer: '46482',
    instellings_naam: 'GO! CVO IVORAN Kapellen',
    geografie: 'Stabroek',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '562',
    geannuleerd: false,
    instellings_nummer: '48934',
    instellings_naam: 'GO! CVO VIVA Antwerpen',
    geografie: 'Stabroek',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '563',
    geannuleerd: false,
    instellings_nummer: '117721',
    instellings_naam: 'CVO Vitant',
    geografie: 'Stabroek',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '564',
    geannuleerd: false,
    instellings_nummer: '33753',
    instellings_naam: 'CVO Vilvoorde',
    geografie: 'Steenokkerzeel',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '567',
    geannuleerd: false,
    instellings_nummer: '38166',
    instellings_naam: 'CVO LBC-NVK C 38166',
    geografie: 'Stekene',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '568',
    geannuleerd: false,
    instellings_nummer: '38356',
    instellings_naam: 'CVO Temse',
    geografie: 'Temse',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '569',
    geannuleerd: false,
    instellings_nummer: '33233',
    instellings_naam: 'CVO M&T',
    geografie: 'Ternat',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '571',
    geannuleerd: false,
    instellings_nummer: '33555',
    instellings_naam: 'CVO Tervuren - Hoeilaart',
    geografie: 'Tervuren',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '572',
    geannuleerd: false,
    instellings_nummer: '54486',
    instellings_naam: 'CVO Technisch Instituut Sint-Jozef',
    geografie: 'Tessenderlo',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '575',
    geannuleerd: false,
    instellings_nummer: '34901',
    instellings_naam: 'CVO MIRAS 34901',
    geografie: 'Tielt',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '576',
    geannuleerd: false,
    instellings_nummer: '48595',
    instellings_naam: 'CVO MIRAS 48595',
    geografie: 'Tielt',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '577',
    geannuleerd: false,
    instellings_nummer: '48901',
    instellings_naam: 'CVO Creo 48901',
    geografie: 'Tielt',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '578',
    geannuleerd: false,
    instellings_nummer: '112599',
    instellings_naam: 'GO! CVO 3 Hofsteden',
    geografie: 'Tielt',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '579',
    geannuleerd: false,
    instellings_nummer: '117391',
    instellings_naam: 'CVO Roeselare',
    geografie: 'Tielt',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '58',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Zelzate',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '581',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Tielt-Winge',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '582',
    geannuleerd: false,
    instellings_nummer: '125468',
    instellings_naam: 'GO! CVO De Oranjerie',
    geografie: 'Tielt-Winge',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '583',
    geannuleerd: false,
    instellings_nummer: '33563',
    instellings_naam: 'CVO - De Nobel',
    geografie: 'Tienen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2014-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-03-11',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '584',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Tienen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '585',
    geannuleerd: false,
    instellings_nummer: '117168',
    instellings_naam: 'Vrij Tech. Inst. Leuven - CVO',
    geografie: 'Tienen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '586',
    geannuleerd: false,
    instellings_nummer: '39991',
    instellings_naam: 'CVO viio',
    geografie: 'Tongeren',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2015-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2015-10-16',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '588',
    geannuleerd: false,
    instellings_nummer: '112541',
    instellings_naam: 'GO! CVO Zuid-Limburg',
    geografie: 'Tongeren',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '589',
    geannuleerd: false,
    instellings_nummer: '48637',
    instellings_naam: 'GO! CVO De Avondschool Oostende',
    geografie: 'Torhout',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '591',
    geannuleerd: false,
    instellings_nummer: '126599',
    instellings_naam: 'CBE Ligo Zuid-Oost-Vlaanderen',
    geografie: 'Aalst',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '593',
    geannuleerd: false,
    instellings_nummer: '126541',
    instellings_naam: 'CBE Ligo Oost-Brabant',
    geografie: 'Aarschot',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '594',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Affligem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '595',
    geannuleerd: false,
    instellings_nummer: '126573',
    instellings_naam: 'CBE Ligo Limburg Zuid',
    geografie: 'Alken',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '596',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Alveringem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '597',
    geannuleerd: false,
    instellings_nummer: '116616',
    instellings_naam: 'CBE Ligo Brusselleer',
    geografie: 'Brussels Hoofdstedelijk Gewest',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '598',
    geannuleerd: false,
    instellings_nummer: '126516',
    instellings_naam: 'CBE Ligo Antwerpen',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '599',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Anzegem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '6',
    geannuleerd: false,
    instellings_nummer: '46524',
    instellings_naam: 'GO! CVO HORITO Turnhout',
    geografie: 'Turnhout',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '600',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'As',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '601',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Asse',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '603',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Avelgem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '604',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Balen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '605',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Beernem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '606',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Beerse',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '607',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Beersel',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '608',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Beringen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '609',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Berlaar',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '61',
    geannuleerd: false,
    instellings_nummer: '48934',
    instellings_naam: 'GO! CVO VIVA Antwerpen',
    geografie: 'Zoersel',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '610',
    geannuleerd: false,
    instellings_nummer: '126581',
    instellings_naam: 'CBE Ligo Waas & Dender',
    geografie: 'Berlare',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '611',
    geannuleerd: false,
    instellings_nummer: '126541',
    instellings_naam: 'CBE Ligo Oost-Brabant',
    geografie: 'Bertem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '612',
    geannuleerd: false,
    instellings_nummer: '126581',
    instellings_naam: 'CBE Ligo Waas & Dender',
    geografie: 'Beveren',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '613',
    geannuleerd: false,
    instellings_nummer: '126541',
    instellings_naam: 'CBE Ligo Oost-Brabant',
    geografie: 'Bierbeek',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '614',
    geannuleerd: false,
    instellings_nummer: '126573',
    instellings_naam: 'CBE Ligo Limburg Zuid',
    geografie: 'Bilzen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '615',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Blankenberge',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '616',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Bocholt',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '617',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Boechout',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '618',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Boom',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '619',
    geannuleerd: false,
    instellings_nummer: '126541',
    instellings_naam: 'CBE Ligo Oost-Brabant',
    geografie: 'Boortmeerbeek',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '62',
    geannuleerd: false,
    instellings_nummer: '112714',
    instellings_naam: 'GO! CVO - STEP',
    geografie: 'Zonhoven',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '620',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Bornem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '621',
    geannuleerd: false,
    instellings_nummer: '126516',
    instellings_naam: 'CBE Ligo Antwerpen',
    geografie: 'Brasschaat',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '622',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Brecht',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '623',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Bredene',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '624',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Bree',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '625',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Brugge',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '627',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Damme',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '628',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'De Haan',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '629',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'De Panne',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '63',
    geannuleerd: false,
    instellings_nummer: '48488',
    instellings_naam: 'GO! CVO Cervo',
    geografie: 'Zonnebeke',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '630',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Deerlijk',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '631',
    geannuleerd: false,
    instellings_nummer: '126607',
    instellings_naam: 'CBE Ligo Gent-Meetjesland-Leieland',
    geografie: 'Deinze',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '632',
    geannuleerd: false,
    instellings_nummer: '126599',
    instellings_naam: 'CBE Ligo Zuid-Oost-Vlaanderen',
    geografie: 'Denderleeuw',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '633',
    geannuleerd: false,
    instellings_nummer: '126581',
    instellings_naam: 'CBE Ligo Waas & Dender',
    geografie: 'Dendermonde',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '634',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Dentergem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '635',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Dessel',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '636',
    geannuleerd: false,
    instellings_nummer: '126573',
    instellings_naam: 'CBE Ligo Limburg Zuid',
    geografie: 'Diepenbeek',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '637',
    geannuleerd: false,
    instellings_nummer: '126541',
    instellings_naam: 'CBE Ligo Oost-Brabant',
    geografie: 'Diest',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '638',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Diksmuide',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '639',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Dilbeek',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '64',
    geannuleerd: false,
    instellings_nummer: '53454',
    instellings_naam: 'CVO VIVA West-Vlaanderen vzw',
    geografie: 'Zonnebeke',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '640',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Dilsen-Stokkem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '641',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Duffel',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '642',
    geannuleerd: false,
    instellings_nummer: '126607',
    instellings_naam: 'CBE Ligo Gent-Meetjesland-Leieland',
    geografie: 'Eeklo',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '645',
    geannuleerd: false,
    instellings_nummer: '126516',
    instellings_naam: 'CBE Ligo Antwerpen',
    geografie: 'Essen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '650',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Geel',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '651',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Genk',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '652',
    geannuleerd: false,
    instellings_nummer: '126607',
    instellings_naam: 'CBE Ligo Gent-Meetjesland-Leieland',
    geografie: 'Gent',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '653',
    geannuleerd: false,
    instellings_nummer: '126599',
    instellings_naam: 'CBE Ligo Zuid-Oost-Vlaanderen',
    geografie: 'Geraardsbergen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '654',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Gistel',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '655',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Gooik',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '656',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Grimbergen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '657',
    geannuleerd: false,
    instellings_nummer: '126541',
    instellings_naam: 'CBE Ligo Oost-Brabant',
    geografie: 'Haacht',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '659',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Halle',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '660',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Ham',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '661',
    geannuleerd: false,
    instellings_nummer: '126581',
    instellings_naam: 'CBE Ligo Waas & Dender',
    geografie: 'Hamme',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '662',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Hamont-Achel',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '663',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Harelbeke',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '664',
    geannuleerd: false,
    instellings_nummer: '126573',
    instellings_naam: 'CBE Ligo Limburg Zuid',
    geografie: 'Hasselt',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '665',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Hechtel-Eksel',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '666',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Heist-op-den-Berg',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '667',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Hemiksem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '668',
    geannuleerd: false,
    instellings_nummer: '126541',
    instellings_naam: 'CBE Ligo Oost-Brabant',
    geografie: 'Herent',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '669',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Herentals',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '670',
    geannuleerd: false,
    instellings_nummer: '126573',
    instellings_naam: 'CBE Ligo Limburg Zuid',
    geografie: 'Herk-de-Stad',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '671',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Herne',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '672',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Heusden-Zolder',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '673',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Hoeilaart',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '674',
    geannuleerd: false,
    instellings_nummer: '126573',
    instellings_naam: 'CBE Ligo Limburg Zuid',
    geografie: 'Hoeselt',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '675',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Hooglede',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '676',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Hoogstraten',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '677',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Houthalen-Helchteren',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '678',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Houthulst',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '679',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Ichtegem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '68',
    geannuleerd: false,
    instellings_nummer: '105353',
    instellings_naam: 'PCVO VIVA',
    geografie: 'Zottegem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '680',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Ieper',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '681',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Ingelmunster',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '682',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Izegem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '684',
    geannuleerd: false,
    instellings_nummer: '126516',
    instellings_naam: 'CBE Ligo Antwerpen',
    geografie: 'Kapellen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '685',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Kinrooi',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '688',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Knokke-Heist',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '689',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Koekelare',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '69',
    geannuleerd: false,
    instellings_nummer: '112516',
    instellings_naam: 'GO! CVO Panta Rhei',
    geografie: 'Zottegem',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '691',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Koksijde',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '692',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Kortemark',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '693',
    geannuleerd: false,
    instellings_nummer: '126541',
    instellings_naam: 'CBE Ligo Oost-Brabant',
    geografie: 'Kortenberg',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '694',
    geannuleerd: false,
    instellings_nummer: '126573',
    instellings_naam: 'CBE Ligo Limburg Zuid',
    geografie: 'Kortessem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '695',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Kortrijk',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '696',
    geannuleerd: true,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Kortrijk',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-06-04',
      laatst_gewijzigd_door: '80090108576'
    }
  },
  {
    code: '698',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Kuurne',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '699',
    geannuleerd: false,
    instellings_nummer: '126581',
    instellings_naam: 'CBE Ligo Waas & Dender',
    geografie: 'Laarne',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '7',
    geannuleerd: false,
    instellings_nummer: '48488',
    instellings_naam: 'GO! CVO Cervo',
    geografie: 'Veurne',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '70',
    geannuleerd: false,
    instellings_nummer: '117077',
    instellings_naam: 'PCVO Groeipunt',
    geografie: 'Zottegem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '700',
    geannuleerd: false,
    instellings_nummer: '126573',
    instellings_naam: 'CBE Ligo Limburg Zuid',
    geografie: 'Lanaken',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '701',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Langemark-Poelkapelle',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '702',
    geannuleerd: false,
    instellings_nummer: '126581',
    instellings_naam: 'CBE Ligo Waas & Dender',
    geografie: 'Lebbeke',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '704',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Lendelede',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '706',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Leopoldsburg',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '707',
    geannuleerd: false,
    instellings_nummer: '126541',
    instellings_naam: 'CBE Ligo Oost-Brabant',
    geografie: 'Leuven',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '708',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Liedekerke',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '709',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Lier',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '71',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Zoutleeuw',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '710',
    geannuleerd: false,
    instellings_nummer: '126581',
    instellings_naam: 'CBE Ligo Waas & Dender',
    geografie: 'Lokeren',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '711',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Lommel',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '714',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Maaseik',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '715',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Maasmechelen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '716',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Machelen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '718',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Mechelen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '719',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Meerhout',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '72',
    geannuleerd: false,
    instellings_nummer: '34884',
    instellings_naam: 'CVO MIRAS 34884',
    geografie: 'Zwevegem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '720',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Meeuwen-Gruitrode',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '721',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Meise',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '722',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Menen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '723',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Merchtem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '724',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Merksplas',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '725',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Meulebeke',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '726',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Middelkerke',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '727',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Mol',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '728',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Neerpelt',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '73',
    geannuleerd: false,
    instellings_nummer: '48901',
    instellings_naam: 'CVO Creo 48901',
    geografie: 'Zwevegem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '730',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Niel',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '731',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Nieuwpoort',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '732',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Nijlen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '733',
    geannuleerd: false,
    instellings_nummer: '126599',
    instellings_naam: 'CBE Ligo Zuid-Oost-Vlaanderen',
    geografie: 'Ninove',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '734',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Oostende',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '736',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Oostkamp',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '737',
    geannuleerd: false,
    instellings_nummer: '126599',
    instellings_naam: 'CBE Ligo Zuid-Oost-Vlaanderen',
    geografie: 'Oudenaarde',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '738',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Oudenburg',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '739',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Overijse',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '74',
    geannuleerd: false,
    instellings_nummer: '112599',
    instellings_naam: 'GO! CVO 3 Hofsteden',
    geografie: 'Zwevegem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '740',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Overpelt',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '741',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Peer',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '742',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Pepingen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '743',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Poperinge',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '744',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Putte',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '745',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Puurs',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '746',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Ranst',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '747',
    geannuleerd: false,
    instellings_nummer: '126573',
    instellings_naam: 'CBE Ligo Limburg Zuid',
    geografie: 'Riemst',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '748',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Roeselare',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '749',
    geannuleerd: true,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Roeselare',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2015-06-04',
      laatst_gewijzigd_door: '80090108576'
    }
  },
  {
    code: '75',
    geannuleerd: false,
    instellings_nummer: '112706',
    instellings_naam: 'GO! CVO Deurne',
    geografie: 'Zwijndrecht',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2012-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2013-01-25',
      laatst_gewijzigd_door: 'MWOV'
    }
  },
  {
    code: '750',
    geannuleerd: false,
    instellings_nummer: '126599',
    instellings_naam: 'CBE Ligo Zuid-Oost-Vlaanderen',
    geografie: 'Ronse',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '751',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Roosdaal',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '752',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Ruiselede',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '753',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Rumst',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '755',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Schelle',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '756',
    geannuleerd: false,
    instellings_nummer: '126541',
    instellings_naam: 'CBE Ligo Oost-Brabant',
    geografie: 'Scherpenheuvel-Zichem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '757',
    geannuleerd: false,
    instellings_nummer: '126516',
    instellings_naam: 'CBE Ligo Antwerpen',
    geografie: 'Schoten',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '76',
    geannuleerd: false,
    instellings_nummer: '35881',
    instellings_naam: 'CVO Kisp - VTI Aalst',
    geografie: 'Aalst',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '760',
    geannuleerd: false,
    instellings_nummer: '126581',
    instellings_naam: 'CBE Ligo Waas & Dender',
    geografie: 'Sint-Gillis-Waas',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '764',
    geannuleerd: false,
    instellings_nummer: '126581',
    instellings_naam: 'CBE Ligo Waas & Dender',
    geografie: 'Sint-Niklaas',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '765',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Sint-Pieters-Leeuw',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '766',
    geannuleerd: false,
    instellings_nummer: '126573',
    instellings_naam: 'CBE Ligo Limburg Zuid',
    geografie: 'Sint-Truiden',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '768',
    geannuleerd: false,
    instellings_nummer: '126581',
    instellings_naam: 'CBE Ligo Waas & Dender',
    geografie: 'Temse',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '769',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Ternat',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '77',
    geannuleerd: false,
    instellings_nummer: '35972',
    instellings_naam: 'CVO Kisp - VTH Aalst',
    geografie: 'Aalst',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '770',
    geannuleerd: false,
    instellings_nummer: '126541',
    instellings_naam: 'CBE Ligo Oost-Brabant',
    geografie: 'Tervuren',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '773',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Tielt',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '774',
    geannuleerd: false,
    instellings_nummer: '126541',
    instellings_naam: 'CBE Ligo Oost-Brabant',
    geografie: 'Tienen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '775',
    geannuleerd: false,
    instellings_nummer: '126573',
    instellings_naam: 'CBE Ligo Limburg Zuid',
    geografie: 'Tongeren',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '776',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Torhout',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '777',
    geannuleerd: false,
    instellings_nummer: '126541',
    instellings_naam: 'CBE Ligo Oost-Brabant',
    geografie: 'Tremelo',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '778',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Turnhout',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '779',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Veurne',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '78',
    geannuleerd: false,
    instellings_nummer: '36574',
    instellings_naam: 'Technisch Instituut Sint-Jozef CVO',
    geografie: 'Aalst',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2017-06-28',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '780',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Vilvoorde',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '781',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Vorselaar',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '783',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Waregem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '784',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Wemmel',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '785',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Wervik',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '786',
    geannuleerd: false,
    instellings_nummer: '126524',
    instellings_naam: 'CBE Ligo Kempen',
    geografie: 'Westerlo',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '787',
    geannuleerd: false,
    instellings_nummer: '126581',
    instellings_naam: 'CBE Ligo Waas & Dender',
    geografie: 'Wetteren',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '788',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Wevelgem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '789',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Wezembeek-Oppem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '79',
    geannuleerd: false,
    instellings_nummer: '105353',
    instellings_naam: 'PCVO VIVA',
    geografie: 'Aalst',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '790',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Wielsbeke',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '791',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Willebroek',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '792',
    geannuleerd: false,
    instellings_nummer: '126615',
    instellings_naam: 'CBE Ligo Midden- en Zuid-West-Vlaanderen',
    geografie: 'Wingene',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '793',
    geannuleerd: false,
    instellings_nummer: '126516',
    instellings_naam: 'CBE Ligo Antwerpen',
    geografie: 'Wommelgem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '794',
    geannuleerd: false,
    instellings_nummer: '126557',
    instellings_naam: 'CBE Ligo Halle-Vilvoorde',
    geografie: 'Zaventem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '795',
    geannuleerd: false,
    instellings_nummer: '126623',
    instellings_naam: 'CBE Ligo Brugge-Oostende-Westhoek',
    geografie: 'Zedelgem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '796',
    geannuleerd: false,
    instellings_nummer: '126581',
    instellings_naam: 'CBE Ligo Waas & Dender',
    geografie: 'Zele',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '798',
    geannuleerd: false,
    instellings_nummer: '126599',
    instellings_naam: 'CBE Ligo Zuid-Oost-Vlaanderen',
    geografie: 'Zingem',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2019-06-26',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '8',
    geannuleerd: false,
    instellings_nummer: '48736',
    instellings_naam: 'CVO MIRAS',
    geografie: 'Veurne',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '80',
    geannuleerd: false,
    instellings_nummer: '113696',
    instellings_naam: 'CVO Tanera',
    geografie: 'Aalst',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2017-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '800',
    geannuleerd: false,
    instellings_nummer: '126573',
    instellings_naam: 'CBE Ligo Limburg Zuid',
    geografie: 'Zonhoven',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '801',
    geannuleerd: false,
    instellings_nummer: '126599',
    instellings_naam: 'CBE Ligo Zuid-Oost-Vlaanderen',
    geografie: 'Zottegem',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '803',
    geannuleerd: false,
    instellings_nummer: '126565',
    instellings_naam: 'CBE Ligo LiMiNo',
    geografie: 'Zutendaal',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '804',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Hove',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-05-14',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '805',
    geannuleerd: false,
    instellings_nummer: '126532',
    instellings_naam: 'CBE Ligo regio Mechelen',
    geografie: 'Sint-Katelijne-Waver',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-05-14',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '806',
    geannuleerd: false,
    instellings_nummer: '126516',
    instellings_naam: 'CBE Ligo Antwerpen',
    geografie: 'Kalmthout',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-05-14',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '808',
    geannuleerd: false,
    instellings_nummer: '126607',
    instellings_naam: 'CBE Ligo Gent-Meetjesland-Leieland',
    geografie: 'Merelbeke',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-05-16',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '809',
    geannuleerd: false,
    instellings_nummer: '46474',
    instellings_naam: 'GO! CVO Antwerpenn',
    geografie: 'Schilde',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-07-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '81',
    geannuleerd: false,
    instellings_nummer: '113712',
    instellings_naam: 'GO! hét CVO Pro',
    geografie: 'Aalst',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-06-08',
      laatst_gewijzigd_door: '71121704253'
    }
  },
  {
    code: '810',
    geannuleerd: false,
    instellings_nummer: '112516',
    instellings_naam: 'GO! CVO Panta Rhei',
    geografie: 'Sint-Laureins',
    geldigheids_periode: {
      geldig_van: '2012-09-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-10-10',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '811',
    geannuleerd: false,
    instellings_nummer: '112615',
    instellings_naam: 'GO! CVO Altus',
    geografie: 'Ieper',
    geldigheids_periode: {
      geldig_van: '2012-09-01',
      geldig_tot: '2017-09-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-10-10',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '812',
    geannuleerd: false,
    instellings_nummer: '117168',
    instellings_naam: 'Vrij Tech. Inst. Leuven - CVO',
    geografie: 'Diest',
    geldigheids_periode: {
      geldig_van: '2012-09-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '82',
    geannuleerd: false,
    instellings_nummer: '123992',
    instellings_naam: 'CVO Kisp',
    geografie: 'Aalter',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-12-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-02-06',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '83',
    geannuleerd: false,
    instellings_nummer: '31898',
    instellings_naam: 'CVO Hageland - Aarschot',
    geografie: 'Aarschot',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '84',
    geannuleerd: false,
    instellings_nummer: '33043',
    instellings_naam: 'HIRL CVO',
    geografie: 'Aarschot',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2012-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2013-02-07',
      laatst_gewijzigd_door: '80090108576'
    }
  },
  {
    code: '86',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Aarschot',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '87',
    geannuleerd: false,
    instellings_nummer: '125468',
    instellings_naam: 'GO! CVO De Oranjerie',
    geografie: 'Aarschot',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-25',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '88',
    geannuleerd: false,
    instellings_nummer: '32491',
    instellings_naam: 'CVO Lethas Brussel',
    geografie: 'Brussels Hoofdstedelijk Gewest',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '89',
    geannuleerd: false,
    instellings_nummer: '33191',
    instellings_naam: 'CVO Meise-Jette',
    geografie: 'Brussels Hoofdstedelijk Gewest',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-27',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '91',
    geannuleerd: false,
    instellings_nummer: '56366',
    instellings_naam: 'CVO COOVI',
    geografie: 'Brussels Hoofdstedelijk Gewest',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '92',
    geannuleerd: false,
    instellings_nummer: '105759',
    instellings_naam: 'CVO VIVA SVV Brabant',
    geografie: 'Brussels Hoofdstedelijk Gewest',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-03-30',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '93',
    geannuleerd: false,
    instellings_nummer: '28431',
    instellings_naam: 'CVO LBC-NVK B 28431',
    geografie: 'Antwerpen',
    enkel_evcassesment: false,
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2021-05-03',
      laatst_gewijzigd_door: '85012307062'
    }
  },
  {
    code: '94',
    geannuleerd: false,
    instellings_nummer: '28531',
    instellings_naam: 'CVO Encora 028531',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '95',
    geannuleerd: false,
    instellings_nummer: '29082',
    instellings_naam: 'Stedelijk CVO Encora 029082',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2018-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '96',
    geannuleerd: false,
    instellings_nummer: '29124',
    instellings_naam: 'Stedelijk CVO Encora 029124',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '97',
    geannuleerd: false,
    instellings_nummer: '29207',
    instellings_naam: 'CVO HBO5 Antwerpen',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2019-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-02-26',
      laatst_gewijzigd_door: '60120535860'
    }
  },
  {
    code: '98',
    geannuleerd: false,
    instellings_nummer: '29389',
    instellings_naam: 'Stedelijk CVO Encora 029389',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '1975-04-01'
    },
    audit: {
      laatst_gewijzigd_op: '2012-03-28',
      laatst_gewijzigd_door: 'INIT'
    }
  },
  {
    code: '99',
    geannuleerd: false,
    instellings_nummer: '30891',
    instellings_naam: 'CVO LBC-NVK Berchem-Brasschaat',
    geografie: 'Antwerpen',
    geldigheids_periode: {
      geldig_van: '1975-04-01',
      geldig_tot: '2020-08-31'
    },
    audit: {
      laatst_gewijzigd_op: '2020-06-24',
      laatst_gewijzigd_door: '85012307062'
    }
  }
];
