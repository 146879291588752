/**
 * Naric
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 4.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { OpleidingsTypeRestDto } from '././opleidingsTypeRestDto';


import {ValidatorFn, AbstractControlOptions, FormGroup, FormBuilder, FormArray} from '@angular/forms';
import { SwaggerValidators } from '@ov/ngx-ov-utils';

export interface IBenodigdeDocumentRestDto {
	document_type_context_code: string;
	documentnaam: string;
	opleidings_type: OpleidingsTypeRestDto;
	verplicht: boolean;
}


export class BenodigdeDocumentRestDto implements IBenodigdeDocumentRestDto {
	document_type_context_code: string;
	documentnaam: string;
	opleidings_type: OpleidingsTypeRestDto;
	verplicht: boolean;
}

export class BenodigdeDocumentRestDtoFormHelper {

    static generateFormWithSwaggerValidators(formBuilder: FormBuilder, controlsConfig: {[key: string]: any;}, options?: AbstractControlOptions | {[key: string]: any;} | null): FormGroup{
        if(controlsConfig["document_type_context_code"] != undefined && !(controlsConfig["document_type_context_code"] instanceof FormGroup) && !(controlsConfig["document_type_context_code"] instanceof FormArray)){
            controlsConfig["document_type_context_code"][1] = controlsConfig["document_type_context_code"][1].concat(BenodigdeDocumentRestDtoValidator.document_type_context_code_swagger_validator());
        }
        if(controlsConfig["documentnaam"] != undefined && !(controlsConfig["documentnaam"] instanceof FormGroup) && !(controlsConfig["documentnaam"] instanceof FormArray)){
            controlsConfig["documentnaam"][1] = controlsConfig["documentnaam"][1].concat(BenodigdeDocumentRestDtoValidator.documentnaam_swagger_validator());
        }
        if(controlsConfig["opleidings_type"] != undefined && !(controlsConfig["opleidings_type"] instanceof FormGroup) && !(controlsConfig["opleidings_type"] instanceof FormArray)){
            controlsConfig["opleidings_type"][1] = controlsConfig["opleidings_type"][1].concat(BenodigdeDocumentRestDtoValidator.opleidings_type_swagger_validator());
        }
        if(controlsConfig["verplicht"] != undefined && !(controlsConfig["verplicht"] instanceof FormGroup) && !(controlsConfig["verplicht"] instanceof FormArray)){
            controlsConfig["verplicht"][1] = controlsConfig["verplicht"][1].concat(BenodigdeDocumentRestDtoValidator.verplicht_swagger_validator());
        }
        if(options){
            return formBuilder.group(controlsConfig, options);
        }
        return formBuilder.group(controlsConfig);
    }
}

export class BenodigdeDocumentRestDtoValidator {

        /**
        * Active Validators for document_type_context_code:
        * Required validator
        */
        static document_type_context_code_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                validators.push(SwaggerValidators.required);
                return validators;
            };

        /**
        * Active Validators for documentnaam:
        * Required validator
        */
        static documentnaam_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                validators.push(SwaggerValidators.required);
                return validators;
            };

        /**
        * Active Validators for opleidings_type:
        * Required validator
        */
        static opleidings_type_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                validators.push(SwaggerValidators.required);
                return validators;
            };

        /**
        * Active Validators for verplicht:
        * Required validator
        */
        static verplicht_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                validators.push(SwaggerValidators.required);
                return validators;
            };

}



