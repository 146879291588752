import { delay, Observable, of } from 'rxjs';

export const mockEten: Observable<any[]> = of([
  {
    name: 'Spaghetti',
    type: 'Hoofdgerecht',
    code: 'SPA'
  },
  {
    name: 'Frietjes',
    type: 'Hoofdgerecht',
    code: 'FRI'
  },
  {
    name: 'Stoemp',
    type: 'Hoofdgerecht',
    code: 'STO'
  },
  {
    name: 'Pudding',
    type: 'Nagerecht',
    code: 'PUD'
  }
]).pipe(delay(5000));
