/**
 * Naric
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 4.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { BevragingRestDto } from '././bevragingRestDto';
import { ResultaatInfoRestDto } from '././resultaatInfoRestDto';


import {ValidatorFn, AbstractControlOptions, FormGroup, FormBuilder, FormArray} from '@angular/forms';
import { SwaggerValidators } from '@ov/ngx-ov-utils';

export interface IAanvraagbegeleidingRestDto {
	bevraging: BevragingRestDto;
	creatie_datum: string;
	id: string;
	resultaat_info?: ResultaatInfoRestDto;
	update_datum: string;
}


export class AanvraagbegeleidingRestDto implements IAanvraagbegeleidingRestDto {
	bevraging: BevragingRestDto;
	creatie_datum: string;
	id: string;
	resultaat_info?: ResultaatInfoRestDto;
	update_datum: string;
}

export class AanvraagbegeleidingRestDtoFormHelper {

    static generateFormWithSwaggerValidators(formBuilder: FormBuilder, controlsConfig: {[key: string]: any;}, options?: AbstractControlOptions | {[key: string]: any;} | null): FormGroup{
        if(controlsConfig["bevraging"] != undefined && !(controlsConfig["bevraging"] instanceof FormGroup) && !(controlsConfig["bevraging"] instanceof FormArray)){
            controlsConfig["bevraging"][1] = controlsConfig["bevraging"][1].concat(AanvraagbegeleidingRestDtoValidator.bevraging_swagger_validator());
        }
        if(controlsConfig["creatie_datum"] != undefined && !(controlsConfig["creatie_datum"] instanceof FormGroup) && !(controlsConfig["creatie_datum"] instanceof FormArray)){
            controlsConfig["creatie_datum"][1] = controlsConfig["creatie_datum"][1].concat(AanvraagbegeleidingRestDtoValidator.creatie_datum_swagger_validator());
        }
        if(controlsConfig["id"] != undefined && !(controlsConfig["id"] instanceof FormGroup) && !(controlsConfig["id"] instanceof FormArray)){
            controlsConfig["id"][1] = controlsConfig["id"][1].concat(AanvraagbegeleidingRestDtoValidator.id_swagger_validator());
        }
        if(controlsConfig["resultaat_info"] != undefined && !(controlsConfig["resultaat_info"] instanceof FormGroup) && !(controlsConfig["resultaat_info"] instanceof FormArray)){
            controlsConfig["resultaat_info"][1] = controlsConfig["resultaat_info"][1].concat(AanvraagbegeleidingRestDtoValidator.resultaat_info_swagger_validator());
        }
        if(controlsConfig["update_datum"] != undefined && !(controlsConfig["update_datum"] instanceof FormGroup) && !(controlsConfig["update_datum"] instanceof FormArray)){
            controlsConfig["update_datum"][1] = controlsConfig["update_datum"][1].concat(AanvraagbegeleidingRestDtoValidator.update_datum_swagger_validator());
        }
        if(options){
            return formBuilder.group(controlsConfig, options);
        }
        return formBuilder.group(controlsConfig);
    }
}

export class AanvraagbegeleidingRestDtoValidator {

        /**
        * Active Validators for bevraging:
        * Required validator
        */
        static bevraging_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                validators.push(SwaggerValidators.required);
                return validators;
            };

        /**
        * Active Validators for creatie_datum:
        * Required validator
        */
        static creatie_datum_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                validators.push(SwaggerValidators.required);
                return validators;
            };

        /**
        * Active Validators for id:
        * Required validator
        */
        static id_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                validators.push(SwaggerValidators.required);
                return validators;
            };

        /**
        * Active Validators for resultaat_info:
        */
        static resultaat_info_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

        /**
        * Active Validators for update_datum:
        * Required validator
        */
        static update_datum_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                validators.push(SwaggerValidators.required);
                return validators;
            };

}



