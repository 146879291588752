<div
  class="c-datepicker {{ classes }}"
  [class.is-error]="ngControl?.touched && ngControl?.status === 'INVALID'"
  [class.is-disabled]="isDisabled"
  [class.c-input-field--block]="isBlocked"
>
  @if (label) {
    <ngx-ov-input-label
      [text]="label"
      [required]="isRequired"
      [for]="identifier"
      [isReadonly]="isReadonly"
    ></ngx-ov-input-label>
  }
  @if (!isReadonly) {
    <div
      class="c-datepicker__container"
      mwlFlatpickr
      [allowInput]="allowInput"
      [altFormat]="altFormat"
      [altInput]="altInput"
      [convertModelValue]="convertModelValue"
      [dateFormat]="dateFormat"
      [enableTime]="enableTime"
      [noCalendar]="noCalendar"
      [time24hr]="time24hr"
      [enableSeconds]="enableSeconds"
      [hourIncrement]="hourIncrement"
      [minuteIncrement]="minuteIncrement"
      [locale]="locale"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [mode]="mode"
      [wrap]="true"
      [ngModel]="value"
      [enable]="monthYearOnly ? undefined : enable"
      [disable]="monthYearOnly ? undefined : disable"
      [plugins]="plugins"
      [monthSelectorType]="monthSelectorType"
      (flatpickrClose)="onFlatpickrClose($event)"
      (flatpickrChange)="onFlatpickrChange($event)"
      (blur)="onBlur(value)"
    >
      <input
        id="{{ identifier }}"
        [(ngModel)]="value"
        [placeholder]="placeholder ? placeholder : ''"
        class="c-datepicker__input"
        type="text"
        data-input
      />
      @if (showIcon()) {
        <div class="c-datepicker__icon" data-toggle>
          <i
            [ngClass]="!noCalendar ? 'fas fa-calendar-alt' : 'fas fa-clock'"
            aria-hidden="true"
          ></i>
        </div>
      }
    </div>
  } @else {
    <div
      id="readonly"
      class="u-form-readonly__input-readonly"
      data-cy="readonly-value"
      [innerHTML]="value ? value : '-'"
    ></div>
  }
</div>
@if (errors$ | async; as errors) {
  <div class="mt-xsmall">
    @if (ngControl?.touched && ngControl?.status === 'INVALID') {
      <ngx-ov-input-validation [messages]="errors"></ngx-ov-input-validation>
    }
  </div>
}
