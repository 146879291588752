import {
  APP_INITIALIZER,
  enableProdMode,
  importProvidersFrom,
  Injector,
  NgModule
} from '@angular/core';

import { apiConfigs, createTranslateLoader, getApiConfig } from './constants';
import { environment } from '../environments/environment';
import { provideNgxMask } from 'ngx-mask';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { provideRouter } from '@angular/router';
import { routes } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlatpickrModule } from 'angularx-flatpickr';
import {
  flatpickerConfig,
  NgxOvUiModule
} from '../../projects/ngx-ov-ui/src/lib/ngx-ov-ui.module';
import { ApiModule, LandService } from '@naricbo-open-api';
import { NGXS_PLUGINS, NgxsModule } from '@ngxs/store';
import { LandenState } from '@store/landen/landen.state';
import { MockState } from '@store/mock/mock.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxOvMigratieModule } from '../../projects/ngx-ov-ui/src/lib/components/migratie/migratie.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BrowserSupportModule } from '../../projects/ngx-ov-ui/src/lib/services/browser-support.module';
import { NgxOvUiModuleConfig } from '../../projects/ngx-ov-ui/src/lib/models/ngx-ov-ui.config';
import { maskConfig } from '../config';
import {
  API_CONFIG,
  EnvironmentService,
  LanguageService,
  NgxOvActionsState,
  NgxOvBuildServiceProvider,
  NgxOvUtilsModule,
  OutSubenvironmentInterceptor
} from '@ov/ngx-ov-utils';
import { appInitializerFactory } from './shared/app.initizalize';
import { actionsPlugin } from '../../projects/ngx-ov-ui/src/lib/fundamentals/store/actions';
import {
  NGX_OV_SNACKBAR_PLUGIN_OPTIONS,
  ngxOvSnackbarPluginFactory,
  NgxOvSnackbarPluginOptions
} from '../../projects/ngx-ov-ui/src/lib/fundamentals/store/plugins/snackbar-plugin-options';
import { NgxOvSnackbarService } from '../../projects/ngx-ov-ui/src/lib/components/meldingen/snackbar/snackbar.service';
import { TranslationsService } from '../../projects/ngx-ov-ui/src/lib/services/translations.service';
import { NgxOvUiService } from '../../projects/ngx-ov-ui/src/lib/fundamentals/ui/ui.service';

if (environment.production) {
  enableProdMode();
}

const ngxOvUIConfiguration: NgxOvUiModuleConfig = {
  cypress: true,
  uploadOptions: {
    acceptedFileTypes: ['.pdf'],
    maxLengthFileName: 20,
    maxFileSizeInMB: 1
  }
};

export function snackbarPluginOptionsFactory(
  options: NgxOvSnackbarPluginOptions
) {
  const defaultSnackbarOptions: NgxOvSnackbarPluginOptions = {
    showDefaultSnackbarOnSaveSuccess: false,
    showDefaultSnackbarOnSaveFailure: true
  };

  return {
    ...defaultSnackbarOptions
  };
}

@NgModule({
  imports: [],
  providers: [
    LandService,
    LanguageService,
    EnvironmentService,
    NgxOvSnackbarService,
    NgxOvUiService,
    provideRouter(routes),
    provideNgxMask(maskConfig),
    importProvidersFrom(
      HttpClientModule,
      BrowserAnimationsModule,
      FlatpickrModule.forRoot(flatpickerConfig),
      ApiModule.forRoot(getApiConfig),
      NgxOvUtilsModule.forRoot(),
      NgxOvMigratieModule.forRoot(),
      NgxsModule.forRoot([LandenState, MockState, NgxOvActionsState]),
      NgxOvUiModule.forRoot(ngxOvUIConfiguration),
      NgxsReduxDevtoolsPluginModule.forRoot(),
      NgxSkeletonLoaderModule.forRoot(),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
        }
      }),
      BrowserSupportModule.forRoot({
        versions: [
          {
            name: 'IE',
            version: '11'
          }
        ]
      })
    ),
    {
      provide: NGXS_PLUGINS,
      useValue: actionsPlugin,
      multi: true
    },
    {
      provide: NGXS_PLUGINS,
      useFactory: ngxOvSnackbarPluginFactory,
      deps: [
        NgxOvSnackbarService,
        NGX_OV_SNACKBAR_PLUGIN_OPTIONS,
        TranslationsService,
        TranslateService
      ],
      multi: true
    },
    {
      provide: NGX_OV_SNACKBAR_PLUGIN_OPTIONS,
      useFactory: snackbarPluginOptionsFactory
    },
    NgxOvBuildServiceProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [NgxOvUiService, LanguageService, EnvironmentService, Injector],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OutSubenvironmentInterceptor,
      multi: true
    },
    { provide: API_CONFIG, useValue: apiConfigs }
  ],
  declarations: []
})
export class AppModule {}
