import {
  ChangeDetectorRef,
  Directive,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';

import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgxOvActionsService } from '../actions/actions.service';
import { NgxOvButtonComponent } from '../../../components/default-layout-blokken/button/button.component';
import { NgxOvUploadComponent } from '../../../components/forms/upload/upload.component';
import { NgxOvSelectComponent } from '../../../components/forms/select/select.component';

@Directive({
  selector: '[storeIsLoading]',
  standalone: true
})
export class StoreIsLoadingDirective implements OnInit, OnDestroy {
  @Input() storeIsLoading: string | string[];

  public destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private actionsService: NgxOvActionsService,
    private cd: ChangeDetectorRef,
    @Host() @Optional() private buttonComponent: NgxOvButtonComponent,
    @Host() @Optional() private uploadComponent: NgxOvUploadComponent,
    @Host() @Optional() private selectComponent: NgxOvSelectComponent
  ) {}

  ngOnInit() {
    this.actionsService
      .getStatus$(this.storeIsLoading)
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((res) => {
        if (res) {
          let loading = false;
          if (res.isSaving || res.isDeleting || res.isLoading) {
            loading = true;
          }
          if (this.buttonComponent) {
            this.buttonComponent.isLoading = loading;
          }
          if (this.uploadComponent) {
            this.uploadComponent.isLoading = loading;
          }
          if (this.selectComponent) {
            this.selectComponent.isLoading.set(loading);
          }
          this.cd.detectChanges();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }
}
