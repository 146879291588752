import { Signal } from '@angular/core';

export * from '././aanvraagbegeleidingRestDto';
export * from '././benodigdeDocumentRestDto';
export * from '././benodigdeDocumentenSelectiecriteriaRestDto';
export * from '././beroepInstanceOutputRestDto';
export * from '././beroepOutputRestDto';
export * from '././bevragingRestDto';
export * from '././codeOmschrijvingRestDto';
export * from '././codeOmschrijvingRestDtoOfstring';
export * from '././geoPostcodeRestDto';
export * from '././geslachtOutputRestDto';
export * from '././guiNameValuePairRestDto';
export * from '././knoopRestDto';
export * from '././landOutputRestDto';
export * from '././nationaliteitOutputRestDto';
export * from '././opleidingsTypeRestDto';
export * from '././opleidingsverstrekkerOutputRestDto';
export * from '././resultaatInfoRestDto';
export * from '././stringRestDto';
export * from '././telefoonLandprefixOutputRestDto';
export * from '././vakgebiedOutputRestDto';
export * from '././vrijstellingsredenOutputRestDto';


export interface ApiCallState<TResult> {
  loading: boolean;
  result?: TResult;
  error?: any;
}

 export function isAngularSignal<T>(value: T | Signal<T>): value is Signal<T> {
    return typeof value === 'function';
    }
