/**
 * Naric
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 4.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */



import {ValidatorFn, AbstractControlOptions, FormGroup, FormBuilder, FormArray} from '@angular/forms';
import { SwaggerValidators } from '@ov/ngx-ov-utils';

export interface IBeroepInstanceOutputRestDto {
	overkoepelende_instantie?: string;
	url?: string;
}


export class BeroepInstanceOutputRestDto implements IBeroepInstanceOutputRestDto {
	overkoepelende_instantie?: string;
	url?: string;
}

export class BeroepInstanceOutputRestDtoFormHelper {

    static generateFormWithSwaggerValidators(formBuilder: FormBuilder, controlsConfig: {[key: string]: any;}, options?: AbstractControlOptions | {[key: string]: any;} | null): FormGroup{
        if(controlsConfig["overkoepelende_instantie"] != undefined && !(controlsConfig["overkoepelende_instantie"] instanceof FormGroup) && !(controlsConfig["overkoepelende_instantie"] instanceof FormArray)){
            controlsConfig["overkoepelende_instantie"][1] = controlsConfig["overkoepelende_instantie"][1].concat(BeroepInstanceOutputRestDtoValidator.overkoepelende_instantie_swagger_validator());
        }
        if(controlsConfig["url"] != undefined && !(controlsConfig["url"] instanceof FormGroup) && !(controlsConfig["url"] instanceof FormArray)){
            controlsConfig["url"][1] = controlsConfig["url"][1].concat(BeroepInstanceOutputRestDtoValidator.url_swagger_validator());
        }
        if(options){
            return formBuilder.group(controlsConfig, options);
        }
        return formBuilder.group(controlsConfig);
    }
}

export class BeroepInstanceOutputRestDtoValidator {

        /**
        * Active Validators for overkoepelende_instantie:
        */
        static overkoepelende_instantie_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

        /**
        * Active Validators for url:
        */
        static url_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

}



