import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnumOverzichtResultaatRestDto } from './enum-selection.model';
import { v4 as uuidv4 } from 'uuid';
import { ENUM_CONFIG, EnumConfig } from './enum.config';
import { Configuration } from './configuration';

@Injectable({
  providedIn: 'root'
})
export class EnumSelectionService {
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected enumUrl: string;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(ENUM_CONFIG) private enumConfig: EnumConfig,
    @Optional() configuration: Configuration
  ) {
    if (enumConfig.enumUrl) {
      this.enumUrl = enumConfig.enumUrl;
    }
    if (configuration) {
      this.configuration = configuration;
    }
  }

  /**
   * REST.API.BFF.ENUM.01 Overzicht van enums.
   * Kan gebruikt worden voor het ophalen van enum waardes voor enums.
   * @param enumeration_code The enum code that is mapped in the enum mapping config file.
   * @param alternateConfig by default we use the wired config. But in some special cases we need to retrieve the enum values from another url.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */

  public findEnumValues(
    enumeration_code: string,
    alternateConfig?: EnumConfig,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<EnumOverzichtResultaatRestDto>;
  public findEnumValues(
    enumeration_code: string,
    alternateConfig?: EnumConfig,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<EnumOverzichtResultaatRestDto>>;
  public findEnumValues(
    enumeration_code: string,
    alternateConfig?: EnumConfig,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<EnumOverzichtResultaatRestDto>>;
  public findEnumValues(
    enumeration_code: string,
    alternateConfig?: EnumConfig,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (enumeration_code === null || enumeration_code === undefined) {
      throw new Error(
        'Required parameter enumeration_code was null or undefined when calling findEnumValues.'
      );
    }

    let headers = this.defaultHeaders;

    // add request Id
    if (this.configuration.transactionTracing) {
      let requestId = uuidv4();
      headers = headers.set('X-Request-ID', requestId);
    }
    // authentication (BearerAuth) required
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    let httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<EnumOverzichtResultaatRestDto>(
      `${
        alternateConfig ? alternateConfig.enumUrl : this.enumUrl
      }/${encodeURIComponent(String(enumeration_code))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}