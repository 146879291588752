/**
 * Naric
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 4.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */



import {ValidatorFn, AbstractControlOptions, FormGroup, FormBuilder, FormArray} from '@angular/forms';
import { SwaggerValidators } from '@ov/ngx-ov-utils';

export interface ITelefoonLandprefixOutputRestDto {
	id?: string;
	prefix?: string;
}


export class TelefoonLandprefixOutputRestDto implements ITelefoonLandprefixOutputRestDto {
	id?: string;
	prefix?: string;
}

export class TelefoonLandprefixOutputRestDtoFormHelper {

    static generateFormWithSwaggerValidators(formBuilder: FormBuilder, controlsConfig: {[key: string]: any;}, options?: AbstractControlOptions | {[key: string]: any;} | null): FormGroup{
        if(controlsConfig["id"] != undefined && !(controlsConfig["id"] instanceof FormGroup) && !(controlsConfig["id"] instanceof FormArray)){
            controlsConfig["id"][1] = controlsConfig["id"][1].concat(TelefoonLandprefixOutputRestDtoValidator.id_swagger_validator());
        }
        if(controlsConfig["prefix"] != undefined && !(controlsConfig["prefix"] instanceof FormGroup) && !(controlsConfig["prefix"] instanceof FormArray)){
            controlsConfig["prefix"][1] = controlsConfig["prefix"][1].concat(TelefoonLandprefixOutputRestDtoValidator.prefix_swagger_validator());
        }
        if(options){
            return formBuilder.group(controlsConfig, options);
        }
        return formBuilder.group(controlsConfig);
    }
}

export class TelefoonLandprefixOutputRestDtoValidator {

        /**
        * Active Validators for id:
        */
        static id_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

        /**
        * Active Validators for prefix:
        */
        static prefix_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

}



