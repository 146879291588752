<a
  class="c-progress-block"
  [class.c-progress-block--disabled]="isDisabled"
  [class.c-progress-block--completed]="isCompleted"
  [class.c-progress-block--action]="hasAction"
  (click)="onClickBar()"
  [attr.tabindex]="isDisabled ? -1 : 0"
>
  <div class="c-progress-block__header">
    <div class="c-progress-block__title">{{ title }}</div>
    <div class="c-progress-block__icon">
      @if (!isCompleted) {
        <i
          class="fas fa-chevron-circle-right"
          aria-hidden="true"
          [title]="iconInProgressTitle"
        ></i>
      } @else {
        <i
          class="fas fa-check-circle"
          aria-hidden="true"
          [title]="iconCompletedTitle"
        ></i>
      }

      <span class="sr-only">{{
        isCompleted ? iconCompletedTitle : iconInProgressTitle
      }}</span>
    </div>
  </div>
  <div class="c-progress-block__bar">
    <ngx-ov-progress-bar [percentage]="percentage"></ngx-ov-progress-bar>
  </div>
</a>
