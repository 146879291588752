import { Pipe, PipeTransform } from '@angular/core';
import { isFixedSelectItem } from '../select.utils';

@Pipe({
  name: 'isFixedSelectItem',
  standalone: true
})
export class IsFixedSelectItemPipe implements PipeTransform {
  transform(
    item: any,
    fixedItemsValues: any[],
    bindValue: string
  ): string | boolean {
    return isFixedSelectItem(item, fixedItemsValues, bindValue);
  }
}
