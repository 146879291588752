/**
 * Naric
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 4.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */



import {ValidatorFn, AbstractControlOptions, FormGroup, FormBuilder, FormArray} from '@angular/forms';
import { SwaggerValidators } from '@ov/ngx-ov-utils';

export interface ILandOutputRestDto {
	auto_erk?: boolean;
	landcode?: string;
	landnaam?: string;
}


export class LandOutputRestDto implements ILandOutputRestDto {
	auto_erk?: boolean;
	landcode?: string;
	landnaam?: string;
}

export class LandOutputRestDtoFormHelper {

    static generateFormWithSwaggerValidators(formBuilder: FormBuilder, controlsConfig: {[key: string]: any;}, options?: AbstractControlOptions | {[key: string]: any;} | null): FormGroup{
        if(controlsConfig["auto_erk"] != undefined && !(controlsConfig["auto_erk"] instanceof FormGroup) && !(controlsConfig["auto_erk"] instanceof FormArray)){
            controlsConfig["auto_erk"][1] = controlsConfig["auto_erk"][1].concat(LandOutputRestDtoValidator.auto_erk_swagger_validator());
        }
        if(controlsConfig["landcode"] != undefined && !(controlsConfig["landcode"] instanceof FormGroup) && !(controlsConfig["landcode"] instanceof FormArray)){
            controlsConfig["landcode"][1] = controlsConfig["landcode"][1].concat(LandOutputRestDtoValidator.landcode_swagger_validator());
        }
        if(controlsConfig["landnaam"] != undefined && !(controlsConfig["landnaam"] instanceof FormGroup) && !(controlsConfig["landnaam"] instanceof FormArray)){
            controlsConfig["landnaam"][1] = controlsConfig["landnaam"][1].concat(LandOutputRestDtoValidator.landnaam_swagger_validator());
        }
        if(options){
            return formBuilder.group(controlsConfig, options);
        }
        return formBuilder.group(controlsConfig);
    }
}

export class LandOutputRestDtoValidator {

        /**
        * Active Validators for auto_erk:
        */
        static auto_erk_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

        /**
        * Active Validators for landcode:
        */
        static landcode_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

        /**
        * Active Validators for landnaam:
        */
        static landnaam_swagger_validator(): Array<ValidatorFn>{
                let validators = [];
                return validators;
            };

}



