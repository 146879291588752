/**
 * Naric
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 4.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional, Signal, inject }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext  }                           from '@angular/common/http';
import { CustomHttpParameterCodec }                        from '../encoder';

import { Observable, of, startWith }                                        from 'rxjs';
import { v4 as uuidv4 }                                      from 'uuid';
import { catchError, map } from 'rxjs/operators';
import { ApiCallState, isAngularSignal } from '../model/models';
import { derivedAsync } from 'ngxtension/derived-async';
import { BenodigdeDocumentenSelectiecriteriaRestDto } from '../model/benodigdeDocumentenSelectiecriteriaRestDto';
import { StringRestDto } from '../model/stringRestDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';




@Injectable()
export class DocumentdetailsService {

    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Deze webservice wordt opgeroepen wanneer er voor een bepaald benodigd document informatie moet getoond worden
     * 
     * @param documenttypecontextCode documenttypecontext_code
     * @param xOvLanguage x-ov-language
     * @param criteriaRestDto criteria_rest_dto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */



    public bepaalDocumentdetailsUsingPUT(documenttypecontextCode: string, criteriaRestDto: BenodigdeDocumentenSelectiecriteriaRestDto, observe?: 'body', reportProgress?: boolean):
Observable<StringRestDto>;
    public bepaalDocumentdetailsUsingPUT(documenttypecontextCode: string, criteriaRestDto: BenodigdeDocumentenSelectiecriteriaRestDto, observe?: 'response', reportProgress?: boolean):
Observable<HttpResponse<StringRestDto>>;
    public bepaalDocumentdetailsUsingPUT(documenttypecontextCode: string, criteriaRestDto: BenodigdeDocumentenSelectiecriteriaRestDto, observe?: 'events', reportProgress?: boolean):
Observable<HttpEvent<StringRestDto>>;
    public bepaalDocumentdetailsUsingPUT(documenttypecontextCode: string, criteriaRestDto: BenodigdeDocumentenSelectiecriteriaRestDto, observe: any = 'body', reportProgress: boolean = false ):
Observable<any> {
        if (documenttypecontextCode === null || documenttypecontextCode === undefined) {
            throw new Error('Required parameter documenttypecontextCode was null or undefined when calling bepaalDocumentdetailsUsingPUT.');
        }
        if (criteriaRestDto === null || criteriaRestDto === undefined) {
            throw new Error('Required parameter criteriaRestDto was null or undefined when calling bepaalDocumentdetailsUsingPUT.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpParameterCodec()});

        if (documenttypecontextCode !== undefined) {
            queryParameters = queryParameters.set('documenttypecontext_code', <any>documenttypecontextCode);
        }

        let headers = this.defaultHeaders;

        // add request Id
        if (this.configuration.transactionTracing) {
            let requestId = uuidv4();
            headers = headers.set("X-Request-ID", requestId);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json;charset=UTF-8'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

    let localVarPath = `/open-api/v1/documentdetails`;
        return this.httpClient.put<StringRestDto>(`${this.configuration.basePath}${localVarPath}`,
            criteriaRestDto,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
