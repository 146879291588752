import * as R from 'ramda';

export const isFixedSelectItem = (
  item: any,
  fixedItemsValues: any[],
  bindValue: string
) => {
  const props = bindValue?.split('.');
  const getValue = R.path(props);

  if (!Array.isArray(fixedItemsValues)) {
    return false;
  }

  if (!fixedItemsValues) {
    return false;
  }

  return (
    fixedItemsValues.filter((fixedValue) => {
      if (bindValue) {
        return getValue(item) == fixedValue;
      } else {
        return item == fixedValue;
      }
    }).length > 0
  );
};
